export enum ScriptRatingCompetencyLevel {
    HOMEWORK = 1,
    DEMO = 2,
    EXPLAINED_BY_STUDENT = 3,
    ACT_ON_DIRECTIONS = 4,
    ACT_ON_LIMITED_DIRECTIONS = 5,
    ACT_WITHOUT_DIRECTIONS = 6,
    ACT_ON_CHANGED_SITUATIONS = 7,
    ACT_INDEPENDENTLY = 8,
    TOOL = 'M',
    STV_EDUCATION = 'S',
    STV_TRAINING = 'T',
    STV_FORMATION = 'V',
}
