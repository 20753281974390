import { Pipe, PipeTransform } from '@angular/core'
import { DecimalPipe } from '@angular/common'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
    name: 'localizedNumber',
})
export class LocalizedNumberPipe implements PipeTransform {

    constructor(
        private readonly decimalPipe: DecimalPipe,
        private readonly translateService: TranslateService,
    ) {
    }

    public transform(
        value: number | string,
        digitsInfo: string = this.translateService.instant('locale.format.number.digitsInfo'),
    ): string {
        return this.decimalPipe.transform(
            value,
            digitsInfo,
            this.translateService.currentLang ?? this.translateService.defaultLang,
        )
    }

}
