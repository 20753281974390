import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { ToastController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { equals, reject } from 'ramda'

import { Vehicle } from '@app-graphql'
import { FormHelperService, VehiclesService } from '@app-services'
import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-vehicle-edit-modal',
    templateUrl: './vehicle-edit.modal.html',
    styleUrls: ['./vehicle-edit.modal.scss'],
})
export class VehicleEditModal extends BaseModal implements AfterViewInit {

    @Input()
    public vehicle: Partial<Vehicle>

    @Output()
    public vehicleUpdated = new EventEmitter<Partial<Vehicle>>()

    public form: FormGroup
    public apiError: string
    public loading = false

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly vehiclesService: VehiclesService,
        private readonly translateService: TranslateService,
        private readonly toastController: ToastController,
    ) {
        super()
    }

    public async ngAfterViewInit(): Promise<void> {
        this.modal?.ionModalWillPresent?.subscribe(async () => {
            this.apiError = ''

            await this.initializeForm()
        })

        this.modal.canDismiss = async () => this.canDismiss()
    }

    public async initializeForm(): Promise<void> {
        this.form = this.formBuilder.group({
            name: [this.vehicle.name],
            cbrId: [this.vehicle.cbrId],
            licensePlate: [this.vehicle.licensePlate, [Validators.required]],
            brand: [this.vehicle.brand, [Validators.required]],
            model: [this.vehicle.model, [Validators.required]],
            year: [this.vehicle.year, [Validators.required]],
        })
    }

    private async canDismiss(): Promise<boolean> {

        if (! this.form.dirty) {
            return true
        }

        const shouldDiscardChanges = await this.formHelperService.confirmDiscardChanges()

        if (shouldDiscardChanges) {
            this.form.reset()
        }

        return shouldDiscardChanges
    }

    public async submit(): Promise<void> {
        this.apiError = ''

        if (this.loading) {
            return
        }

        if (! this.form.valid) {
            this.formHelperService.reportFormErrors(this.form)
            return
        }

        this.loading = true

        let message = this.translateService.instant('modals.organization.vehicle.edit.success')

        this.form.markAsPristine()

        try {
            const data = {
                ...this.form.value,
                year: +this.form.controls.year.value,
                id: this.vehicle.id,
            }

            const response = await this.vehiclesService.updateVehicle(
                reject(equals(null))(data),
            )

            this.vehicleUpdated.emit(response.updateVehicle as Partial<Vehicle>)

            this.loading = false

            await this.dismiss()
        } catch (e) {
            this.loading = false
            this.apiError = e.message
            this.formHelperService.reportFormErrors(this.form)
            message = this.translateService.instant('modals.organization.vehicle.edit.error')
        }

        await this.toastController.create({
            message,
            duration: 3000,
        }).then((toast) => toast.present())
    }
}
