import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-lesson-rating',
    templateUrl: './lesson-rating.modal.html',
    styleUrls: ['./lesson-rating.modal.scss'],
})
export class LessonRatingModal extends BaseModal {

    @Input()
    public lesson: any // @TODO: Set type

}
