<ion-card class="ion-margin-vertical">
    <ion-card-content>
        <ion-icon class="ion-color ion-color-dark" name="lock-open" />
        Upgrade om toegang te krijgen tot de inhoud.
    </ion-card-content>
    <div class="card-buttons">
        <ion-button [appExternalLink]="packagesUrl"
                    appExternalLinkTarget="_blank"
                    fill="clear"
        >
            Bekijk pakketten
        </ion-button>
        <app-redeem-roadmap-paywall-code-modal #redeemRoadmapPaywallCodeModal
                                               (codeRedeemed)="codeRedeemed.emit()"
        />
        <ion-button (click)="redeemRoadmapPaywallCodeModal.present()" fill="clear">
            Ik heb een code
        </ion-button>
    </div>
</ion-card>
