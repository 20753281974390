import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-lesson-edit',
    templateUrl: './lesson-edit.modal.html',
    styleUrls: ['./lesson-edit.modal.scss'],
})
export class LessonEditModal extends BaseModal {

    @Input()
    public lesson: any // @TODO: Set type

}
