<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.roadmap.introduction.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding-horizontal">

            <ng-container *ngIf="milestone?.contents">
                <ion-card color="light" shape="round">
                    <ion-card-content>
                        <div *ngFor="let contentItem of $any(milestone.contents); let i = index"
                             [class.ion-margin-top]="i > 0"
                        >
                            <ng-container *ngIf="contentItem.text">
                                <div [innerHTML]="contentItem.text | safe : 'html'"
                                     appExternalContent
                                ></div>
                            </ng-container>
                            <ng-container *ngIf="contentItem.videoId">
                                <app-video-player [video]="contentItem" />
                            </ng-container>
                            <ng-container *ngIf="contentItem.image">
                                <img [alt]="contentItem.altText"
                                     [src]="contentItem.image"
                                >
                            </ng-container>
                        </div>
                    </ion-card-content>
                </ion-card>
            </ng-container>

        </ion-content>

    </ng-template>
</ion-modal>
