import { Directive, Input, ViewChild } from '@angular/core'
import { IonModal } from '@ionic/angular'

@Directive()
export abstract class BaseModal {

    @ViewChild(IonModal)
    protected modal: IonModal

    @Input()
    public trigger?: string

    public async present(): Promise<void> {
        await this.modal.present()
    }

    public async dismiss(): Promise<void> {
        await this.modal.dismiss()
    }

}
