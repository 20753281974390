import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'

import { CalendarEvent, CalendarEventParticipantActor, Instructor, Student, User } from '@app-graphql'

@Component({
    selector: 'app-calendar-event',
    templateUrl: './calendar-event.component.html',
    styleUrls: ['./calendar-event.component.scss'],
})
export class CalendarEventComponent implements OnChanges {

    @Input()
    public event: Partial<CalendarEvent>

    @Input()
    public allowMultilineText: boolean = false

    @Output()
    public eventSelected = new EventEmitter<Partial<CalendarEvent>>()

    public students: Partial<Student>[]
    public instructors: Partial<Instructor>[]
    public otherUsers: Partial<User>[]

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.event) {
            this.listParticipants()
        }
    }

    private listParticipants(): void {
        this.students = []
        this.instructors = []
        this.otherUsers = []
        this.event.calendarEventParticipants
            .forEach((p) => {
                switch ((p.actor as CalendarEventParticipantActor & { __typename: string }).__typename) {
                    case 'Student':
                        this.students.push(p.actor as Partial<Student>)
                        break
                    case 'Instructor':
                        this.instructors.push(p.actor as Partial<Instructor>)
                        break
                    case 'User':
                        this.otherUsers.push(p.actor as Partial<User>)
                        break
                }
            })
    }
}
