import {
    AfterViewInit,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { IonCheckbox, IonPopover, IonSearchbar, IonSelectOption } from '@ionic/angular'

import { FilterHelperService } from '@app-services'

const noop = () => {
}

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectWithSearchComponent),
    multi: true,
}

@Component({
    selector: 'app-select-with-search',
    templateUrl: './select-with-search.component.html',
    styleUrls: ['./select-with-search.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class SelectWithSearchComponent implements AfterViewInit, ControlValueAccessor {

    @Input()
    public title: string

    @Input()
    public placeholder: string

    @Input()
    public multiple?: boolean = false

    @Input()
    public compareWith?: string

    @Output()
    public valueChanged = new EventEmitter<any>()

    @Input()
    public searchbarPlaceholder: string = 'Zoeken'

    @Input()
    public searchbarDebounce = 300

    @Input()
    public showClearButton?: boolean

    @Input()
    public showCancelButton?: boolean = true

    @Input()
    public side?: 'bottom' | 'end' | 'left' | 'right' | 'start' | 'top' = 'bottom'

    @ViewChild(IonPopover)
    public popover: IonPopover

    @ViewChild(IonSearchbar)
    public searchbar: IonSearchbar

    @ContentChildren(IonSelectOption)
    public options: QueryList<IonSelectOption>

    @ContentChildren(IonSelectOption, { read: ElementRef })
    public optionElements: QueryList<ElementRef<IonSelectOption>>

    @ViewChildren(IonCheckbox, { read: ElementRef })
    public checkboxElements: QueryList<ElementRef<IonCheckbox>>

    public innerValue: any = null
    public innerValueForComparison: any = null

    public labelValue: any = null

    public searchResults: any[] = null

    private onTouchedCallback: () => void = noop
    private onChangeCallback: (_: any) => void = noop

    constructor(
        private readonly filterService: FilterHelperService,
    ) {
    }

    public ngAfterViewInit(): void {
        this.labelValue = this.getLabelValue()
        this.popover?.willDismiss.subscribe(() => this.clearSearch())
    }

    public get value(): any | null {
        return this.innerValue
    }

    @Input()
    public set value(value: any | null) {
        if (value === undefined) {
            return
        }
        if (this.writeValue(value)) {
            this.onChangeCallback(this.innerValue)
        }
    }

    public search(): void {
        const searchTerm = this.filterService.normalizeString(this.searchbar.value).toLowerCase()
        if (! searchTerm) {
            this.searchResults = Array.from(this.options)?.map((option) => option.value)
            return
        }

        this.searchResults = Array.from(this.options).filter((_: IonSelectOption, i) => {
            const optionElement = this.optionElements.get(i).nativeElement as unknown as HTMLIonSelectOptionElement
            return this.filterService
                .normalizeString(optionElement.innerText).toLowerCase()
                .includes(searchTerm)
        }).map((option) => option.value)
    }

    public clearSearch(): void {
        this.searchbar.value = ''
        this.search()
    }

    public onBlur(): void {
        this.onTouchedCallback()
    }

    public writeValue(value: any): boolean {
        this.innerValue = value
        this.innerValueForComparison = this.getValueForComparison(value)
        this.labelValue = this.getLabelValue()

        return true
    }

    public getValueForComparison(value: any): any {
        if (! this.compareWith) {
            return value
        }

        if (this.multiple) {
            return Array.isArray(value) ? value.map((val: any) => val[this.compareWith]) : null
        }

        return value?.[this.compareWith] ?? null
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }

    public setValue(value: any): void {
        if (! value) {
            this.value = null
            this.onBlur()
            this.valueChanged.emit(null)
            return
        }

        this.value = value
        this.onBlur()
        this.valueChanged.emit(value)
    }

    public setMultipleValue(): void {
        const selectedIndexes = Array.from(this.checkboxElements)
            .map((checkbox, i) => (checkbox.nativeElement as unknown as HTMLIonCheckboxElement).checked ? i : null)
            .filter((value) => value !== null)

        this.setValue(selectedIndexes.map((index) => this.options.get(index).value))
    }

    private getLabelValue(): string | null {
        if (! this.innerValueForComparison || ! this.optionElements) {
            return null
        }
        if (this.multiple) {
            return this.innerValueForComparison?.map((val) => this.getLabelValueForSingleValue(val)).join(', ')
        }

        return this.getLabelValueForSingleValue(this.innerValueForComparison)
    }

    private getLabelValueForSingleValue(value: any): string | null {
        const optionIndex = Array.from(this.options)
            .findIndex((option) => (this.compareWith ? option.value[this.compareWith] : option.value) === value)

        const optionElement =
            (this.optionElements.get(optionIndex)?.nativeElement as unknown as HTMLIonSelectOptionElement)

        return optionElement?.innerText?.trim() ?? null
    }

}
