<ion-modal [breakpoints]="[0, 0.33, 1]"
           [handle]="false"
           [initialBreakpoint]="0.33"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.roadmap.paywall.redeemRoadmapPaywallCode.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding">
            <form (submit)="submit()" action="" id="redeem-roadmap-paywall-code-form">
                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked" class="ion-text-wrap">
                        <h3>Vul hier de code in die je per mail hebt ontvangen</h3>
                    </ion-label>
                    <ion-input (ionFocus)="$any(modal).setCurrentBreakpoint(1)"
                               [(ngModel)]="code"
                               name="code"
                               placeholder="Vul jouw code in"
                    />
                </ion-item>
                <input class="semi-hidden" tabindex="-1" type="submit" value="">
            </form>
        </ion-content>

        <ion-footer>
            <ion-toolbar class="ion-color ion-color-light">
                <ion-button class="ion-color ion-color-tertiary ion-margin"
                            enterkeyhint="send"
                            expand="block"
                            form="redeem-roadmap-paywall-code-form"
                            type="submit"
                >
                    Code inwisselen
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ng-template>
</ion-modal>
