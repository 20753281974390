import { Component, Input } from '@angular/core'

import { TestExercise } from '@app-graphql'

@Component({
    selector: 'app-roadmap-test-exercises-swiper',
    templateUrl: './roadmap-test-exercises-swiper.component.html',
    styleUrls: ['./roadmap-test-exercises-swiper.component.scss'],
})
export class RoadmapTestExercisesSwiperComponent {

    @Input()
    public testExercises: TestExercise[]

}
