import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'
import { CalendarEvent, PracticeMilestone, Script } from '@app-graphql'

interface ScriptGroup {
    practiceMilestone: PracticeMilestone;
    scripts: Script[];
}

@Component({
    selector: 'app-calendar-event-assign-homework',
    templateUrl: './calendar-event-assign-homework.modal.html',
    styleUrls: ['./calendar-event-assign-homework.modal.scss'],
})
export class CalendarEventAssignHomeworkModal extends BaseModal implements OnChanges {

    @Input()
    public event: Partial<CalendarEvent>

    @Input()
    public eligibleScripts: Script[]

    @Output()
    public selectionChanged = new EventEmitter<Script[]>()

    public selectedScripts: Script[] = []

    public eligibleScriptsGroupedByMilestone: ScriptGroup[]

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.eligibleScripts) {
            this.eligibleScriptsGroupedByMilestone = []

            changes?.eligibleScripts?.currentValue?.forEach((script) => {
                const groupIndex = this.eligibleScriptsGroupedByMilestone
                    .findIndex((g) => g.practiceMilestone.id === script.practiceMilestone.id)

                if (groupIndex === -1) {
                    this.eligibleScriptsGroupedByMilestone.push({
                        practiceMilestone: script.practiceMilestone,
                        scripts: [script],
                    })
                } else {
                    this.eligibleScriptsGroupedByMilestone[groupIndex].scripts.push(script)
                }
            })
        }
    }

    public toggleScript(script: Script, checked: boolean): void {
        if (checked) {
            this.selectedScripts.push(script)
        } else {
            this.selectedScripts.splice(this.selectedScripts.findIndex((s) => s.id === script.id), 1)
        }
    }

}
