import {
    Component,
    EventEmitter,
    forwardRef,
    Input,
    Output,
    ViewChild,
} from '@angular/core'
import { Color } from '@ionic/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { IonDatetime } from '@ionic/angular'
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core'
import { maskitoDateOptionsGenerator } from '@maskito/kit'
import { TranslateService } from '@ngx-translate/core'
import { format, parse, startOfDay, startOfMonth, startOfYear } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'

const noop = () => {
}

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DatePickerComponent),
    multi: true,
}

@Component({
    selector: 'app-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class DatePickerComponent implements ControlValueAccessor {

    @Input()
    public color?: Color | string

    @Input()
    public format?: string

    @Input()
    public maskMode?: string

    @Input()
    public presentation?: 'date' | 'month' | 'month-year' | 'time' | 'year' = 'date'

    @Input()
    public allowManualInput?: boolean = true

    @Input()
    public showInput = true

    @Input()
    public showPickerButton = true

    @Input()
    public showClearButton?: boolean

    @Input()
    public showCancelButton?: boolean = true

    @Input()
    public placeholder?: string = 'locale.format.date.placeholder'

    @Input()
    public defaultValue?: Date

    @Input()
    public min?: Date

    @Input()
    public max?: Date

    @Input()
    public multiple?: boolean = false

    @Output()
    public valueChanged = new EventEmitter<Date>()

    @ViewChild('date')
    public dateComponent: IonDatetime

    public innerValue: Date = null

    public uuid = uuidv4()

    public inputMask: { options: MaskitoOptions, element: MaskitoElementPredicateAsync }

    private onTouchedCallback: () => void = noop
    private onChangeCallback: (_: any) => void = noop

    constructor(
        private readonly translateService: TranslateService,
    ) {
        this.inputMask = {
            options: maskitoDateOptionsGenerator({
                mode: this.translateService.instant('locale.format.date.maskMode'),
                separator: this.translateService.instant('locale.format.date.short').includes('/') ? '/' : '-',
            }),
            element: async (el) => {
                const inputElement = (el as HTMLIonInputElement)
                if (inputElement?.getInputElement) {
                    return inputElement.getInputElement()
                }
                return document.createElement('input')
            },
        }
    }

    public get value(): Date | null {
        return this.innerValue
    }

    @Input()
    public set value(value: string | string[] | Date | null) {
        if (value === undefined) {
            return
        }
        if (this.writeValue(value)) {
            this.onChangeCallback(this.innerValue)
        }
    }

    public onBlur(): void {
        this.onTouchedCallback()
    }

    public writeValue(value: any): boolean {
        let parsedValue: Date = this.parseValue(value)
        if (parsedValue !== this.innerValue) {
            this.innerValue = this.parseValue(value)
            return true
        }
        return false
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }

    public setValue(value: string | string[] | Date | null): void {
        if (! value) {
            this.value = null
            this.onBlur()
            this.valueChanged.emit(null)
            return
        }

        let parsedValue: Date = this.parseValue(value)

        if (this.presentation === 'year') {
            parsedValue = startOfYear(parsedValue)
        } else if (this.presentation.startsWith('month')) {
            parsedValue = startOfMonth(parsedValue)
        } else if (this.presentation === 'date') {
            parsedValue = startOfDay(parsedValue)
        }

        this.value = parsedValue
        this.onBlur()
        this.valueChanged.emit(parsedValue)
    }

    public updateValueFromManualInput(value?: string): void {
        if (! this.allowManualInput) {
            return
        }

        // Update inner value if a full date was entered
        if (value.length === 10) {
            const date: Date = parse(value, 'dd-MM-yyyy', this.innerValue ?? new Date())
            this.setValue(date)
            this.dateComponent.reset(format(date, 'yyyy-MM-dd'))
        }
    }

    private parseValue(value: string | string[] | Date | null): Date | null {
        if (value instanceof Date) {
            return value
        }

        if (! value) {
            return null
        }

        return new Date(value.toString().includes(':') ? value.toString() : `${value.toString()}T00:00:00`)
    }

}
