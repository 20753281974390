import { Injectable } from '@angular/core'
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx'
import { Camera } from '@awesome-cordova-plugins/camera/ngx'
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx'
import { DirectoryEntry, File as NativeFile, FileEntry } from '@awesome-cordova-plugins/file/ngx'
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx'
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx'
import { Platform } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { format } from 'date-fns'

export interface LocalFile {
    path: string;
    publicPath: string;
    originalFileName?: string; // Only available directly after picking a local file
    mimeType?: string;
}

export enum FileAccept {
    Images = 'image/*',
    TextDocuments = 'text/*',
    Pdf = 'application/pdf',
}

export const DOCUMENTS_DIRECTORY = 'documents'

@Injectable({
    providedIn: 'root',
})
export class FileStorageService {

    private fileInputElement: HTMLInputElement

    constructor(
        private readonly camera: Camera,
        private readonly chooser: Chooser,
        private readonly documentViewer: DocumentViewer,
        private readonly file: NativeFile,
        private readonly fileOpener: FileOpener,
        private readonly platform: Platform,
        private readonly translateService: TranslateService,
        private readonly webview: WebView,
    ) {
    }

    public async listLocalFiles(): Promise<LocalFile[]> {
        const directory = await this.getDocumentsDirectory()
        const files = await this.file.listDir(directory.nativeURL, '.')

        return files.map((file: FileEntry) => ({
            path: file.nativeURL,
            publicPath: this.webview.convertFileSrc(file.nativeURL),
        }))
    }

    public async pickFile(
        accept: FileAccept | FileAccept[] = [FileAccept.Images, FileAccept.TextDocuments, FileAccept.Pdf],
        copyToDocumentsDirectory = false,
    ): Promise<LocalFile | null> {

        if (! this.platform.is('cordova')) {
            return this.showFileBrowseDialog(accept)
        }

        const chooserResult = await this.chooser.getFile(accept instanceof Array ? accept.join(',') : accept)
        if (! chooserResult) {
            return null
        }

        const file: LocalFile = {
            path: chooserResult.uri,
            publicPath: this.webview.convertFileSrc(chooserResult.uri),
            mimeType: chooserResult.mediaType,
            originalFileName: chooserResult.name,
        }

        if (copyToDocumentsDirectory) {
            const result = await this.writeFileDataToDocumentsDirectory(chooserResult.data, chooserResult.name)
            return { ...file, ...result }
        }

        return file
    }

    public async pickImage(copyToDocumentsDirectory = false): Promise<LocalFile | null> {
        if (! this.platform.is('cordova')) {
            return this.showFileBrowseDialog(FileAccept.Images)
        }

        const pictureResult = await this.camera.getPicture({
            sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
            saveToPhotoAlbum: false,
            correctOrientation: true,
        })

        if (! pictureResult) {
            return null
        }

        const originalFileName = this.getBaseName(pictureResult).split('cdv_photo_').join('')

        const file: LocalFile = {
            path: pictureResult,
            publicPath: this.webview.convertFileSrc(pictureResult),
            mimeType: this.guessMimeTypeFromPath(pictureResult),
            originalFileName,
        }

        if (copyToDocumentsDirectory) {
            const result = await this.copyFileToDocumentsDirectory(pictureResult, originalFileName)
            return { ...file, ...result }
        }

        return file
    }

    public async takePictureWithCamera(
        copyToDocumentsDirectory = false,
        cameraDirection: 'environment' | 'user' = 'environment',
    ): Promise<LocalFile | null> {
        if (! this.platform.is('cordova')) {
            return this.showFileBrowseDialog(FileAccept.Images, cameraDirection)
        }

        const cameraResult = await this.camera.getPicture({
            saveToPhotoAlbum: false,
            correctOrientation: true,
        })
        if (! cameraResult) {
            return null
        }

        const originalFileName = this.getTimestampedFileName(cameraResult)

        const file: LocalFile = {
            path: cameraResult,
            publicPath: this.webview.convertFileSrc(cameraResult),
            mimeType: this.guessMimeTypeFromPath(cameraResult),
            originalFileName,
        }

        if (copyToDocumentsDirectory) {
            const result = await this.copyFileToDocumentsDirectory(cameraResult, originalFileName)
            return { ...file, ...result }
        }

        return file
    }

    public async showFileBrowseDialog(
        accept: FileAccept | FileAccept[] = [FileAccept.Images, FileAccept.TextDocuments, FileAccept.Pdf],
        capture: 'user' | 'environment' | null = null,
    ): Promise<LocalFile> {

        return new Promise<LocalFile>((resolve) => {

            this.fileInputElement = document.createElement('input')
            this.fileInputElement.type = 'file'
            this.fileInputElement.accept = accept instanceof Array ? accept.join(',') : accept
            if (capture) {
                this.fileInputElement.capture = capture
            }

            // Canceling the file browse dialog will not fire the change event in most browsers.
            // To still be able to 'listen' to this event, we listen to the window focus event instead.
            window.addEventListener('focus', () => {
                setTimeout(() => {
                    window.removeEventListener('focus', (window as any).eventListeners().pop())

                    // If no file was selected, resolve with null
                    if (! this.fileInputElement.files?.length) {
                        resolve(null)
                    }
                }, 1000)
            })

            this.fileInputElement.addEventListener('change', async () => {
                if (this.fileInputElement.files.length) {
                    const file: File = this.fileInputElement.files.item(0)
                    const documentFile: LocalFile = {
                        originalFileName: file.name,
                        path: file.webkitRelativePath,
                        publicPath: null,
                        mimeType: file.type,
                    }

                    const reader = new FileReader()
                    reader.onload = async () => {
                        documentFile.path = reader.result.toString()
                        documentFile.publicPath = reader.result.toString()
                        resolve(documentFile)
                    }
                    reader.readAsDataURL(file)
                } else {
                    resolve(null)
                }
            })

            this.fileInputElement.click()

        })
    }

    public async openFileWithDefaultApplication(file: LocalFile): Promise<void> {
        if (! this.platform.is('cordova')) {
            window.open(file.publicPath)
            return
        }

        if (this.platform.is('ios') && file.mimeType === 'application/pdf') {
            await this.documentViewer.viewDocument(
                file.path,
                file.mimeType,
                {
                    documentView: { closeLabel: this.translateService.instant('common.close') },
                    navigationView: { closeLabel: this.translateService.instant('common.close') },
                },
                null,
                null,
                async () => {
                    console.warn('Failed to open PDF using default viewer. Switching to generic file open dialog...')
                    await this.fileOpener.showOpenWithDialog(file.path, file.mimeType)
                },
                async () => {
                    console.warn('Error while opening PDF. Switching to generic file open dialog...')
                    await this.fileOpener.showOpenWithDialog(file.path, file.mimeType)
                },
            )
            return
        }

        await this.fileOpener.showOpenWithDialog(file.path, file.mimeType)
    }

    public async deleteLocalFile(file: LocalFile): Promise<boolean> {
        const destinationDirectory = await this.getDocumentsDirectory()
        const fileName = file.path.split('/').pop()
        try {
            const result = await this.file.removeFile(destinationDirectory.nativeURL, fileName)
            return result.success
        } catch (e) {
            //
        }

        return false
    }

    public guessMimeTypeFromPath(uri: string): string {
        if (uri.toLowerCase().endsWith('.pdf')) {
            return 'application/pdf'
        }

        if (uri.toLowerCase().endsWith('.png')) {
            return 'image/png'
        }

        if (uri.toLowerCase().endsWith('.jpg') || uri.toLowerCase().endsWith('.jpeg')) {
            return 'image/jpeg'
        }

        if (uri.toLowerCase().endsWith('.gif')) {
            return 'image/gif'
        }

        if (uri.toLowerCase().endsWith('.txt')) {
            return 'text/plain'
        }

        return 'application/octet-stream'
    }

    private getBaseName(path: string): string {
        return path.split('/').pop().split('?')[0]
    }

    private getFileExtension(path: string): string {
        const fileName = this.getBaseName(path)
        return fileName.substring(fileName.lastIndexOf('.'))
    }

    private getTimestampedFileName(path: string): string {
        return `${format(new Date(), 'yyyy-MM-dd-HHmmss')}${this.getFileExtension(path)}`
    }

    private async getDocumentsDirectory(): Promise<DirectoryEntry> {
        const baseDirectory = this.file.dataDirectory
        let hasDocumentsDirectory = false

        try {
            hasDocumentsDirectory = await this.file.checkDir(baseDirectory, DOCUMENTS_DIRECTORY)
        } catch (e) {
            //
        }

        if (! hasDocumentsDirectory) {
            await this.file.createDir(baseDirectory, DOCUMENTS_DIRECTORY, false)
        }

        return this.file.resolveDirectoryUrl(`${baseDirectory}/${DOCUMENTS_DIRECTORY}`)
    }

    private async copyFileToDocumentsDirectory(path: string, fileName: string): Promise<LocalFile> {
        const sourceFile = await this.file.resolveLocalFilesystemUrl(path)
        const destinationDirectory = await this.getDocumentsDirectory()

        return new Promise<LocalFile>((resolve, reject) => {
            sourceFile.copyTo(destinationDirectory, fileName, (file) => {
                resolve({
                    path: file.nativeURL,
                    publicPath: this.webview.convertFileSrc(file.nativeURL),
                })
            }, () => reject())
        })
    }

    private async writeFileDataToDocumentsDirectory(fileData: Uint8Array, fileName: string) {
        const destinationDirectory = await this.getDocumentsDirectory()
        const file = await this.file.writeFile(
            destinationDirectory.nativeURL,
            fileName,
            new Blob([fileData.buffer]),
        )

        return {
            path: file.nativeURL,
            publicPath: this.webview.convertFileSrc(file.nativeURL),
        }
    }
}
