import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx'
import { AndroidNotch } from '@awesome-cordova-plugins/android-notch/ngx'
import { Keyboard, KeyboardStyle } from '@awesome-cordova-plugins/keyboard/ngx'

import { StatusBarStyleOptions } from '@app-interfaces'

@Injectable()
export class StatusBarStyleService {

    protected darkModeEnabled: boolean
    protected statusBarStyle: StatusBarStyleOptions

    constructor(
        private readonly androidNotch: AndroidNotch,
        private readonly platform: Platform,
        private readonly statusBar: StatusBar,
        private readonly keyboard: Keyboard,
    ) {
        this.platform.ready().then(() => this.initialize())
        this.refreshDarkModeState()
    }

    protected async initialize() {
        if (! this.platform.is('hybrid')) {
            return
        }

        this.statusBar.overlaysWebView(true)

        // Android notch
        if (this.platform.is('android')) {
            const safeAreaTop = await this.androidNotch.getInsetTop()
            const defaultSafeAreaTop = 28
            document.documentElement.style.setProperty('--ion-safe-area-top', `${safeAreaTop || defaultSafeAreaTop}px`)
        }

        // Detect dark mode
        this.platform.resume.subscribe(() => this.refreshDarkModeState())
        await this.refreshDarkModeState()
    }

    protected async refreshDarkModeState() {
        const darkModeEnabled = window.matchMedia('(prefers-color-scheme: dark)')?.matches

        if (darkModeEnabled !== this.darkModeEnabled) {
            this.darkModeEnabled = darkModeEnabled

            if (darkModeEnabled) {
                document.body.classList.add('dark')
            } else {
                document.body.classList.remove('dark')
            }

            if (this.statusBarStyle) {
                this.setStatusBarStyle(this.statusBarStyle)
            }
        }
    }

    public setStatusBarStyle(statusBarStyle: StatusBarStyleOptions): void {
        this.statusBarStyle = statusBarStyle

        if (! this.platform.is('hybrid')) {
            return
        }

        const useLightContent = statusBarStyle.color === 'light'

        if (useLightContent) {
            this.statusBar.styleLightContent()
            if (this.platform.is('ios')) {
                this.keyboard.setKeyboardStyle(KeyboardStyle.Light)
            }
        } else {
            this.statusBar.styleDefault()
            if (this.platform.is('ios')) {
                this.keyboard.setKeyboardStyle(KeyboardStyle.Dark)
            }
        }
    }

}
