import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ModalController } from '@ionic/angular'

import { BaseModal } from '@app-components/modals/base.modal'
import { Note, NoteType } from '@app-components/ui'
import { RoleEnum } from '@app-graphql'
import { FormHelperService, NotesService } from '@app-services'

@Component({
    selector: 'app-note-add',
    templateUrl: './note-add.modal.html',
    styleUrls: ['./note-add.modal.scss'],
})
export class NoteAddModal extends BaseModal implements OnInit {

    @Input()
    public note: Note

    @Input()
    public noteType: NoteType

    @Input()
    public roadmapProgressId?: string

    @Input()
    public scriptId: string

    @Input()
    public studentId: string

    @Input()
    public calendarEventId: string

    @Output()
    public noteAdded = new EventEmitter<Note>()

    public apiError: string
    public loading = false
    public form: FormGroup

    public readonly NoteType = NoteType
    public readonly RoleEnum = RoleEnum

    private excludeFields: string[] = []

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly modalController: ModalController,
        private readonly notesService: NotesService,
    ) {
        super()
    }

    public ngOnInit(): void {
        let controls: { [key: string]: FormControl } = {
            text: new FormControl('', [Validators.required]),
        }

        if (this.noteType === NoteType.Script) {
            controls.roadmapProgressId = new FormControl(this.roadmapProgressId)
            controls.scriptId = new FormControl(this.scriptId)
            controls.visibleForStudent = new FormControl(true)
        }

        if (this.noteType === NoteType.Student) {
            controls.studentId = new FormControl(this.studentId)
        }

        if (this.noteType === NoteType.CalendarEvent) {
            controls.calendarEventId = new FormControl(this.calendarEventId)
            controls.visibleForStudent = new FormControl(true)
        }

        this.form = this.formBuilder.group(controls)
    }

    private storeFormValues(): { [key: string]: any } {
        this.excludeFields = ['text']
        let excludedFields: string[] = this.excludeFields
        let formValues = { ...this.form.value }

        excludedFields.forEach((field) => delete formValues[field])
        return formValues
    }

    public async submit(): Promise<void> {
        this.apiError = ''

        if (! this.form.valid) {
            this.formHelperService.reportFormErrors(this.form)
            return
        }

        if (this.loading) {
            return
        }

        // Store fields as scriptId or roadMapProgressId
        let formValues = this.storeFormValues()

        this.loading = true

        try {
            await this.notesService.createNote(this.form.value, this.noteType)

            this.formHelperService.hideKeyboard()

            this.form.reset()
            this.form.patchValue(formValues)

            this.noteAdded.emit()
            this.loading = false

            await this.modalController.dismiss()

        } catch (e) {
            this.loading = false
            this.apiError = e.message
        }
    }
}
