import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'
import { Instructor } from '@app/graphql'

@Component({
    selector: 'app-instructor-edit',
    templateUrl: './instructor-edit.modal.html',
    styleUrls: ['./instructor-edit.modal.scss'],
})
export class InstructorEditModal extends BaseModal {

    @Input()
    public instructor: Partial<Instructor>

    public workingDays = [
        { weekDay: 'Maandagen', times: [] },
        { weekDay: 'Dinsdagen', times: [] },
        { weekDay: 'Woensdagen', times: [] },
        { weekDay: 'Donderdagen', times: [] },
        { weekDay: 'Vrijdagen', times: [] },
        { weekDay: 'Zaterdagen', times: [] },
        { weekDay: 'Zondagen', times: [] },
    ]
}
