<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>Wat moet je kunnen?</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding-horizontal">

            <h2>
                Fase 1: Scholingsniveau
            </h2>
            <ion-card color="light" shape="round">
                <ion-card-content class="ion-no-padding ion-padding-3">
                    <p>
                        In deze fase maak je kennis met het script door het te lezen en de kernwoorden te begrijpen. Je
                        ontdekt waarom het script belangrijk is en hoe je het in de praktijk moet uitvoeren.
                    </p>

                    <h4 class="ion-padding-top">Kennismaking script</h4>
                    <p class="ion-padding-bottom-2">Je rijinstructeur motiveert en demonstreert. Hij demonstreert je de
                        handelingen duidelijk en
                        rustig.</p>

                    <ion-badge class="ion-color ion-color-primary">Stap 1</ion-badge>
                    <p>Je rijinstructeur geeft huiswerk zodat je je kunt voorbereiden op het nieuwe script</p>

                    <ion-badge class="ion-color ion-color-primary">Stap 2</ion-badge>
                    <p>Je rijinstructeur motiveert en demonstreert het nieuwe script</p>

                    <ion-badge class="ion-color ion-color-primary">Stap 3</ion-badge>
                    <p>Je vertelt je rijinstructeur hoe hij het script moet uitvoeren</p>

                    <div class="ion-margin-top-2" style="aspect-ratio: 16/9;">
                        <canvas [data]="barChartData[0]"
                                [legend]="false"
                                [options]="barChartOptions"
                                [type]="'bar'"
                                baseChart
                        ></canvas>
                        <div class="chart-score">
                            <app-competency-level [level]="1" interface="badge" />
                            <app-competency-level [level]="2" interface="badge" />
                            <app-competency-level [level]="3" interface="badge" />
                        </div>
                    </div>
                </ion-card-content>
            </ion-card>

            <h2 class="ion-margin-top-4">
                Fase 2: Trainingsniveau
            </h2>
            <ion-card color="light" shape="round">
                <ion-card-content class="ion-no-padding ion-padding-3">

                    <p>
                        Nu leer je om het script zelf toe te passen. Je rijinstructeur ondersteunt je in het begin maar
                        hij bouwt die hulp steeds meer af. Aan het einde van deze fase kun je eenvoudige
                        verkeerssituaties zelfstandig oplossen en uitvoeren.
                    </p>

                    <h4 class="ion-padding-top">Eigen maken script</h4>
                    <p class="ion-padding-bottom-2">Je rijinstructeur is je begeleider. Hij ondersteunt je zo veel als
                        nodig is.</p>

                    <ion-badge class="ion-color ion-color-primary">Stap 4</ion-badge>
                    <p>Je voert het script uit met ondersteuning van je rijinstructeur</p>

                    <ion-badge class="ion-color ion-color-primary">Stap 5</ion-badge>
                    <p>Je voert het script uit vrijwel zonder ondersteuning van je rijinstructeur</p>

                    <ion-badge class="ion-color ion-color-primary">Stap 6</ion-badge>
                    <p>Je voert het script zelfstandig uit</p>

                    <div class="ion-margin-top-2" style="aspect-ratio: 16/9;">
                        <canvas [data]="barChartData[1]"
                                [legend]="false"
                                [options]="barChartOptions"
                                [type]="'bar'"
                                baseChart
                        ></canvas>
                        <div class="chart-score">
                            <app-competency-level [level]="4" interface="badge" />
                            <app-competency-level [level]="5" interface="badge" />
                            <app-competency-level [level]="6" interface="badge" />
                        </div>
                    </div>
                </ion-card-content>
            </ion-card>

            <h2 class="ion-margin-top-4">
                Fase 3: Vormingsniveau
            </h2>
            <ion-card class="ion-margin-bottom" color="light" shape="round">
                <ion-card-content class="ion-no-padding ion-padding-3">
                    <p>
                        Nu ga je leren om het script in alle situaties en in combinatie met de andere scripts toe te
                        passen. Je gaat alle eerder geleerde puzzelstukjes combineren zodat je leert om je eigen scripts
                        te ontwikkelen en toe te passen. Zo wordt het autorijden helemaal iets van jezelf omdat je alle
                        verkeerssituaties zelfstandig en naar eigen inzicht mag gaan oplossen.
                    </p>

                    <h4 class="ion-padding-top">Veralgemeniseren script</h4>
                    <p class="ion-padding-bottom-2">
                        Je rijinstructeur is nu je coach die je helpt te ontdekken wat je kunt verbeteren en om
                        zelfstandig oplossingen te vinden.
                    </p>

                    <ion-badge class="ion-color ion-color-primary">Stap 7</ion-badge>
                    <p>Je voert het script zelfstandig uit in iets gewijzigde situaties</p>

                    <ion-badge class="ion-color ion-color-primary">Stap 8</ion-badge>
                    <p>
                        Je voert het script zelfstandig uit in steeds wisselende situaties en in alle gebieden
                    </p>

                    <div class="ion-margin-top-2" style="aspect-ratio: 16/9;">
                        <canvas [data]="barChartData[2]"
                                [legend]="false"
                                [options]="barChartOptions"
                                [type]="'bar'"
                                baseChart
                        ></canvas>
                        <div class="chart-score">
                            <app-competency-level [level]="7" interface="badge" />
                            <app-competency-level [level]="8" interface="badge" />
                        </div>
                    </div>
                </ion-card-content>
            </ion-card>

        </ion-content>

    </ng-template>
</ion-modal>
