import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'

import { ScriptRatingCompetencyLevel } from '@app/enums'
import { Script, ScriptRatingEnum, ScriptRatingTypeEnum, ScriptResult } from '@app/graphql'

@Component({
    selector: 'app-script-rating',
    templateUrl: './script-rating.component.html',
    styleUrls: ['./script-rating.component.scss'],
})
export class ScriptRatingComponent implements OnChanges {

    @Input()
    public script: Script

    @Input()
    public latestScriptResult: Partial<ScriptResult>

    @Input()
    public showNoteButton = true

    @Output()
    public scriptRatingChanged = new EventEmitter<ScriptRatingEnum>()

    @Output()
    public scriptRatingCleared = new EventEmitter<void>()

    @Output()
    public competencyLevelChanged = new EventEmitter<ScriptRatingCompetencyLevel>()

    @Output()
    public addNoteButtonClicked = new EventEmitter<void>()

    public scriptRating: ScriptRatingEnum
    public competencyLevel: ScriptRatingCompetencyLevel | null = null

    public readonly ScriptRatingEnum = ScriptRatingEnum
    public readonly ScriptRatingTypeEnum = ScriptRatingTypeEnum

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.latestScriptResult && this.latestScriptResult) {
            this.scriptRating = this.latestScriptResult.rating
            this.competencyLevel = ScriptRatingCompetencyLevel[this.scriptRating]
        }
    }

}
