<ng-container *ngIf="scriptResults?.length">
    <div class="chart-wrapper ion-padding">
        <canvas [data]="chartData"
                [legend]="false"
                [options]="chartOptions"
                [type]="'bar'"
                baseChart
        ></canvas>
        <div class="chart-score">
            <app-competency-level *ngFor="let scriptResult of scriptResults"
                                  [scriptRating]="scriptResult.rating"
                                  interface="badge"
            />
        </div>
    </div>
</ng-container>
