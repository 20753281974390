import { NgModule } from '@angular/core'

import { BackButtonService } from '@app-services/native/back-button.service'
import { ExternalLinkService } from '@app-services/native/external-link.service'
import { PushService } from '@app-services/native/push.service'
import { StatusBarStyleService } from '@app-services/native/status-bar-style.service'

@NgModule({
    providers: [
        BackButtonService,
        ExternalLinkService,
        PushService,
        StatusBarStyleService,
    ],
})
export class NativeServiceModule {
}
