import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'

@Injectable({
    providedIn: 'root',
})
export class AutocapitalizeFixService {

    private autocapitalizeFixElement: HTMLInputElement

    constructor(
        private readonly platform: Platform,
    ) {
    }

    public initialize(): void {
        if (this.platform.is('ios')) {
            document.addEventListener('submit', () => this.submitHandler())
        }
    }

    private submitHandler(): void {
        const element = document?.activeElement as HTMLInputElement | HTMLTextAreaElement

        if (! this.autocapitalizeFixElement) {
            this.autocapitalizeFixElement = document.querySelector('#autocapitalize-fix-input')
        }

        if (this.autocapitalizeFixElement && element && element.getAttribute('autocapitalize')) {
            this.autocapitalizeFixElement.focus()
            element.focus()
        }
    }
}
