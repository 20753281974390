import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { equals, reject } from 'ramda'
import { Subscription } from 'rxjs'

import { User, Vehicle } from '@app-graphql'
import { FormHelperService, UserService, VehiclesService } from '@app-services'
import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-vehicle-add-modal',
    templateUrl: './vehicle-add.modal.html',
    styleUrls: ['./vehicle-add.modal.scss'],
})
export class VehicleAddModal extends BaseModal implements AfterViewInit, OnDestroy {

    @Input()
    public vehicle: Partial<Vehicle>

    @Output()
    public vehicleAdded = new EventEmitter<Partial<Vehicle>>()

    public form: FormGroup
    public apiError: string
    public loading = false

    private user$: Subscription
    public user: Partial<User>

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly translateService: TranslateService,
        private readonly userService: UserService,
        private readonly vehiclesService: VehiclesService,
        private readonly toastController: ToastController,
    ) {
        super()
    }

    public async ngAfterViewInit(): Promise<void> {
        this.modal?.ionModalWillPresent?.subscribe(async () => {
            this.apiError = ''

            this.user$ = this.userService.user$.subscribe((user) => this.user = user)

            await this.initializeForm()
        })

        this.modal.canDismiss = async () => this.canDismiss()
    }

    public ngOnDestroy(): void {
        this.user$?.unsubscribe()
    }

    // noinspection JSUnusedGlobalSymbols
    public async ionViewWillEnter(): Promise<void> {
        await this.userService.getUser()
    }

    public async initializeForm(): Promise<void> {
        this.form = this.formBuilder.group({
            name: [''],
            cbrId: [''],
            licensePlate: ['', [Validators.required]],
            brand: ['', [Validators.required]],
            model: ['', [Validators.required]],
            year: [+new Date().getFullYear(), [Validators.required]],
        })
    }

    private async canDismiss(): Promise<boolean> {
        if (! this.form.dirty) {
            return true
        }

        const shouldDiscardChanges = await this.formHelperService.confirmDiscardChanges()

        if (shouldDiscardChanges) {
            this.form.reset()
        }

        return shouldDiscardChanges
    }

    public async submit(): Promise<void> {
        this.apiError = ''

        if (this.loading) {
            return
        }

        if (! this.form.valid) {
            this.formHelperService.reportFormErrors(this.form)

            return
        }

        this.loading = true

        let message = this.translateService.instant('modals.organization.vehicle.add.success')

        this.form.markAsPristine()

        try {
            const data = {
                drivingSchoolId: this.userService.getDrivingSchool()?.id,
                name: this.form.controls.name.value,
                cbrId: this.form.controls.cbrId.value,
                licensePlate: this.form.controls.licensePlate.value,
                brand: this.form.controls.brand.value,
                model: this.form.controls.model.value,
                year: +this.form.controls.year.value,
            }

            const response = await this.vehiclesService.createVehicle(
                reject(equals(null))(data),
            )

            this.vehicleAdded.emit(response.createVehicle as Partial<Vehicle>)

            this.loading = false

            await this.dismiss()
        } catch (e) {
            this.loading = false
            this.apiError = e.message
            this.formHelperService.reportFormErrors(this.form)
            message = this.translateService.instant('modals.organization.vehicle.add.error')
        }

        await this.toastController.create({
            message,
            duration: 3000,
        }).then((toast) => toast.present())
    }
}
