import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'
import { RelatedScript } from '@app-graphql'

@Component({
    selector: 'app-lesson-measure',
    templateUrl: './lesson-measure.modal.html',
    styleUrls: ['./lesson-measure.modal.scss'],
})
export class LessonMeasureModal extends BaseModal implements OnChanges {

    @Input()
    public relatedScripts: RelatedScript[]

    @Input()
    public usedRelatedScripts: RelatedScript[] = []

    @Output()
    public selectionChanged = new EventEmitter<RelatedScript[]>()

    public newRelatedScripts: RelatedScript[] = []

    public usedRelatedScriptIds: string[] = []

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.usedRelatedScripts?.currentValue?.length) {
            this.usedRelatedScriptIds = changes.usedRelatedScripts.currentValue.map((s) => s.id)
        }
    }

    public toggleRelatedScript(relatedScript: RelatedScript, checked: boolean): void {
        if (checked) {
            this.newRelatedScripts.push(relatedScript)
        } else {
            this.newRelatedScripts.splice(
                this.newRelatedScripts.findIndex((s) => s.id === relatedScript.id),
                1,
            )
        }
    }

}
