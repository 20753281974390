import { Component, Input } from '@angular/core'

import { BaseModal } from '@app/components/modals/base.modal'
import { ChartData, ChartOptions } from 'chart.js'

@Component({
    selector: 'app-script-skills',
    templateUrl: './script-skills.modal.html',
    styleUrls: ['./script-skills.modal.scss'],
})
export class ScriptSkillsModal extends BaseModal {

    @Input()
    public barChartData: ChartData<'bar'>[]

    @Input()
    public barChartOptions: ChartOptions<'bar'>

    constructor() {
        super()
        this.setBarChartDefaults()
    }

    private setBarChartDefaults(): void {
        this.barChartData = [
            {
                labels: Array.from({ length: 3 }).fill(''),
                datasets: [
                    {
                        data: [2, 2, 3],
                        label: 'Score',
                        backgroundColor: '#E61B73',
                        borderRadius: 50,
                        barThickness: 12,
                    },
                ],
            },
            {
                labels: Array.from({ length: 3 }).fill(''),
                datasets: [
                    {
                        data: [4, 5, 6],
                        label: 'Score',
                        backgroundColor: '#E61B73',
                        borderRadius: 50,
                        barThickness: 12,
                    },
                ],
            },
            {
                labels: Array.from({ length: 2 }).fill(''),
                datasets: [
                    {
                        data: [7, 8],
                        label: 'Score',
                        backgroundColor: '#E61B73',
                        borderRadius: 50,
                        barThickness: 12,
                    },
                ],
            },
        ]

        this.barChartOptions = {
            scales: {
                x: {
                    grid: { display: false },
                    ticks: { display: false },
                },
                y: {
                    border: { display: false },
                    grid: { drawTicks: false },
                    ticks: { display: false },
                    min: 0,
                    max: 8,
                },
            },
            animation: false,
        }
    }
}
