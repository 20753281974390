<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.students.student.add.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding">

            <form (submit)="submit()"
                  *ngIf="form"
                  [formGroup]="form"
                  action=""
                  id="student-add-form"
            >

                <ion-input formControlName="drivingSchoolId" type="hidden" />

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">Voornaam</ion-label>
                    <ion-input autocapitalize="words"
                               autocomplete="given-name"
                               formControlName="firstName"
                               placeholder="Vul een voornaam in"
                               type="text"
                    />
                    <ion-note [hidden]="! form.get('firstName').dirty" slot="error">
                        {{ 'modals.students.student.add.validation.firstNameInvalid' | translate }}
                    </ion-note>
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">Achternaam</ion-label>
                    <ion-input autocapitalize="words"
                               autocomplete="family-name"
                               formControlName="lastName"
                               placeholder="Vul een achternaam in"
                               type="text"
                    />
                    <ion-note [hidden]="! form.get('lastName').dirty" slot="error">
                        {{ 'modals.students.student.add.validation.lastNameInvalid' | translate }}
                    </ion-note>
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">E-mailadres</ion-label>
                    <ion-input autocomplete="email"
                               formControlName="email"
                               placeholder="Vul een e-mailadres in"
                               type="email"
                    />
                    <ion-note [hidden]="form.get('email').valid || ! form.get('email').dirty"
                              slot="error"
                    >
                        {{ 'modals.students.student.add.validation.emailInvalid' | translate }}
                    </ion-note>
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">Telefoonnummer</ion-label>
                    <ion-input autocomplete="tel"
                               formControlName="phone"
                               placeholder="Vul een telefoonnummer in"
                               type="tel"
                    />
                </ion-item>

                <app-date-picker [max]="'today' | dateFromString | dfnsSubYears : 16"
                                 [min]="'today' | dateFromString | dfnsStartOfYear | dfnsSubYears : 100"
                                 class="ion-margin-bottom"
                                 formControlName="birthdate"
                                 placeholder="Vul een geboortedatum in"
                >
                    <ion-label position="stacked">
                        Geboortedatum
                    </ion-label>
                    <ion-note [hidden]="form.get('birthdate').valid || ! form.get('birthdate').dirty"
                              class="error"
                    >
                        {{ 'modals.students.student.add.validation.birthdateInvalid' | translate }}
                    </ion-note>
                </app-date-picker>

                <ng-container formGroupName="address">
                    <ion-item class="ion-margin-bottom" lines="none">
                        <ion-label position="stacked">Thuisadres straatnaam</ion-label>
                        <ion-input autocapitalize="words"
                                   formControlName="street"
                                   placeholder="Vul een straatnaam in"
                                   type="text"
                        />
                        <ion-note [hidden]="form.get('address.street').valid || ! form.get('address.street').dirty"
                                  slot="error"
                        >
                            {{ 'modals.students.student.add.validation.address.streetInvalid' | translate }}
                        </ion-note>
                    </ion-item>

                    <div class="ion-flex ion-flex-row ion-align-items-center">
                        <ion-item class="ion-margin-bottom" lines="none">
                            <ion-label position="stacked">Thuisadres huisnummer</ion-label>
                            <ion-input autocapitalize="words"
                                       formControlName="number"
                                       placeholder="Vul een huisnummer in"
                                       type="text"
                            />
                            <ion-note [hidden]="form.get('address.number').valid || ! form.get('address.number').dirty"
                                      slot="error"
                            >
                                {{ 'modals.students.student.add.validation.address.numberInvalid' | translate }}
                            </ion-note>
                        </ion-item>

                        <ion-item class="ion-margin-bottom ion-margin-start" lines="none">
                            <ion-label position="stacked">Thuisadres toevoeging</ion-label>
                            <ion-input autocapitalize="words"
                                       formControlName="numberAddition"
                                       placeholder="Vul een huisnummertoevoeging in"
                                       type="text"
                            />
                        </ion-item>
                    </div>

                    <ion-item class="ion-margin-bottom" lines="none">
                        <ion-label position="stacked">Thuisadres postcode</ion-label>
                        <ion-input autocapitalize="characters"
                                   autocomplete="postal-code"
                                   formControlName="postalCode"
                                   pattern="[0-9]{4}[A-Za-z]{2}"
                                   placeholder="Vul een postcode in"
                                   type="text"
                        />
                        <ion-note [hidden]="form.get('address.postalCode').valid || ! form.get('address.postalCode').dirty"
                                  slot="error"
                        >
                            {{ 'modals.students.student.add.validation.address.postalCodeInvalid' | translate }}
                        </ion-note>
                    </ion-item>

                    <ion-item class="ion-margin-bottom" lines="none">
                        <ion-label position="stacked">Thuisadres plaatsnaam</ion-label>
                        <ion-input autocapitalize="words"
                                   autocomplete="address-level2"
                                   formControlName="city"
                                   placeholder="Vul een plaatsnaam in"
                                   type="text"
                        />
                        <ion-note [hidden]="form.get('address.city').valid || ! form.get('address.city').dirty"
                                  slot="error"
                        >
                            {{ 'modals.students.student.add.validation.address.cityInvalid' | translate }}
                        </ion-note>
                    </ion-item>
                </ng-container>

                <!-- From here invoiceAddress -->
                <ion-item class="ion-margin-top-2" fill="clear" lines="none">
                    <ion-checkbox (ionChange)="toggleFormGroup(form.get('invoiceAddress'), ! $any($event).detail);"
                                  [formControl]="invoiceSame"
                                  slot="start"
                    ></ion-checkbox>
                    <ion-label>Factuuradres is hetzelfde als thuisadres</ion-label>
                </ion-item>
                <div [hidden]="invoiceSame.value" formGroupName="invoiceAddress">
                    <ion-item class="ion-margin-bottom ion-margin-top-2" lines="none">
                        <ion-label position="stacked">Straatnaam</ion-label>
                        <ion-input autocapitalize="words"
                                   formControlName="street"
                                   placeholder="Vul een straatnaam in"
                                   type="text"
                        />
                        <ion-note [hidden]="form.get('invoiceAddress.street').valid || ! form.get('invoiceAddress.street').dirty"
                                  slot="error"
                        >
                            {{ 'modals.students.student.add.validation.address.streetInvalid' | translate }}
                        </ion-note>
                    </ion-item>

                    <div class="ion-flex ion-flex-row ion-align-items-center">
                        <ion-item class="ion-margin-bottom" lines="none">
                            <ion-label position="stacked">Huisnummer</ion-label>
                            <ion-input autocapitalize="words"
                                       formControlName="number"
                                       placeholder="Vul een huisnummer in"
                                       type="text"
                            />
                            <ion-note [hidden]="form.get('invoiceAddress.number').valid || ! form.get('invoiceAddress.number').dirty"
                                      slot="error"
                            >
                                {{ 'modals.students.student.add.validation.address.numberInvalid' | translate }}
                            </ion-note>
                        </ion-item>

                        <ion-item class="ion-margin-bottom ion-margin-start" lines="none">
                            <ion-label position="stacked">Toevoeging</ion-label>
                            <ion-input autocapitalize="words"
                                       formControlName="numberAddition"
                                       placeholder="Vul een huisnummertoevoeging in"
                                       type="text"
                            />
                        </ion-item>
                    </div>

                    <ion-item class="ion-margin-bottom" lines="none">
                        <ion-label position="stacked">Postcode</ion-label>
                        <ion-input autocapitalize="characters"
                                   autocomplete="postal-code"
                                   formControlName="postalCode"
                                   pattern="[0-9]{4}[A-Za-z]{2}"
                                   placeholder="Vul een postcode in"
                                   type="text"
                        />
                        <ion-note [hidden]="form.get('invoiceAddress.postalCode').valid || ! form.get('invoiceAddress.postalCode').dirty"
                                  slot="error"
                        >
                            {{ 'modals.students.student.add.validation.address.postalCodeInvalid' | translate }}
                        </ion-note>
                    </ion-item>

                    <ion-item class="ion-margin-bottom" lines="none">
                        <ion-label position="stacked">Plaatsnaam</ion-label>
                        <ion-input autocapitalize="words"
                                   autocomplete="address-level2"
                                   formControlName="city"
                                   placeholder="Vul een plaatsnaam in"
                                   type="text"
                        />
                        <ion-note [hidden]="form.get('invoiceAddress.city').valid || ! form.get('invoiceAddress.city').dirty"
                                  slot="error"
                        >
                            {{ 'modals.students.student.add.validation.address.cityInvalid' | translate }}
                        </ion-note>
                    </ion-item>
                </div>

                <!-- From here pickupAddress -->
                <ion-item class="ion-margin-top-2" fill="clear" lines="none">
                    <ion-checkbox (ionChange)="toggleFormGroup(form.get('pickupAddress'), ! $any($event).detail);"
                                  [formControl]="pickupSame"
                                  slot="start"
                    ></ion-checkbox>
                    <ion-label>Ophaaladres is hetzelfde als thuisadres</ion-label>
                </ion-item>
                <div [hidden]="pickupSame.value" formGroupName="pickupAddress">
                    <ion-item class="ion-margin-bottom ion-margin-top-2" lines="none">
                        <ion-label position="stacked">Straatnaam</ion-label>
                        <ion-input autocapitalize="words"
                                   formControlName="street"
                                   placeholder="Vul een straatnaam in"
                                   type="text"
                        />
                        <ion-note [hidden]="form.get('pickupAddress.street').valid || ! form.get('pickupAddress.street').dirty"
                                  slot="error"
                        >
                            {{ 'modals.students.student.add.validation.address.streetInvalid' | translate }}
                        </ion-note>
                    </ion-item>

                    <div class="ion-flex ion-flex-row ion-align-items-center">
                        <ion-item class="ion-margin-bottom" lines="none">
                            <ion-label position="stacked">Huisnummer</ion-label>
                            <ion-input autocapitalize="words"
                                       formControlName="number"
                                       placeholder="Vul een huisnummer in"
                                       type="text"
                            />
                            <ion-note [hidden]="form.get('pickupAddress.number').valid || ! form.get('pickupAddress.number').dirty"
                                      slot="error"
                            >
                                {{ 'modals.students.student.add.validation.address.numberInvalid' | translate }}
                            </ion-note>
                        </ion-item>

                        <ion-item class="ion-margin-bottom ion-margin-start" lines="none">
                            <ion-label position="stacked">Toevoeging</ion-label>
                            <ion-input autocapitalize="words"
                                       formControlName="numberAddition"
                                       placeholder="Vul een huisnummertoevoeging in"
                                       type="text"
                            />
                        </ion-item>
                    </div>

                    <ion-item class="ion-margin-bottom" lines="none">
                        <ion-label position="stacked">Postcode</ion-label>
                        <ion-input autocapitalize="characters"
                                   autocomplete="postal-code"
                                   formControlName="postalCode"
                                   pattern="[0-9]{4}[A-Za-z]{2}"
                                   placeholder="Vul een postcode in"
                                   type="text"
                        />
                        <ion-note [hidden]="form.get('pickupAddress.postalCode').valid || ! form.get('pickupAddress.postalCode').dirty"
                                  slot="error"
                        >
                            {{ 'modals.students.student.add.validation.address.postalCodeInvalid' | translate }}
                        </ion-note>
                    </ion-item>

                    <ion-item class="ion-margin-bottom" lines="none">
                        <ion-label position="stacked">Plaatsnaam</ion-label>
                        <ion-input autocapitalize="words"
                                   autocomplete="address-level2"
                                   formControlName="city"
                                   placeholder="Vul een plaatsnaam in"
                                   type="text"
                        />
                        <ion-note [hidden]="form.get('pickupAddress.city').valid || ! form.get('pickupAddress.city').dirty"
                                  slot="error"
                        >
                            {{ 'modals.students.student.add.validation.address.cityInvalid' | translate }}
                        </ion-note>
                    </ion-item>
                </div>

                <ion-item class="ion-margin-vertical" lines="none">
                    <ion-label position="stacked">
                        <h3>Voorkeursinstructeurs</h3>
                    </ion-label>
                    <ion-select [multiple]="true"
                                formControlName="preferredInstructorIds"
                                placeholder="Maak een keuze..."
                    >
                        <ion-select-option *ngFor="let instructor of instructors" [value]="instructor.id">
                            {{ instructor.user?.firstName }} {{ instructor.user?.lastName }}
                        </ion-select-option>
                    </ion-select>
                    <ion-note [hidden]="form.get('preferredInstructorIds').valid || ! form.get('preferredInstructorIds').dirty"
                              slot="error"
                    >
                        {{ 'modals.students.student.add.validation.preferredInstructorIdsInvalid' | translate }}
                    </ion-note>
                </ion-item>

                <ion-card [hidden]="! apiError" class="ion-margin-vertical">
                    <ion-card-content class="error-message">
                        {{ apiError | translate }}
                    </ion-card-content>
                </ion-card>

                <input class="semi-hidden" tabindex="-1" type="submit" value="" />
            </form>
        </ion-content>

        <ion-footer>
            <ion-toolbar class="ion-color ion-color-light">
                <ion-button class="ion-color ion-color-tertiary ion-margin"
                            expand="block"
                            form="student-add-form"
                            type="submit"
                >
                    <span [hidden]="loading">Student toevoegen</span>
                    <ion-spinner [hidden]="! loading" slot="icon-only" />
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ng-template>
</ion-modal>
