import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { firstValueFrom, map, Subject } from 'rxjs'

import { EducationCategoriesQuery, EducationCategoriesQueryService, EducationCategory } from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services/api/api-helper.service'

@Injectable({
    providedIn: 'root',
})
export class EducationService {

    public educationCategories$ = new Subject<EducationCategory[]>()

    private educationCategories: EducationCategory[]

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly educationCategoriesQueryService: EducationCategoriesQueryService,
    ) {
    }

    public async getEducationCategories(
        hasRoadmap: boolean,
        cacheOptions?: CacheOptions,
    ): Promise<EducationCategory[] | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(cacheOptions, 'education.educationCategories')
        const educationCategories$ = this.educationCategoriesQueryService
            .fetch({ input: { hasRoadmap } }, { fetchPolicy })
            .pipe(
                map((result: ApolloQueryResult<EducationCategoriesQuery>) => {
                    this.educationCategories = result.data.educationCategories
                    this.educationCategories$.next(this.educationCategories)

                    return this.educationCategories
                }),
            )
        try {
            return await firstValueFrom(educationCategories$)
        } catch (e) {
            await this.apiHelperService.showHttpError()
        }
    }

}
