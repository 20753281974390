import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ModalController } from '@ionic/angular'
import { Subscription } from 'rxjs'
import { CacheService } from 'ionic-cache'

import { BaseModal } from '@app-components/modals/base.modal'
import { FormHelperService, NotesService } from '@app-services'
import { Note, NoteType } from '@app-components/ui'
import { CalendarEventNote, ScriptNote } from '@app-graphql'

@Component({
    selector: 'app-note-edit',
    templateUrl: './note-edit.modal.html',
    styleUrls: ['./note-edit.modal.scss'],
})
export class NoteEditModal extends BaseModal implements OnInit {
    @Input()
    public note: Note

    @Input()
    public noteType: NoteType

    @Output()
    public noteUpdated = new EventEmitter<Note>()

    public apiError: string
    public section: string
    public loading = false

    public form: FormGroup
    public readonly NoteType = NoteType

    private note$: Subscription

    constructor(
        private readonly cacheService: CacheService,
        private readonly notesService: NotesService,
        private readonly modalController: ModalController,
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
    ) {
        super()

        this.note$ = this.notesService.note$.subscribe((note) => {
            this.note = note
        })
    }

    public ngOnInit(): void {
        let controls: { [key: string]: FormControl } = {
            text: new FormControl(this.note?.text, [Validators.required]),
        }

        if (this.noteType === NoteType.Script) {
            const scriptNote = this.note as ScriptNote
            controls.visibleForStudent = new FormControl(scriptNote.visibleForStudent)
        }

        if (this.noteType === NoteType.CalendarEvent) {
            const calendarEventNote = this.note as CalendarEventNote
            controls.visibleForStudent = new FormControl(calendarEventNote.visibleForStudent)
        }

        this.form = this.formBuilder.group(controls)
    }

    public async update(): Promise<void> {
        this.apiError = ''

        if (! this.form.valid) {
            this.formHelperService.reportFormErrors(this.form)
            return
        }

        if (this.loading) {
            return
        }

        this.loading = true

        try {
            await this.notesService.updateNote({ id: this.note.id, ...this.form.value }, this.noteType)

            this.loading = false
            this.noteUpdated.emit(this.note)

            this.formHelperService.hideKeyboard()
            await this.modalController.dismiss()
        } catch (e) {
            this.loading = false
            this.apiError = e.message
        }
    }
}
