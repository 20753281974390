import { Injectable } from '@angular/core'
import { firstValueFrom, map, Subject } from 'rxjs'

import {
    Instructor,
    InstructorQuery,
    InstructorQueryService,
    InstructorsQuery,
    InstructorsQueryService,
    QueryInstructorsOrderByColumn,
    SortOrder,
} from '@app-graphql'
import { ApiHelperService, CacheOptions, PaginationOptions } from '@app-services/api/api-helper.service'
import { ApolloQueryResult } from '@apollo/client/core'

@Injectable({
    providedIn: 'root',
})
export class InstructorsService {

    public instructors$ = new Subject<Instructor[]>()
    public instructor$ = new Subject<Instructor>()

    private instructors: Instructor[]
    private instructor: Instructor

    public instructorPaginationOptions: PaginationOptions = {
        first: 999,
        page: 1,
        orderBy: [
            {
                order: SortOrder.Asc,
                column: QueryInstructorsOrderByColumn.FirstName,
            },
        ],
    }

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly instructorsQueryService: InstructorsQueryService,
        private readonly instructorQueryService: InstructorQueryService,
    ) {
        //
    }

    public async getInstructors(
        paginationOptions: PaginationOptions = this.instructorPaginationOptions,
        cacheOptions?: CacheOptions,
    ): Promise<Instructor[]> {

        const fetchPolicy = paginationOptions?.search
            ? 'network-only'
            : await this.apiHelperService.getFetchPolicy(
                cacheOptions,
                `instructors.instructors_${paginationOptions?.page}_${btoa(JSON.stringify(paginationOptions?.orderBy))}`,
            )

        const instructors$ = this.instructorsQueryService.fetch(paginationOptions, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<InstructorsQuery>) => {
                this.instructors = result.data.instructors.data as Instructor[]
                this.instructors$.next(this.instructors)
                return this.instructors
            }),
        )

        return firstValueFrom(instructors$)
    }

    public async getInstructorById(id: string, cacheOptions?: CacheOptions): Promise<Instructor> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `instructors.instructor_${id}`,
        )
        const instructor$ = this.instructorQueryService.fetch({ input: { id } }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<InstructorQuery>) => {
                this.instructor = result.data.instructor as Instructor
                this.instructor$.next(this.instructor)

                return this.instructor
            }),
        )

        return firstValueFrom(instructor$)
    }

}
