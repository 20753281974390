import { ApolloQueryResult } from '@apollo/client/core'
import { Injectable } from '@angular/core'
import { firstValueFrom, map, Subject } from 'rxjs'

import { ApiHelperService, CacheOptions, PaginationOptions } from '@app-services/api/api-helper.service'
import {
    CreateVehicleMutationService,
    DeleteVehicleMutationService,
    UpdateVehicleMutationService,
    QueryVehiclesOrderByColumn,
    SortOrder,
    Vehicle,
    VehiclesQuery,
    VehiclesQueryService,
    CreateVehicleMutation,
    CreateVehicleInput,
    DeleteVehicleInput,
    DeleteVehicleMutation,
    UpdateVehicleInput,
    UpdateVehicleMutation,
    CreateMileageMutationService,
    CreateMileageInput,
    CreateMileageMutation,
    DeleteMileageMutation,
    DeleteMileageInput,
    DeleteMileageMutationService,
    Mileage,
    MileagesQuery,
    MileagesQueryService,
    QueryMileagesOrderByColumn,
    QueryMileagesOrderByOrderByClause,
} from '@app-graphql'

interface MileagesPaginationOptions {
    first: number;
    page: number;
    orderBy?: any | QueryMileagesOrderByOrderByClause | QueryMileagesOrderByOrderByClause[];
    vehicleId: string;
}

@Injectable({
    providedIn: 'root',
})
export class VehiclesService {

    public mileages$ = new Subject<Mileage[]>()
    public vehicles$ = new Subject<Vehicle[]>()

    public vehicles: Vehicle[]
    public mileages: Mileage[]

    public vehiclePaginationOptions: PaginationOptions = {
        first: 999,
        page: 1,
        orderBy: [
            {
                order: SortOrder.Asc,
                column: QueryVehiclesOrderByColumn.Name,
            },
        ],
    }

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly vehiclesQueryService: VehiclesQueryService,
        private readonly mileagesQueryService: MileagesQueryService,
        private readonly createVehicleService: CreateVehicleMutationService,
        private readonly createMileageService: CreateMileageMutationService,
        private readonly deleteVehicleService: DeleteVehicleMutationService,
        private readonly deleteMileageService: DeleteMileageMutationService,
        private readonly updateVehicleService: UpdateVehicleMutationService,
    ) {
    }

    public async getVehicles(
        paginationOptions: PaginationOptions = this.vehiclePaginationOptions,
        cacheOptions?: CacheOptions,
    ): Promise<Vehicle[]> | null {

        const fetchPolicy = paginationOptions?.search
            ? 'network-only'
            : await this.apiHelperService.getFetchPolicy(
                cacheOptions,
                `vehicles.vehicles_${paginationOptions?.page}_${btoa(JSON.stringify(paginationOptions?.orderBy))}`,
            )

        const vehicles$ = this.vehiclesQueryService.fetch(paginationOptions, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<VehiclesQuery>) => {
                this.vehicles = result.data.vehicles.data as Vehicle[]
                this.vehicles$.next(this.vehicles)

                return this.vehicles
            }),
        )

        return firstValueFrom(vehicles$)
    }

    public async createVehicle(input: CreateVehicleInput): Promise<CreateVehicleMutation> {
        try {
            const response = await firstValueFrom(
                this.createVehicleService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async deleteVehicle(input: DeleteVehicleInput): Promise<DeleteVehicleMutation> {
        try {
            const response = await firstValueFrom(
                this.deleteVehicleService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async updateVehicle(input: UpdateVehicleInput): Promise<UpdateVehicleMutation> {
        try {
            const response = await firstValueFrom(
                this.updateVehicleService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async getMileagesByVehicleId(
        paginationOptions?: MileagesPaginationOptions,
        cacheOptions?: CacheOptions,
    ): Promise<Mileage[]> | null {
        const options: MileagesPaginationOptions = paginationOptions || {
            first: 1000,
            page: 1,
            orderBy: [
                {
                    order: SortOrder.Desc,
                    column: QueryMileagesOrderByColumn.RegisteredAt,
                },
            ],
            vehicleId: '',
        }

        const fetchPolicy = options?.vehicleId
            ? 'network-only'
            : await this.apiHelperService.getFetchPolicy(
                cacheOptions,
                `mileages.mileages_${options?.page}_${btoa(JSON.stringify(options?.orderBy))}_${options?.vehicleId}`,
            )

        const mileages$ = this.mileagesQueryService.fetch(options, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<MileagesQuery>) => {
                this.mileages = result.data.mileages.data as Mileage[]
                this.mileages$.next(this.mileages)

                return this.mileages
            }),
        )

        return firstValueFrom(mileages$)
    }

    public async createMileage(input: CreateMileageInput): Promise<CreateMileageMutation> {
        try {
            const response = await firstValueFrom(
                this.createMileageService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async deleteMileage(input: DeleteMileageInput): Promise<DeleteMileageMutation> {
        try {
            const response = await firstValueFrom(
                this.deleteMileageService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }
}
