import { Component, Input } from '@angular/core'

import { Script, ScriptProgress } from '@app/graphql'

@Component({
    selector: 'app-roadmap-work-in-progress',
    templateUrl: './roadmap-work-in-progress.component.html',
    styleUrls: ['./roadmap-work-in-progress.component.scss'],
})
export class RoadmapWorkInProgressComponent {

    @Input()
    public workInProgress: Partial<Script>[]

    @Input()
    public scriptProgresses: ScriptProgress[]

    @Input()
    public studentId: string

    @Input()
    public calendarEventId: string

}
