import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { ToastController } from '@ionic/angular'
import { addDays, addHours, addMinutes, format, isSameDay, startOfDay } from 'date-fns'
import { equals, reject } from 'ramda'
import { merge, Subscription } from 'rxjs'

import { BaseModal } from '@app-components/modals/base.modal'
import {
    CalendarEvent,
    CalendarEventNote,
    CalendarEventType,
    CreateCalendarEventInput,
    EducationCategory,
    EventStatusEnum,
    ExamProduct,
    ExamTypeEnum,
    Instructor,
    PlannableProductInterface,
    Product,
    ProductTypeEnum,
    RoleEnum,
    Student,
    UpdateCalendarEventInput,
    User,
    Vehicle,
} from '@app-graphql'
import {
    CalendarEventsService,
    EducationService,
    FormHelperService,
    InstructorsService,
    NotesService,
    ProductsService,
    StudentsService,
    UserService,
    VehiclesService,
} from '@app-services'

@Component({
    selector: 'app-calendar-event-edit',
    templateUrl: './calendar-event-edit.modal.html',
    styleUrls: ['./calendar-event-edit.modal.scss'],
})
export class CalendarEventEditModal extends BaseModal implements AfterViewInit, OnDestroy {
    @Output()
    public eventCreatedOrUpdated = new EventEmitter<void>()

    @Output()
    public instructorChanged = new EventEmitter<string>()

    @Input()
    public event: Partial<CalendarEvent> = {}

    @Input()
    public section: string

    @Input()
    public defaultDate: Date | null = null

    public readonly EventStatusEnum = EventStatusEnum

    public form: FormGroup
    public initialFormValue: any

    public apiError: string
    public loading = false

    public user: Partial<User>
    public instructorId: string
    public educationCategories: Partial<EducationCategory[]> = []
    public instructors: Partial<Instructor[]> = []
    public students: Partial<Student[]> = []
    public products: Partial<Product>[] = []
    public vehicles: Partial<Vehicle>[] = []

    public calendarEventTypes: CalendarEventType[] = []
    public startsAtTimePickerOptions: { value: string, label: string }[]
    public endsAtTimePickerOptions: { value: string, label: string }[]
    public studentWithAddresses: Partial<Student>

    private isSettingFormValues: boolean = false

    private user$: Subscription
    private educationCategories$: Subscription
    private instructors$: Subscription
    private students$: Subscription
    private products$: Subscription
    private vehicles$: Subscription

    constructor(
        public activatedRoute: ActivatedRoute,
        private readonly calendarEventsService: CalendarEventsService,
        private readonly educationService: EducationService,
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly instructorService: InstructorsService,
        private readonly notesService: NotesService,
        private readonly productsService: ProductsService,
        private readonly studentsService: StudentsService,
        private readonly toastController: ToastController,
        private readonly translateService: TranslateService,
        private readonly vehiclesService: VehiclesService,
        private readonly userService: UserService,
    ) {
        super()

        this.user$ = this.userService.user$.subscribe((user) => {
            this.user = user

            this.event = {
                ...this.event,
                author: {
                    ...this.event.author,
                    ...(this.user as Partial<User>),
                },
            }

            const calendarEventTypes = this.userService.getDrivingSchool()?.organization?.calendarEventTypes ?? []
            this.calendarEventTypes = [...calendarEventTypes].sort((a, b) => a.name.localeCompare(b.name))
        })

        this.educationCategories$ = this.educationService.educationCategories$.subscribe((educationCategories) => {
            this.educationCategories = educationCategories
        })

        this.instructors$ = this.instructorService.instructors$.subscribe((instructors) => {
            this.instructors = instructors
        })

        this.students$ = this.studentsService.students$.subscribe((students) => {
            this.students = students
        })

        this.products$ = this.productsService.products$.subscribe((products) => {
            this.products = products
        })

        this.vehicles$ = this.vehiclesService.vehicles$.subscribe((vehicles) => {
            this.vehicles = vehicles
        })
    }

    public async ngAfterViewInit(): Promise<void> {
        this.modal?.ionModalWillPresent?.subscribe(async () => {
            this.apiError = ''

            if (this.defaultDate) {
                await this.initializeForm()
            }
        })

        this.activatedRoute.queryParams.subscribe((params) => {
            this.instructorId = params.instructorId
        })

        await Promise.all([
            this.userService.getUser(),
            this.educationService.getEducationCategories(null),
            this.instructorService.getInstructors(),
            this.studentsService.getStudents(),
            this.vehiclesService.getVehicles(),
        ])

        this.modal.canDismiss = async () => this.canDismiss()
        await this.initializeForm()
    }

    public ngOnDestroy(): void {
        this.user$?.unsubscribe()
        this.educationCategories$?.unsubscribe()
        this.instructors$?.unsubscribe()
        this.students$?.unsubscribe()
        this.products$?.unsubscribe()
        this.vehicles$?.unsubscribe()
    }

    public async initializeForm(): Promise<void> {
        let controls: { [key: string]: FormControl } = {
            // description: new FormControl(this.event.description),

            instructors: new FormControl(null, Validators.required),

            status: new FormControl((this.event.status ?? EventStatusEnum.Final), Validators.required),
            educationCategory: new FormControl(this.event.product?.educationCategory, Validators.required),

            vehicles: new FormControl(this.event.vehicles, Validators.required),
            students: new FormControl(null),

            productDuration: new FormControl(0),

            date: new FormControl(
                this.parseDate(this.event.startsAt) ?? new Date(),
                Validators.required,
            ),
            startTime: new FormControl(
                format(this.parseDate(this.event.startsAt) ?? new Date(), 'HH:mm') ?? null,
                Validators.required,
            ),
            endTime: new FormControl(
                format(this.parseDate(this.event.endsAt) ?? new Date(), 'HH:mm') ?? null,
                Validators.required,
            ),

            locationType: new FormControl('home', Validators.required),
            locationOther: new FormControl(this.event.location ?? null),

            tableNumber: new FormControl(this.event.tableNumber ?? null, Validators.required),
        }

        if (! this.event.id) {
            controls = {
                ...controls,
                type: new FormControl(this.event.type),
                product: new FormControl(this.event.product),
                note: new FormControl(),
            }
        }

        this.form = this.formBuilder.group(controls)

        // Pre-fill the form with default values
        this.setParticipants()
        this.setDefaultInstructorAndVehicle()
        this.setProduct()

        await this.updateLocationValues()

        if (this.event.id) {
            this.generateTimePickerOptions()
            this.form.controls.educationCategory.clearValidators()
        } else {
            this.createByDate(this.defaultDate || new Date())
            this.defaultDate = null
            setTimeout(() => this.form.markAsPristine(), 100)
        }

        this.form.controls.instructors.valueChanges.subscribe((selectedInstructors: Partial<Instructor>[]) => {
            if (! this.isSettingFormValues) {
                this.updateVehiclesForInstructors(selectedInstructors)
            }
        })

        this.form.controls.product?.valueChanges?.subscribe(() => this.updateProductFields())

        if (this.instructorId) {
            this.updateInstructor(this.instructorId)
        }

        this.initialFormValue = this.form.value
    }

    private async canDismiss(): Promise<boolean> {

        // If the form wasn't changed, we can leave the page without prompting
        if (! this.form.dirty) {
            return true
        }

        // Ask the user whether they want to discard their changes
        const shouldDiscardChanges = await this.formHelperService.confirmDiscardChanges()

        // Reset the form if the user wants to discard changes
        if (shouldDiscardChanges) {
            this.form.reset()
            this.updateTypeAndProductValues()
            this.event.location = null

            await this.initializeForm()
        }

        // We are allowed to leave the page if the changes should be discarded
        return shouldDiscardChanges
    }

    public setDefaultInstructorAndVehicle(): void {
        if (this.event.id) {
            return
        }

        if (this.user.instructor?.id && ! this.form.get('instructors').value?.length) {
            this.form.controls.instructors.setValue([this.user.instructor])

            if (this.user.instructor?.preferredVehicle?.id && ! this.form.get('vehicles').value?.length) {
                this.form.controls.vehicles.setValue([this.user.instructor.preferredVehicle])
            }
        }
    }

    public setParticipants(): void {
        if (this.form?.controls && this.event?.calendarEventParticipants) {
            const selectedVehicles = this.event?.vehicles
                ? this.vehicles.filter((vehicle) => {
                    return this.event.vehicles.some((selectedVehicle) => selectedVehicle.id === vehicle.id)
                })
                : []
            this.form.controls.vehicles.setValue(selectedVehicles)

            const instructorsAndStudents: Partial<Instructor | Student>[] = this.event?.calendarEventParticipants
                .filter((participant) => !! (participant.actor as Instructor | Student).user)
                .map((participant) => participant.actor)

            const participatingInstructors = instructorsAndStudents
                .filter((instructor) => instructor.user.roles.some(role => role.name === RoleEnum.Instructor))

            const participatingStudents = instructorsAndStudents
                .filter((student) => student.user.roles.some(role => role.name === RoleEnum.Student))

            this.form.controls.instructors.setValue(participatingInstructors)

            if (! this.event.id) {
                this.updateVehiclesForInstructors(participatingInstructors)
            }

            this.form.controls.students.setValue(participatingStudents)
        }
    }

    public setProduct(): void {
        if (this.form?.controls?.product) {
            this.form.controls.product.valueChanges.subscribe((selectedProduct: PlannableProductInterface) => {
                if (selectedProduct && selectedProduct.defaultDurationInMinutes) {
                    this.form.controls.productDuration.setValue(selectedProduct.defaultDurationInMinutes)
                    this.updateEndTimeByProductDuration()
                } else {
                    this.form.controls.productDuration.setValue(0)
                }
            })

            merge(
                this.form.controls.startTime.valueChanges,
                this.form.controls.productDuration.valueChanges,
            ).subscribe(() => this.updateEndTimeByProductDuration())
        }
    }

    public updateInstructor(selectedInstructorId: string) {
        if (this.event.id || ! selectedInstructorId) {
            return
        }

        if (this.instructors && this.instructors.length > 0) {
            const selectedInstructor = this.instructors.find((instructor) => instructor.id === selectedInstructorId)

            if (selectedInstructor) {
                this.form?.controls?.instructors?.setValue([selectedInstructor])

                this.initialFormValue = {
                    ...this.form.value,
                    instructors: selectedInstructor,
                }

                this.updateVehiclesForInstructors([selectedInstructor])

                this.instructorChanged.emit(selectedInstructorId)
            }
        }
    }

    public updateVehiclesForInstructors(instructors: Partial<Instructor>[] = []): void {
        if (! this.form) {
            return
        }

        try {
            this.isSettingFormValues = true
            const currentInstructors: Partial<Instructor>[] = this.form.controls.instructors.value || []
            const existingVehicles: Partial<Vehicle>[] = this.form.controls.vehicles.value || []

            if (Array.isArray((instructors)) && Array.isArray((currentInstructors))) {
                const instructorsToUpdate = instructors.filter((instructor) => {
                    return currentInstructors.some((currentInstructor) => currentInstructor.id === instructor.id)
                })

                const selectedVehicles: Partial<Vehicle>[] | undefined = instructorsToUpdate
                    ?.filter((instructor) => (instructor as Instructor)?.user?.instructor?.preferredVehicle)
                    ?.map((instructor) => (instructor as Instructor)?.user?.instructor?.preferredVehicle)

                const vehiclesToUpdate: Partial<Vehicle>[] = existingVehicles.filter((vehicle) => {
                    return selectedVehicles?.some((selectedVehicle) => selectedVehicle.id === vehicle.id)
                })

                if (selectedVehicles) {
                    selectedVehicles.forEach((selectedVehicle) => {
                        if (! vehiclesToUpdate.some((vehicle) => vehicle.id === selectedVehicle.id)) {
                            vehiclesToUpdate.push(selectedVehicle)
                        }
                    })
                }

                this.form.controls.instructors.setValue(instructorsToUpdate)
                this.form.controls.vehicles.setValue(vehiclesToUpdate)
            }
        } finally {
            this.isSettingFormValues = false
        }
    }

    public createByDate(date: Date) {
        this.event.startsAt = date.toISOString()
        this.event.endsAt = addHours(date, 1).toISOString()

        // If endsAt is after midnight, set startsAt to 22:30 and endsAt to 23:30
        if (! isSameDay(date, addHours(date, 1))) {
            this.event.startsAt = `${date.toISOString().substring(0, 10)}T22:30:00`
            this.event.endsAt = `${date.toISOString().substring(0, 10)}T23:30:00`
        }

        this.form?.controls?.date?.setValue(new Date(this.event.startsAt) ?? null)

        this.form?.controls?.startTime?.setValue(
            this.event.startsAt
                ? format(this.parseDate(this.event.startsAt), 'HH:mm')
                : null,
        )

        this.form?.controls?.endTime?.setValue(
            this.event.endsAt
                ? format(this.parseDate(this.event.endsAt), 'HH:mm')
                : null,
        )

        this.generateTimePickerOptions()
    }

    public async updateLocationValues(): Promise<void> {
        if (this.form?.controls?.students?.value) {
            const students = this.form.controls.students.value

            if (students.length === 1) {
                const student = students[0]

                this.studentWithAddresses = undefined
                this.studentWithAddresses = await this.studentsService.getStudentProfile({ id: student?.id })
                const address = this.studentWithAddresses.pickupAddress

                // This student has a 'pickup address'. Use this as the location
                if (address) {
                    this.event = {
                        ...this.event,
                        location: [
                            address.street,
                            address.number + (address.numberAddition ? `-${address.numberAddition}` : '' + ','),
                            address.postalCode,
                            address.city,
                        ].join(' '),
                    }

                    // This student does not have a 'pickup address'. Show a message and set the location to 'other'
                } else {
                    this.event = {
                        ...this.event,
                        location: null,
                    }
                    this.form.controls.locationType.setValue('other')
                }
            } else {
                this.form.controls.locationType.setValue('other')
                this.studentWithAddresses = null
            }
        }

        if (this.form?.controls?.locationType?.value === 'home') {
            this.form.controls.locationType.setValue('home')
        } else {
            this.form.controls.locationType.setValue('other')
        }

        this.updateLocationRequired()
        this.updateProductFields()
    }

    public updateLocationRequired(): void {
        if (! this.form) {
            return
        }

        const productValue = this.form.controls.product?.value
        const locationTypeValue = this.form.controls.locationType?.value

        if ((productValue && locationTypeValue === 'home') || (locationTypeValue === 'other' && ! productValue)) {
            this.form.controls.locationOther.clearValidators()
        } else {
            this.form.controls.locationOther.setValidators([Validators.required])
        }

        this.form.controls.locationType.updateValueAndValidity()
        this.form.controls.locationOther.updateValueAndValidity()
    }

    public updateTypeAndProductValues(): void {
        if (this.event?.id) {
            return
        }

        if (! this.form.controls.educationCategory?.value) {
            this.products = []
        }

        if (this.form.controls.educationCategory.value === 'other') {
            this.form.controls.product.setValidators(null)
            this.form.controls.type.setValidators([Validators.required])

            this.form.controls.locationType.setValue('other')

            this.form.controls.locationOther.setValidators(null)
            this.form.controls.locationOther.updateValueAndValidity()

            this.form.controls.product.updateValueAndValidity()
            this.form.controls.type.updateValueAndValidity()
            this.form.controls.locationType.updateValueAndValidity()
        } else {
            this.form.controls.product.setValidators([Validators.required])
            this.form.controls.type.setValidators(null)

            this.form.controls.type.updateValueAndValidity()

            if (this.form.controls.educationCategory?.value) {
                this.productsService.getProducts({
                    isPlannable: true,
                    drivingSchoolId: this.userService.getDrivingSchool()?.id,
                    educationCategoryId: this.form.controls.educationCategory.value?.id,
                })
            }
        }
    }

    public updateDateValues(sourceControl: 'startTime' | 'endTime' | null = null): void {
        const date: Date = this.form.controls.date.value ?? new Date()

        let startDate: Date = new Date(
            this.combineDateAndTime(format(date, 'yyyy-MM-dd'), this.form.controls.startTime.value),
        )
        let endDate: Date = new Date(
            this.combineDateAndTime(format(date, 'yyyy-MM-dd'), this.form.controls.endTime.value),
        )

        // Don't allow the end time to be before the start time
        if (endDate < startDate) {
            // Update end date/time from start date/time
            if (sourceControl === 'startTime') {
                endDate = addHours(startDate, 1)
                this.form.controls.endTime.setValue(format(endDate, 'HH:mm'))
                // Update start date/time from end date/time
            } else if (sourceControl === 'endTime') {
                startDate = addHours(endDate, -1)
                this.form.controls.startTime.setValue(format(startDate, 'HH:mm'))
            }
        }

        this.generateTimePickerOptions()
    }

    public updateEndTimeByProductDuration(): void {
        const startTime = this.form.controls.startTime.value
        const productDuration = this.form.controls.productDuration.value

        if (startTime && productDuration) {
            let newEndTime: Date = addMinutes(
                new Date(`${format(this.parseDate(this.event.startsAt), 'yyyy-MM-dd')}T${startTime}`),
                productDuration,
            )

            // Check if this is still the same day. If not, set end time to 23:30
            const date: Date = this.form.controls.date.value ?? new Date()
            let startDate: Date = new Date(
                this.combineDateAndTime(format(date, 'yyyy-MM-dd'), this.form.controls.startTime.value),
            )
            if (! isSameDay(startDate, newEndTime)) {
                // Set end time to 23:30 and start time to 'productDuration' minutes before the end time
                newEndTime = addMinutes(addDays(startOfDay(startDate), 1), -30)
                const newStartTime = addMinutes(newEndTime, -productDuration)
                this.form.controls.startTime.setValue(format(newStartTime, 'HH:mm'))
            }

            this.form.controls.endTime.setValue(format(newEndTime, 'HH:mm'))
        }
    }

    public combineDateAndTime(dateString: string, timeString: string): string {
        return `${format(this.parseDate(dateString), 'yyyy-MM-dd')}T${timeString}:00`
    }

    public parseDate(dateString: string): Date {
        return new Date(dateString?.split(' ')?.join('T') ?? null)
    }

    public generateTimePickerOptions(): void {
        // Start time picker options are fixed and only need to be generated once
        if (! this.startsAtTimePickerOptions || ! this.form) {
            this.startsAtTimePickerOptions = this.formHelperService.createTimePickerOptions()
        }

        const date: Date = this.form.controls.date.value ?? new Date()
        this.endsAtTimePickerOptions = this.formHelperService
            .createTimePickerOptions(
                new Date(this.combineDateAndTime(date.toISOString(), this.form.controls.startTime.value)),
            )
    }

    public timePickerTrackByFn(_: any, item: { value: string, label: string }): string {
        return item.value
    }

    public async submit() {
        this.apiError = ''

        if (this.loading) {
            return
        }

        if (! this.form.valid) {
            this.formHelperService.reportFormErrors(this.form)
            return
        }

        this.loading = true

        this.form.markAsPristine()

        const input: CreateCalendarEventInput | UpdateCalendarEventInput = reject(equals(null))({

            // id only applies when editing an event
            id: this.event.id || null,

            userIds: [this.user.id],

            // drivingSchoolId does not apply when editing an event
            drivingSchoolId: this.event.id ? null : this.userService.getDrivingSchool()?.id,

            // description: this.form.controls.description.value,

            status: this.form.controls.status.value,

            instructorIds: this.form.controls.instructors.value.map((instructor: Instructor) => instructor.id),

            // product does not apply when editing an event or when creating an event of type 'other'
            product: this.event.id || this.form.controls.educationCategory.value === 'other'
                ? null
                : {
                    id: this.form.controls.product?.value?.id,
                    type: this.form.controls.product?.value?.type,
                },

            // calendarEventTypeId does not apply when editing an event or when creating an event of type 'other'
            calendarEventTypeId: this.event.id || this.form.controls.educationCategory.value === 'other'
                ? this.form.controls.type?.value?.id // Include the value
                : null, // Or set it to null if it doesn't apply

            title: this.form.controls.type?.value?.name ?? this.form.controls.educationCategory?.value?.name,

            vehicleIds: this.form.controls.vehicles.value.map((vehicle: Vehicle) => vehicle.id),
            studentIds: this.form.controls.students?.value?.map((student: Student) => student.id) ?? [],

            startsAt: this.form.controls.startTime?.value
                ? this.combineDateAndTime(
                    format(this.form.controls.date.value, 'yyyy-MM-dd'),
                    this.form.controls.startTime.value,
                ).split('T').join(' ')
                : format(new Date(this.event.startsAt), 'yyyy-MM-dd HH:mm:ss'),

            endsAt: this.form.controls.endTime?.value
                ? this.combineDateAndTime(
                    format(this.form.controls.date.value, 'yyyy-MM-dd'),
                    this.form.controls.endTime.value,
                ).split('T').join(' ')
                : format(new Date(this.event.endsAt), 'yyyy-MM-dd HH:mm:ss'),

            location: this.form?.controls?.locationType?.value === 'other'
                ? this.form.controls.locationOther.value
                : this.event.location,

            tableNumber: this.form.controls.tableNumber?.value ?? null,
        })

        let message = this.translateService.instant(
            this.event.id ? 'modals.calendar.event.edit.submitted' : 'modals.calendar.event.create.submitted',
        )

        try {
            if (this.event.id) {
                await this.calendarEventsService
                    .updateCalendarEvent(input as UpdateCalendarEventInput)
                this.eventCreatedOrUpdated.emit()

                this.loading = false
                this.formHelperService.hideKeyboard()
                await this.dismiss()

            } else {
                const createCalendarEventResult = await this.calendarEventsService
                    .createCalendarEvent(input as CreateCalendarEventInput)

                if (this.form.controls.note.value) {
                    await this.createNote(createCalendarEventResult.createCalendarEvent.id)
                }

                this.eventCreatedOrUpdated.emit()

                this.loading = false
                this.formHelperService.hideKeyboard()
                await this.dismiss()

                this.event.location = null

                await this.initializeForm()
            }
        } catch (e) {
            this.loading = false
            this.apiError = e.message
            this.formHelperService.reportFormErrors(this.form)
            message = this.translateService.instant('modals.calendar.event.create.error')
        }

        await this.toastController.create({
            message,
            duration: 3000,
        }).then((toast) => toast.present())
    }

    private async createNote(calendarEventId: string): Promise<Partial<CalendarEventNote>> {
        const createNoteResult = await this.notesService.createCalendarEventNote({
            calendarEventId,
            visibleForStudent: false,
            text: this.form.controls.note.value,
        })

        return createNoteResult.createCalendarEventNote as Partial<CalendarEventNote>
    }

    private updateProductFields(): void {
        const product = this.event?.product || this.form.controls?.product?.value

        // Disable 'table number' field if the event is not an exam
        if (
            product?.type === ProductTypeEnum.Exam
            && (product as unknown as ExamProduct).examType === ExamTypeEnum.Practical
        ) {
            this.form.controls.tableNumber.enable()
        } else {
            this.form.controls.tableNumber.disable()
        }
    }
}
