<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.finance.invoice.detail.title' | translate }} {{ invoice.invoiceNumber }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light">

            <div class="content-wrapper">
                <div class="ion-text-center">
                    Invoice detail {{ invoice.invoiceNumber }}
                </div>

                <div class="ion-padding">
                    <p>Button below will eventually be visible for instructors only:</p>
                    <app-invoice-edit [invoice]="invoice" trigger="invoice-edit-trigger" />
                    <ion-button class="ion-color ion-color-light" id="invoice-edit-trigger" size="small">
                        Bewerken
                    </ion-button>
                </div>
            </div>

        </ion-content>

    </ng-template>
</ion-modal>
