import { Injectable } from '@angular/core'
import { firstValueFrom, map, Subject } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core'

import { ApiHelperService, CacheOptions } from '@app-services/api/api-helper.service'
import {
    CalendarEventNote,
    CalendarEventNotesInput,
    CalendarEventNotesQuery,
    CalendarEventNotesQueryService,
    CreateCalendarEventNoteInput,
    CreateCalendarEventNoteMutation,
    CreateCalendarEventNoteMutationService,
    CreateScriptNoteInput,
    CreateScriptNoteMutation,
    CreateScriptNoteMutationService,
    CreateStudentNoteInput,
    CreateStudentNoteMutation,
    CreateStudentNoteMutationService,
    DeleteCalendarEventNoteInput,
    DeleteCalendarEventNoteMutation,
    DeleteCalendarEventNoteMutationService,
    DeleteScriptNoteInput,
    DeleteScriptNoteMutation,
    DeleteScriptNoteMutationService,
    DeleteStudentNoteInput,
    DeleteStudentNoteMutation,
    DeleteStudentNoteMutationService,
    QueryCalendarEventNotesOrderByOrderByClause as CalendarEventNotesOrderBy,
    QueryScriptNotesOrderByOrderByClause as ScriptNotesOrderBy,
    QueryStudentNotesOrderByOrderByClause as StudentNotesOrderBy,
    ScriptNote,
    ScriptNotesInput,
    ScriptNotesQuery,
    ScriptNotesQueryService,
    StudentNote,
    StudentNotesInput,
    StudentNotesQuery,
    StudentNotesQueryService,
    UpdateCalendarEventNoteInput,
    UpdateCalendarEventNoteMutation,
    UpdateCalendarEventNoteMutationService,
    UpdateScriptNoteInput,
    UpdateScriptNoteMutation,
    UpdateScriptNoteMutationService,
    UpdateStudentNoteInput,
    UpdateStudentNoteMutation,
    UpdateStudentNoteMutationService,
} from '@app-graphql'

import { Note, NoteType } from '@app-components'

@Injectable({
    providedIn: 'root',
})
export class NotesService {

    public notes$ = new Subject<Note[]>()
    public note$ = new Subject<Note>()

    public scriptNotes$ = new Subject<ScriptNote[]>()
    public scriptNote$ = new Subject<ScriptNote>()

    public calendarEventNotes$ = new Subject<CalendarEventNote[]>()
    public calendarEventNote$ = new Subject<CalendarEventNote>()

    private notes: Note[]
    private scriptNotes: ScriptNote[]
    private calendarEventNotes: CalendarEventNote[]

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly createStudentNoteMutationService: CreateStudentNoteMutationService,
        private readonly createScriptNoteMutationService: CreateScriptNoteMutationService,
        private readonly createCalendarEventNoteMutationService: CreateCalendarEventNoteMutationService,
        private readonly deleteStudentNoteMutationService: DeleteStudentNoteMutationService,
        private readonly deleteScriptNoteMutationService: DeleteScriptNoteMutationService,
        private readonly deleteCalendarEventNoteMutationService: DeleteCalendarEventNoteMutationService,
        private readonly updateStudentNoteMutationService: UpdateStudentNoteMutationService,
        private readonly updateScriptNoteMutationService: UpdateScriptNoteMutationService,
        private readonly updateCalendarEventNoteMutationService: UpdateCalendarEventNoteMutationService,
        private readonly studentNotesQueryService: StudentNotesQueryService,
        private readonly scriptNotesQueryService: ScriptNotesQueryService,
        private readonly calendarEventNotesQueryService: CalendarEventNotesQueryService,
    ) {
    }

    public async getStudentNotes(
        input: StudentNotesInput,
        orderBy?: StudentNotesOrderBy[],
        cacheOptions?: CacheOptions,
    ): Promise<Partial<StudentNote>[]> | null {

        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `studentNotes.studentId_${input.studentId}`,
        )
        const notes$ = this.studentNotesQueryService.fetch({ input, orderBy }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<StudentNotesQuery>) => {
                this.notes = result.data.studentNotes as StudentNote[]
                this.notes$.next(this.notes)

                return this.notes
            }),
        )

        return firstValueFrom(notes$)
    }

    public async createStudentNote(input: CreateStudentNoteInput): Promise<CreateStudentNoteMutation> {
        try {
            const response = await firstValueFrom(
                this.createStudentNoteMutationService.mutate({ input }),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async deleteStudentNote(input: DeleteStudentNoteInput): Promise<DeleteStudentNoteMutation> {
        try {
            const response = await firstValueFrom(
                this.deleteStudentNoteMutationService.mutate({ input }),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async updateStudentNote(input: UpdateStudentNoteInput): Promise<UpdateStudentNoteMutation> {
        try {
            const response = await firstValueFrom(
                this.updateStudentNoteMutationService.mutate({ input }),
            )

            const noteIndex = this.notes.findIndex((note) => note.id === response.data.updateStudentNote.id)

            if (noteIndex !== -1) {
                this.notes = [...this.notes]
                this.notes[noteIndex] = {
                    ...this.notes[noteIndex],
                    ...response.data.updateStudentNote as StudentNote,
                }
                this.notes$.next(this.notes)
                this.note$.next(this.notes[noteIndex])
            }

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async getScriptNotes(
        input: ScriptNotesInput,
        orderBy?: ScriptNotesOrderBy[],
        cacheOptions?: CacheOptions,
    ): Promise<ScriptNote[]> | null {

        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `scriptNotes.script_${input.scriptId}`,
        )

        const scriptNotes$ = this.scriptNotesQueryService.fetch({ input, orderBy }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<ScriptNotesQuery>) => {
                this.scriptNotes = result.data.scriptNotes as ScriptNote[]
                this.scriptNotes$.next(this.scriptNotes)

                return this.scriptNotes
            }),
        )

        return firstValueFrom(scriptNotes$)
    }

    public async createScriptNote(input: CreateScriptNoteInput): Promise<CreateScriptNoteMutation> {
        try {
            const response = await firstValueFrom(
                this.createScriptNoteMutationService.mutate({ input }),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async deleteScriptNote(input: DeleteScriptNoteInput): Promise<DeleteScriptNoteMutation> {
        try {
            const response = await firstValueFrom(
                this.deleteScriptNoteMutationService.mutate({ input }),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async updateScriptNote(input: UpdateScriptNoteInput): Promise<UpdateScriptNoteMutation> {
        try {
            const response = await firstValueFrom(
                this.updateScriptNoteMutationService.mutate({ input }),
            )

            const scriptNoteIndex = this.scriptNotes.findIndex((scriptNote) =>
                scriptNote.id === response.data.updateScriptNote.id)

            if (scriptNoteIndex !== -1) {
                this.scriptNotes = [...this.scriptNotes]
                this.scriptNotes[scriptNoteIndex] = {
                    ...this.scriptNotes[scriptNoteIndex],
                    ...response.data.updateScriptNote as ScriptNote,
                }
                this.scriptNotes$.next(this.scriptNotes)
                this.scriptNote$.next(this.scriptNotes[scriptNoteIndex])
            }

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async getCalendarEventNotes(
        input: CalendarEventNotesInput,
        orderBy?: CalendarEventNotesOrderBy[],
        cacheOptions?: CacheOptions,
    ): Promise<CalendarEventNote[]> | null {

        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `scriptNotes.script_${input.calendarEventId}`,
        )

        const calendarEventNotes$ = this.calendarEventNotesQueryService.fetch({ input, orderBy }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<CalendarEventNotesQuery>) => {
                this.calendarEventNotes = result.data.calendarEventNotes as CalendarEventNote[]
                this.calendarEventNotes$.next(this.calendarEventNotes)

                return this.calendarEventNotes
            }),
        )

        return firstValueFrom(calendarEventNotes$)
    }

    public async createCalendarEventNote(
        input: CreateCalendarEventNoteInput,
    ): Promise<CreateCalendarEventNoteMutation> {
        try {
            const response = await firstValueFrom(
                this.createCalendarEventNoteMutationService.mutate({ input }),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async deleteCalendarEventNote(
        input: DeleteCalendarEventNoteInput,
    ): Promise<DeleteCalendarEventNoteMutation> {
        try {
            const response = await firstValueFrom(
                this.deleteCalendarEventNoteMutationService.mutate({ input }),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async updateCalendarEventNote(
        input: UpdateCalendarEventNoteInput,
    ): Promise<UpdateCalendarEventNoteMutation> {
        try {
            const response = await firstValueFrom(
                this.updateCalendarEventNoteMutationService.mutate({ input }),
            )

            const calendarEventNoteIndex = this.calendarEventNotes.findIndex((calendarEventNote) =>
                calendarEventNote.id === response.data.updateCalendarEventNote.id)

            if (calendarEventNoteIndex !== -1) {
                this.calendarEventNotes = [...this.calendarEventNotes]
                this.calendarEventNotes[calendarEventNoteIndex] = {
                    ...this.calendarEventNotes[calendarEventNoteIndex],
                    ...response.data.updateCalendarEventNote as CalendarEventNote,
                }
                this.calendarEventNotes$.next(this.calendarEventNotes)
                this.calendarEventNote$.next(this.calendarEventNotes[calendarEventNoteIndex])
            }

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async getNotes(
        input: StudentNotesInput | ScriptNotesInput | CalendarEventNotesInput,
        type: NoteType,
        orderBy?: StudentNotesOrderBy[] | ScriptNotesOrderBy[] | CalendarEventNotesOrderBy[],
        cacheOptions?: CacheOptions,
    ) {
        if (type === NoteType.Student) {
            return this.getStudentNotes(
                input as StudentNotesInput,
                orderBy as StudentNotesOrderBy[],
                cacheOptions,
            )
        }

        if (type === NoteType.Script) {
            return this.getScriptNotes(
                input as ScriptNotesInput,
                orderBy as ScriptNotesOrderBy[],
                cacheOptions,
            )
        }

        if (type === NoteType.CalendarEvent) {
            return this.getCalendarEventNotes(
                input as CalendarEventNotesInput,
                orderBy as CalendarEventNotesOrderBy[],
                cacheOptions,
            )
        }

        return null
    }

    public async createNote(
        input: CreateStudentNoteInput | CreateScriptNoteInput | CreateCalendarEventNoteInput, type: NoteType,
    ): Promise<CreateStudentNoteMutation | CreateScriptNoteMutation | CreateCalendarEventNoteMutation> {
        if (type === NoteType.Student) {
            return this.createStudentNote(input as CreateStudentNoteInput)
        }

        if (type === NoteType.Script) {
            return this.createScriptNote(input as CreateScriptNoteInput)
        }

        if (type === NoteType.CalendarEvent) {
            return this.createCalendarEventNote(input as CreateCalendarEventNoteInput)
        }
    }

    public async deleteNote(
        input: DeleteStudentNoteInput | DeleteScriptNoteInput | CalendarEventNotesInput, type: NoteType,
    ) {
        if (type === NoteType.Student) {
            return this.deleteStudentNote(input as DeleteStudentNoteInput)
        }

        if (type === NoteType.Script) {
            return this.deleteScriptNote(input as DeleteScriptNoteInput)
        }

        if (type === NoteType.CalendarEvent) {
            return this.deleteCalendarEventNote(input as DeleteCalendarEventNoteInput)
        }
    }

    public async updateNote(
        input: DeleteStudentNoteInput | DeleteScriptNoteInput | CalendarEventNotesInput, type: NoteType,
    ) {
        if (type === NoteType.Student) {
            return this.updateStudentNote(input as UpdateStudentNoteInput)
        }

        if (type === NoteType.Script) {
            return this.updateScriptNote(input as UpdateScriptNoteInput)
        }

        if (type === NoteType.CalendarEvent) {
            return this.updateCalendarEventNote(input as UpdateCalendarEventNoteInput)
        }
    }
}
