import { Component, EventEmitter, Output } from '@angular/core'
import { AlertController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'

import { BaseModal } from '@app-components/modals/base.modal'
import { RoadmapService } from '@app-services'

@Component({
    selector: 'app-redeem-roadmap-paywall-code-modal',
    templateUrl: './redeem-roadmap-paywall-code.modal.html',
    styleUrls: ['./redeem-roadmap-paywall-code.modal.scss'],
})
export class RedeemRoadmapPaywallCodeModal extends BaseModal {

    @Output()
    public codeRedeemed = new EventEmitter<void>()

    public code: string

    constructor(
        private readonly alertController: AlertController,
        private readonly roadmapService: RoadmapService,
        private readonly translateService: TranslateService,
    ) {
        super()
    }

    public async submit(): Promise<void> {
        try {
            const response = await this.roadmapService.redeemRoadmapPaywallCode({ code: this.code })

            if (response?.redeemRoadmapPaywallCode) {
                this.codeRedeemed.emit()
                await this.dismiss()
            }
        } catch (e) {
            await this.alertController.create({
                message: e.message,
                buttons: [this.translateService.instant('common.ok')],
            }).then((alert) => alert.present())
        }
    }

}
