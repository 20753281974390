import { NgModule } from '@angular/core'
import { AndroidNotch } from '@awesome-cordova-plugins/android-notch/ngx'
import { Camera } from '@awesome-cordova-plugins/camera/ngx'
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx'
import { Device } from '@awesome-cordova-plugins/device/ngx'
import { DocumentViewer } from '@awesome-cordova-plugins/document-viewer/ngx'
import { File } from '@awesome-cordova-plugins/file/ngx'
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx'
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links/ngx'
import { FirebaseMessaging } from '@awesome-cordova-plugins/firebase-messaging/ngx'
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx'
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx'
import { Network } from '@awesome-cordova-plugins/network/ngx'
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx'
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx'
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx'
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx'
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx'

@NgModule({
    providers: [
        AndroidNotch,
        Camera,
        Chooser,
        Device,
        DocumentViewer,
        File,
        FileOpener,
        FirebaseDynamicLinks,
        FirebaseMessaging,
        Geolocation,
        Keyboard,
        Network,
        OpenNativeSettings,
        SafariViewController,
        SplashScreen,
        StatusBar,
        WebView,
    ],
})
export class CordovaModule {
}
