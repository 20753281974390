import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-invoice-edit',
    templateUrl: './invoice-edit.modal.html',
    styleUrls: ['./invoice-edit.modal.scss'],
})
export class InvoiceEditModal extends BaseModal {

    @Input()
    public invoice: any // @TODO: Set type

}
