import { Injectable } from '@angular/core'
import { ApolloError, FetchPolicy } from '@apollo/client/core'
import { Network } from '@awesome-cordova-plugins/network/ngx'
import { Platform, ToastController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { Apollo } from 'apollo-angular'
import { lastValueFrom } from 'rxjs'

import { AppConfig } from '@app-config'
import { OrderByClause } from '@app-graphql'
import { StorageService } from '@app-services/storage'

export interface CacheOptions {
    clearCache?: boolean;
    cacheTtl?: number;
}

export enum CacheTtl {
    None = 0,
    Minute = 60,
    Hour = 3600,
    Day = 86400,
    Week = 604800,
    Month = 2592000,
    Year = 31536000,
}

export interface PaginationOptions {
    search?: string;
    page?: number;
    first: number;
    orderBy?: any | OrderByClause | OrderByClause[];
}

@Injectable({
    providedIn: 'root',
})
export class ApiHelperService {

    protected constructor(
        private readonly apollo: Apollo,
        private readonly network: Network,
        private readonly storageService: StorageService,
        private readonly toastController: ToastController,
        private readonly translateService: TranslateService,
        private readonly platform: Platform,
    ) {
        this.platform.ready().then(async () => {
            await this.clearExpiredCacheTimestamps()
        })
    }

    public getErrorMessageFromApolloError(error: ApolloError): string {
        if (! error.graphQLErrors?.[0]) {
            return error.message
        }

        const graphQLError = error.graphQLErrors[0]

        // Try to get first field in extensions
        let firstError: string
        if (graphQLError.extensions?.validation) {
            firstError = Object.values(graphQLError.extensions.validation)?.[0]?.[0]
        }

        return firstError ?? graphQLError.extensions?.reason ?? graphQLError.message ?? error.message
    }

    public async showHttpError(
        messageOrTranslationKey: string = 'api.errorMessage',
        suppressWhenOffline = true,
        duration = 2500,
    ): Promise<void> {
        let translationKey = messageOrTranslationKey
        const isOffline = (window as any).cordova
            ? this.network.type === this.network.Connection.NONE
            : ! navigator.onLine

        if (isOffline) {
            if (suppressWhenOffline) {
                return
            }
            translationKey = 'api.offlineMessage'
        }

        const message = await lastValueFrom(this.translateService.get(translationKey))
        this.toastController.create({
            color: 'dark',
            message,
            duration,
        }).then((toast) => toast.present())
    }

    public async getFetchPolicy(cacheOptions?: CacheOptions, cacheKey?: string): Promise<FetchPolicy> {
        const ttl = cacheOptions?.cacheTtl ?? AppConfig.cache.ttl
        const isOffline = (window as any).cordova
            ? this.network.type === this.network.Connection.NONE
            : ! navigator.onLine

        // If offline, always use cache
        if (isOffline) {
            return 'cache-only'
        }

        // In case of a force-refresh, send a new request and write new timestamp to storage
        if (cacheOptions?.clearCache) {
            await this.storageService.set(`timestamp.${cacheKey}`, `${Date.now() + ttl * 1000}`)
            return 'network-only'
        }

        // In case cache has expired, send a new request and write new timestamp to storage
        const cachedTimestamp = await this.storageService.get(`timestamp.${cacheKey}`)

        if (! cachedTimestamp || +cachedTimestamp < Date.now()) {
            await this.storageService.set(`timestamp.${cacheKey}`, `${Date.now() + ttl * 1000}`)
            return 'network-only'
        }

        return 'cache-first'
    }

    public async clearExpiredCacheTimestamps(): Promise<void> {
        const cacheTimestamps = await this.storageService.getGroup('timestamp')
        const itemsToRemove = cacheTimestamps.filter((cachedTimestamp) => +cachedTimestamp < Date.now())
        await Promise.all(itemsToRemove.map((cachedTimestamp) => this.storageService.remove(cachedTimestamp)))
    }

    public async invalidateCache(groupKey?: string): Promise<void> {
        // Clear all cache timestamps and Apollo cache
        await Promise.all([
            this.storageService.removeGroup(groupKey ? `timestamp.${groupKey}` : 'timestamp'),
            this.apollo.client.clearStore(),
        ])
    }

}
