<ion-card *ngIf="script" class="ion-margin-top">
    <div class="has-chip">

        <app-competency-level *ngIf="latestScriptResult" [scriptRating]="latestScriptResult.rating" />

        <ion-card>
            <ion-card-content>
                <div class="ion-flex ion-justify-content-between">

                    <h3 class="ion-padding-vertical-2">{{ script.title }}</h3>

                    <ion-segment (ionChange)="scriptRatingChanged.emit(scriptRating)"
                                 [(ngModel)]="scriptRating"
                                 [swipeGesture]="false"
                                 mode="ios"
                    >
                        <ng-container *ngIf="script.practiceMilestone?.ratingType === ScriptRatingTypeEnum.Default">
                            <ion-segment-button *ngFor="let rating of [
                                                    ScriptRatingEnum.Homework,
                                                    ScriptRatingEnum.Demo,
                                                    ScriptRatingEnum.ExplainedByStudent,
                                                    ScriptRatingEnum.ActOnDirections,
                                                    ScriptRatingEnum.ActOnLimitedDirections,
                                                    ScriptRatingEnum.ActWithoutDirections,
                                                    ScriptRatingEnum.ActOnChangedSituations,
                                                    ScriptRatingEnum.ActIndependently,
                                                ], let i = index"
                                                [value]="rating"
                            >
                                <ion-label>{{ i + 1 }}</ion-label>
                            </ion-segment-button>
                        </ng-container>

                        <ng-container *ngIf="script.practiceMilestone?.ratingType === ScriptRatingTypeEnum.Stv">
                            <ion-segment-button [value]="ScriptRatingEnum.StvEducation">
                                <ion-label>S</ion-label>
                            </ion-segment-button>
                            <ion-segment-button [value]="ScriptRatingEnum.StvTraining">
                                <ion-label>T</ion-label>
                            </ion-segment-button>
                            <ion-segment-button [value]="ScriptRatingEnum.StvFormation">
                                <ion-label>V</ion-label>
                            </ion-segment-button>
                        </ng-container>
                    </ion-segment>
                </div>

                <ng-container *ngIf="showNoteButton">
                    <hr class="ion-color ion-color-medium" />

                    <ion-button (click)="addNoteButtonClicked.emit()"
                                class="ion-color ion-color-light"
                                size="small"
                    >
                        Notitie toevoegen
                    </ion-button>
                </ng-container>

            </ion-card-content>
        </ion-card>
    </div>
</ion-card>
