import { Pipe, PipeTransform } from '@angular/core'

import { CalendarEvent, ProductTypeEnum } from '@app-graphql'

@Pipe({
    name: 'calendarEventIcon',
})
export class CalendarEventIconPipe implements PipeTransform {

    public transform(event: Partial<CalendarEvent>): string {
        const eventType = event?.product?.type

        switch (eventType) {
            case ProductTypeEnum.Course:
                return 'book'
            case ProductTypeEnum.DrivingLesson:
                return 'car'
            case ProductTypeEnum.Exam:
                return 'clipboard'
            default:
                return 'calendar'
        }
    }

}
