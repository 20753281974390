<ion-popover #popover [side]="side">

    <ng-template>
        <ng-content />
        <ion-header>
            <ion-toolbar class="ion-padding-2">
                <ion-searchbar (ionInput)="search()"
                               [debounce]="searchbarDebounce"
                               [placeholder]="searchbarPlaceholder"
                />
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>
                <ion-item (click)="! multiple && setValue(option.value); ! multiple && popover.dismiss()"
                          *ngFor="let option of options; let i = index"
                          [class.selected]="! multiple && innerValueForComparison === (compareWith ? option.value[compareWith] : option.value)"
                          [hidden]="searchResults !== null && ! searchResults.includes(option.value)"
                >
                    <ion-icon [hidden]="
                        multiple
                        || innerValueForComparison !== (compareWith ? option.value[compareWith] : option.value)
                    " name="checkmark" slot="end"
                    />
                    <ion-checkbox *ngIf="multiple"
                                  [checked]="innerValueForComparison?.includes((compareWith ? option.value[compareWith] : option.value))"
                                  slot="start"
                    />
                    <ion-label>{{ $any(optionElements.get(i)).nativeElement.innerText }}</ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
        <ion-footer>
            <ion-toolbar class="ion-padding-2">
                <ion-button (click)="setValue(null); popover.dismiss()"
                            *ngIf="showClearButton"
                            class="ion-color ion-color-secondary ion-margin-bottom-2"
                            expand="block"
                            size="small"
                >
                    {{ 'common.clear' | translate }}
                </ion-button>
                <ion-button (click)="popover.dismiss()"
                            *ngIf="showCancelButton"
                            class="ion-color ion-color-secondary ion-margin-bottom-2"
                            expand="block"
                            size="small"
                >
                    {{ 'common.cancel' | translate }}
                </ion-button>
                <ion-button (click)="setMultipleValue(); popover.dismiss()"
                            *ngIf="multiple"
                            class="ion-color ion-color-primary"
                            expand="block"
                            size="small"
                >
                    {{ 'common.apply' | translate }}
                </ion-button>
            </ion-toolbar>
        </ion-footer>
    </ng-template>

</ion-popover>

<ion-input (click)="popover.present($event)"
           [placeholder]="placeholder"
           [readonly]="true"
           [value]="labelValue"
           type="text"
/>
