import { Injectable } from '@angular/core'
import { SwUpdate } from '@angular/service-worker'
import { ApolloQueryResult } from '@apollo/client/core'
import { AlertController, Platform, ToastController } from '@ionic/angular'
import { firstValueFrom, map, Subject } from 'rxjs'

import {
    PlatformEnum,
    UpdateCheckInput,
    UpdateCheckQuery,
    UpdateCheckQueryService,
    UpdateCheckResponse,
} from '@app-graphql'
import { ApiHelperService, CacheOptions, CacheTtl, ExternalLinkService } from '@app/services'
import PACKAGE from '@project-root/package.json'

@Injectable({
    providedIn: 'root',
})
export class UpdateService {

    public updateAvailable$ = new Subject<UpdateCheckResponse>()

    private updateAvailable: UpdateCheckResponse

    constructor(
        private readonly alertController: AlertController,
        private readonly apiHelperService: ApiHelperService,
        private readonly externalLinkService: ExternalLinkService,
        private readonly platform: Platform,
        private readonly swUpdate: SwUpdate,
        private readonly toastController: ToastController,
        private readonly updateCheckQueryService: UpdateCheckQueryService,
    ) {
        this.platform.ready().then(async () => this.checkForUpdate())
    }

    public async checkForUpdate(): Promise<void> {
        if (this.platform.is('hybrid')) {
            await this.checkForNativeAppUpdate()
        } else {
            await this.checkForSwUpdate()
        }
    }

    private async checkForNativeAppUpdate(): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return null
        }

        const updateCheckResponse = await this.getUpdateCheckResponse({ cacheTtl: CacheTtl.Hour })
        if (! updateCheckResponse?.available) {
            return
        }

        const alert = await this.alertController.create({
            header: 'Update',
            message: updateCheckResponse.promptMessage,
            backdropDismiss: false,
            keyboardClose: false,
            buttons: [
                {
                    text: updateCheckResponse.promptConfirmButtonLabel || 'Download update...',
                },
            ],
        })

        await alert.present()
        await alert.onDidDismiss()

        // Always open update page
        const appDownloadUrl = this.platform.is('android')
            ? 'https://play.google.com/store/apps/details?id=io.metmax.app'
            : 'https://apps.apple.com/nl/app/metmax/id6446321919'
        await this.externalLinkService.open(appDownloadUrl)

        // Loop this function until the user updates the app
        await this.checkForNativeAppUpdate()
    }

    private async checkForSwUpdate(): Promise<void> {
        if (this.platform.is('hybrid') || ! this.swUpdate.isEnabled) {
            return
        }

        const hasUpdate = await this.swUpdate.checkForUpdate()
        if (hasUpdate) {
            await this.toastController.create({
                color: 'dark',
                message: 'Er is een nieuwe versie beschikbaar',
                position: 'top',
                buttons: [
                    {
                        text: 'Nu updaten',
                        handler: () => this.swUpdate.activateUpdate().then(() => window.location.reload()),
                    },
                ],
            }).then((toast) => toast.present())
        }
    }

    private async getUpdateCheckResponse(cacheOptions?: CacheOptions): Promise<UpdateCheckResponse | null> {
        if (! this.platform.is('hybrid')) {
            return null
        }

        const input: UpdateCheckInput = {
            currentVersion: PACKAGE.version,
            platform: this.platform.is('ios') ? PlatformEnum.Ios : PlatformEnum.Android,
        }

        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            'app.update',
        )
        const updateAvailable$ = this.updateCheckQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<UpdateCheckQuery>) => {
                this.updateAvailable = result.data.updateCheck
                this.updateAvailable$.next(this.updateAvailable)

                return this.updateAvailable
            }),
        )

        return firstValueFrom(updateAvailable$)
    }

}
