<ng-template #emptyTemplate />

<!-- Week day templates -->
<ng-template #weekviewHeaderTemplate let-viewDate="viewDate">
    <div [class.selected]="viewDate.selected"
         [class.weekend]="viewDate.date | dfnsIsWeekend"
         class="weekday"
    >
        <strong>{{ viewDate.date | localizedDate : 'EEEEEE' | firstCase }}</strong>
        <h3>{{ viewDate.date | localizedDate : 'd' }}</h3>
        <div [hidden]="! viewDate.hasEvent" class="weekday-event-indicator"></div>
    </div>
</ng-template>

<!-- Week view -->
<div *ngIf="showWeek && mode === 'day'">
    <calendar #week
              (onCurrentDateChanged)="setDate(week.currentDate)"
              [currentDate]="value"
              [eventSource]="events | toCalendarComponentEvents"
              [startingDayWeek]="'locale.format.date.firstDayOfWeek' | translate"
              [weekviewAllDayEventSectionTemplate]="emptyTemplate"
              [weekviewHeaderTemplate]="weekviewHeaderTemplate"
              [weekviewInactiveAllDayEventSectionTemplate]="emptyTemplate"
              [weekviewInactiveNormalEventSectionTemplate]="emptyTemplate"
              [weekviewNormalEventTemplate]="emptyTemplate"
              calendarMode="week"
              class="calendar-view-weekview"
    >
    </calendar>
</div>

<!-- Day view template -->
<ng-template #normalEventSectionTemplate let-hourParts="hourParts" let-tm="tm">

    <!-- Hour indicator in day view (only visible on 'today') -->
    <div *ngIf="(
        mode === 'day'
        && (tm.time | date : 'yyyy-MM-dd HH') === ('now' | dateFromString | date : 'yyyy-MM-dd HH')
        && (tm.time | date : 'mm') !== '30'
    )"
         [style.top]="'calc(' + (+('now' | dateFromString | date : 'mm') / 60) + ' * var(--interval-height) * var(--interval-height-multiplier))'"
         class="calendar-indicator"
    >
        <div>{{ 'now' | dateFromString | date : 'HH:mm' }}</div>
    </div>

    <!-- Main calendar event -->
    <div *ngIf="tm.events"
         [ngClass]="{ 'calendar-event-wrap': tm.events }"
    >
        <app-calendar-event (eventSelected)="eventClicked.emit($event)"
                            *ngFor="let displayEvent of tm.events"
                            [allowMultilineText]="(displayEvent.endIndex - displayEvent.startIndex) > hourParts"
                            [event]="displayEvent.event?.event"
                            [ngStyle]="{
                                    top: 'calc(var(--interval-height) * ' + (displayEvent.startOffset / hourParts) + ' * var(--interval-height-multiplier))',
                                    left: 100 / displayEvent.overlapNumber * displayEvent.position + '%',
                                    width: 100 / displayEvent.overlapNumber + '%',
                                    height: 'calc(var(--interval-height) * ' + (
                                       displayEvent.endIndex
                                       - displayEvent.startIndex
                                       - (displayEvent.endOffset + displayEvent.startOffset) / hourParts
                                    ) + ' * var(--interval-height-multiplier))',
                                }"
        />
    </div>
</ng-template>

<!-- Main view (day, week or month, depending on 'mode') -->
<ion-card class="content-card ion-padding">

    <div *ngIf="loading" class="calendar-loader">
        <ion-spinner class="ion-color ion-color-light ion-margin-end" />
        <span>Bezig met laden...</span>
    </div>

    <calendar #calendar
              (onCurrentDateChanged)="setWeekFromDayView(calendar.currentDate)"
              (onTimeSelected)="waitForClickAtDate($event.selectedTime)"
              [autoSelect]="false"
              [calendarMode]="mode"
              [currentDate]="value"
              [dayviewInactiveNormalEventSectionTemplate]="normalEventSectionTemplate"
              [dayviewNormalEventSectionTemplate]="normalEventSectionTemplate"
              [eventSource]="events | toCalendarComponentEvents"
              [locale]="'locale.code' | translate"
              [preserveScrollPosition]="true"
              [startingDayWeek]="'locale.format.date.firstDayOfWeek' | translate"
              [step]="10"
              [timeInterval]="30"
              [weekviewAllDayEventSectionTemplate]="emptyTemplate"
              [weekviewHeaderTemplate]="weekviewHeaderTemplate"
              [weekviewInactiveAllDayEventSectionTemplate]="emptyTemplate"
              [weekviewInactiveNormalEventSectionTemplate]="emptyTemplate"
              [weekviewNormalEventSectionTemplate]="normalEventSectionTemplate"
              [weekviewNormalEventTemplate]="emptyTemplate"
              class="calendar-view-main"
              formatHourColumn="HH:mm"
    />
</ion-card>
