<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>
                    Score opgeven
                </ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light">
            <ion-list *ngIf="scriptResultsByMilestone" lines="none">
                <ion-accordion-group>
                    <ion-accordion *ngFor="let item of scriptResultsByMilestone"
                                   [value]="item.milestone.id"
                    >
                        <ion-item slot="header">
                            <div class="ion-flex ion-flex-row w-100">
                                <img [src]="item.milestone.icon" alt="" slot="start" width="56" />
                                <div class="w-100 ion-margin-start">
                                    <h3>{{ item.milestone.title }}</h3>
                                </div>
                            </div>
                        </ion-item>
                        <div slot="content">
                            <ng-container *ngFor="let script of $any(item).milestone.scripts">
                                <div class="has-chip ion-margin">
                                    <app-script-rating (addNoteButtonClicked)="noteAddModal.present()"
                                                       (scriptRatingChanged)="scriptResultsToCreate[script.id] = $event"
                                                       (scriptRatingCleared)="scriptResultsToCreate[script.id] = null"
                                                       [latestScriptResult]="$any(item).scriptResults[script.id]"
                                                       [script]="$any(script)"
                                    />
                                    <app-note-add #noteAddModal
                                                  (noteAdded)="noteAdded()"
                                                  [noteType]="NoteType.Script"
                                                  [roadmapProgressId]="roadmapProgress.id"
                                                  [scriptId]="script.id"
                                    />
                                </div>
                            </ng-container>
                        </div>
                    </ion-accordion>
                </ion-accordion-group>

            </ion-list>
        </ion-content>

        <ion-footer>
            <ion-toolbar class="ion-color ion-color-light">
                <ion-button (click)="createScriptResults()"
                            class="ion-color ion-color-tertiary ion-margin"
                            expand="block"
                >
                    <span [hidden]="submittingScriptResult">Score opslaan</span>
                    <ion-spinner [hidden]="! submittingScriptResult" slot="icon-only" />
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ng-template>
</ion-modal>
