import { Component, Input } from '@angular/core'

import { RoadmapProgress, Script } from '@app-graphql'

@Component({
    selector: 'app-roadmap-homework-swiper',
    templateUrl: './roadmap-homework-swiper.component.html',
    styleUrls: ['./roadmap-homework-swiper.component.scss'],
})
export class RoadmapHomeworkSwiperComponent {

    @Input()
    public roadmapProgress: RoadmapProgress

    @Input()
    public homeworkScripts: Script[]

}
