<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.finance.lesson.edit.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light">

            <div class="content-wrapper">
                <div class="ion-text-center">
                    <ng-container *ngIf="lesson; else create">
                        Lesson edit
                    </ng-container>
                    <ng-template #create>
                        Lesson create
                    </ng-template>
                </div>
            </div>

        </ion-content>

    </ng-template>
</ion-modal>
