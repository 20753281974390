import { Injectable } from '@angular/core'
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx'

@Injectable({
    providedIn: 'root',
})
export class KeyboardHelperService {

    private rootElement: HTMLElement

    constructor(
        private readonly keyboard: Keyboard,
    ) {
    }

    public initialize(): void {
        this.rootElement = document.querySelector<HTMLElement>(':root')

        this.keyboard.onKeyboardWillShow().subscribe((e) => {
            this.rootElement.style.setProperty('--keyboard-height', `${e.keyboardHeight}px`)
            document.documentElement.classList.add('keyboard-visible')
        })

        this.keyboard.onKeyboardWillHide().subscribe(() => {
            this.rootElement.style.setProperty('--keyboard-height', '0px')
            document.documentElement.classList.remove('keyboard-visible')
        })

        // Scroll focused form elements into view
        this.keyboard.onKeyboardDidShow().subscribe(() => {
            if (
                document.activeElement instanceof HTMLInputElement
                || document.activeElement instanceof HTMLTextAreaElement
            ) {
                document.activeElement.scrollIntoView({
                    block: 'center',
                })
            }
        })
    }

}
