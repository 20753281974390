import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { firstValueFrom, map, Subject } from 'rxjs'

import { Product, ProductsInput, ProductsQuery, ProductsQueryService } from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services'

@Injectable({
    providedIn: 'root',
})
export class ProductsService {

    public products$ = new Subject<Product[]>()

    private products: Product[]

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly productsQueryService: ProductsQueryService,
    ) {
        //
    }

    public async getProducts(input?: ProductsInput, cacheOptions?: CacheOptions): Promise<Product[]> {

        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `products.products_${input.isPlannable}_${input.educationCategoryId}_${input.drivingSchoolId}`,
        )

        const products$ = this.productsQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<ProductsQuery>) => {
                this.products = result.data.products as Product[]
                this.products$.next(this.products)

                return this.products
            }),
        )

        return firstValueFrom(products$)
    }

}
