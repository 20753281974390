<ng-template #scriptsGroupedByMilestoneLoading>
    <div class="ion-flex ion-padding">
        <ion-skeleton-text [animated]="true" style="height: 78px;" />
        <ion-skeleton-text [animated]="true" style="height: 78px;" />
        <ion-skeleton-text [animated]="true" style="height: 78px;" />
        <ion-skeleton-text [animated]="true" style="height: 78px;" />
    </div>
</ng-template>

<ion-accordion-group *ngIf="scriptsGroupedByMilestone; else scriptsGroupedByMilestoneLoading">
    <ng-container *ngFor="let milestone of roadmapProgress?.roadmap?.milestones">
        <ion-accordion *ngIf="milestone.type === MilestoneTypeEnum.Practice"
                       [value]="milestone.id"
        >
            <ng-container *ngFor="let milestoneProgress of milestoneProgresses">
                <ng-container *ngIf="milestoneProgress.milestoneId === milestone.id">
                    <ion-item slot="header">
                        <div class="ion-flex ion-flex-row w-100">
                            <img [src]="milestone.icon" alt="" slot="start"
                                 width="56"
                            >
                            <div class="w-100 ion-margin-start">
                                <h3>{{ milestone.title }}</h3>
                                <app-progress-bar-with-percentage
                                    *ngIf="$any(milestone).ratingType === ScriptRatingTypeEnum.Default"
                                    [value]="milestoneProgress.progress / 100"
                                />
                            </div>
                        </div>
                    </ion-item>
                    <div slot="content">
                        <div class="ion-padding">
                            <ng-container *ngFor="let script of scriptsGroupedByMilestone[milestone.id]?.scripts">
                                <div *ngFor="let scriptProgress of scriptsGroupedByMilestone[milestone.id]?.scriptProgressesByScriptId[script.id]"
                                     [class.has-chip]="scriptProgress.progress"
                                     class="ion-margin-vertical"
                                >
                                    <app-competency-level *ngIf="scriptProgress.progress"
                                                          [level]="scriptProgress.progress"
                                    />
                                    <ion-item *ngIf="studentId"
                                              [detail]="scriptProgress.progress < 100"
                                              [routerLink]="(
                                                calendarEventId
                                                    ? ('/tabs/instructor-calendar/event/' + calendarEventId + '/' + studentId + '/script/' + script.id)
                                                    : ('/tabs/students/student/' + studentId + '/script/' + script.id)
                                          )"
                                              class="ion-margin-bottom"
                                              lines="full"
                                              shape="round"
                                    >
                                        <ion-label>
                                            <h4 class="ion-text-wrap">{{ script.title }}</h4>
                                        </ion-label>
                                        <ion-icon *ngIf="scriptProgress.passed"
                                                  slot="end"
                                                  src="/assets/icons/ui/check.svg"
                                        />
                                    </ion-item>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </ion-accordion>
    </ng-container>
</ion-accordion-group>
