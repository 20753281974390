<div (click)="selectFile($event)"
     class="upload-image {{ shape }}"
>
    <ng-container *ngIf="innerValue?.publicPath ?? src ?? defaultValue">
        <!--suppress AngularNgOptimizedImage -->
        <img *ngIf="innerValue?.publicPath ?? src ?? defaultValue"
             [src]="$any(innerValue?.publicPath ?? src ?? defaultValue)"
             alt=""
        >
    </ng-container>
</div>

<ion-popover #popover size="auto">
    <ng-template>
        <ion-content>
            <ion-list>

                <ion-item (click)="takePicture()"
                          *ngIf="sources.includes('camera')"
                          [button]="true"
                          [detail]="false"
                >
                    {{ 'filePicker.camera' | translate }}
                    <ion-icon name="camera-outline" slot="end"></ion-icon>
                </ion-item>

                <ion-item (click)="pickImage()"
                          *ngIf="sources.includes('image')"
                          [button]="true"
                          [detail]="false"
                >
                    {{ 'filePicker.photoAlbum' | translate }}
                    <ion-icon name="image-outline" slot="end"></ion-icon>
                </ion-item>

                <ion-item (click)="pickFile()"
                          *ngIf="sources.includes('file')"
                          [button]="true"
                          [detail]="false"
                >
                    {{ 'filePicker.file' | translate }}
                    <ion-icon name="folder-outline" slot="end"></ion-icon>
                </ion-item>

            </ion-list>
        </ion-content>
    </ng-template>
</ion-popover>
