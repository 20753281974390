import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IonModal } from '@ionic/angular'
import { Subject, Subscription } from 'rxjs'

import { CacheOptions, CacheTtl, NotesService } from '@app-services'

import {
    CalendarEventNotesInput,
    QueryCalendarEventNotesOrderByColumn,
    QueryCalendarEventNotesOrderByOrderByClause as CalendarEventNotesOrderBy,
    QueryScriptNotesOrderByColumn,
    QueryScriptNotesOrderByOrderByClause as ScriptNotesOrderBy,
    QueryStudentNotesOrderByColumn,
    QueryStudentNotesOrderByOrderByClause as StudentNotesOrderBy,
    ScriptNote,
    ScriptNotesInput,
    SortOrder,
    StudentNote,
    StudentNotesInput,
} from '@app-graphql'

export type Note = Partial<StudentNote | ScriptNote>;

export enum NoteType {
    Student = 'student',
    Script = 'script',
    CalendarEvent = 'calendar-event',
}

@Component({
    selector: 'app-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    public noteType: NoteType

    @Input()
    public studentId: string

    @Input()
    public scriptId: string

    @Input()
    public roadmapProgressId: string

    @Input()
    public calendarEventId: string

    @ViewChild('noteAddModal', { static: true })
    public noteAddModal: IonModal

    public orderBy: StudentNotesOrderBy[] | ScriptNotesOrderBy[] | CalendarEventNotesOrderBy[] = []

    public apiError: string
    public section: string

    public notes: Note[]
    public selectedNote: Note

    public subscriptions$: Subscription[] = []

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly notesService: NotesService,
    ) {
    }

    public ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.section = params.section ?? 'lesson-plan'

            if (params.eventId) {
                this.calendarEventId = params.eventId
            }

            if (params.studentId) {
                this.studentId = params.studentId
            }
        })

        this.subscribeToNotes()
        this.subscribeToSelectedNote()
    }

    public ngOnDestroy(): void {
        this.subscriptions$.forEach(subscription => subscription.unsubscribe())
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.noteType || changes.studentId || changes.scriptId) {
            await this.loadNotes()
        }
    }

    public async openAddNoteModal(): Promise<void> {
        await this.noteAddModal?.present()
    }

    public subscribeToNotes(): void {
        let notes$: Subject<Note[]>

        if (this.noteType === NoteType.Student) {
            notes$ = this.notesService.notes$
        } else if (this.noteType === NoteType.Script) {
            notes$ = this.notesService.scriptNotes$
        } else if (this.noteType === NoteType.CalendarEvent) {
            notes$ = this.notesService.calendarEventNotes$
        }

        const subscription = notes$.subscribe(notes => {
            this.notes = notes
        })
        this.subscriptions$.push(subscription)
    }

    public subscribeToSelectedNote(): void {
        let note$: Subject<Note>

        if (this.noteType === NoteType.Student) {
            note$ = this.notesService.note$
        } else if (this.noteType === NoteType.Script) {
            note$ = this.notesService.scriptNote$
        } else if (this.noteType === NoteType.CalendarEvent) {
            note$ = this.notesService.calendarEventNote$
        }

        const subscription = note$.subscribe(note => {
            this.selectedNote = note
        })
        this.subscriptions$.push(subscription)
    }

    public async loadNotes(cacheOptions: CacheOptions = { cacheTtl: CacheTtl.Minute }): Promise<void> {
        let input: StudentNotesInput | ScriptNotesInput | CalendarEventNotesInput

        if (this.noteType === NoteType.Student) {
            input = { studentId: this.studentId } as StudentNotesInput
            this.orderBy = [
                {
                    order: SortOrder.Asc,
                    column: QueryStudentNotesOrderByColumn.CreatedAt,
                },
            ]
        } else if (this.noteType === NoteType.Script) {
            input = { scriptId: this.scriptId, roadmapProgressId: this.roadmapProgressId } as ScriptNotesInput
            this.orderBy = [
                {
                    order: SortOrder.Asc,
                    column: QueryScriptNotesOrderByColumn.CreatedAt,
                },
            ]
        } else if (this.noteType === NoteType.CalendarEvent) {
            input = { calendarEventId: this.calendarEventId } as CalendarEventNotesInput
            this.orderBy = [
                {
                    order: SortOrder.Asc,
                    column: QueryCalendarEventNotesOrderByColumn.CreatedAt,
                },
            ]
        }

        await this.notesService.getNotes(
            input,
            this.noteType,
            this.orderBy,
            cacheOptions,
        )
    }
}
