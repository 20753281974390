<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.notes.edit.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding-horizontal">
            <div class="content-wrapper">
                <ion-card class="content-card">
                    <ion-card-content>
                        <form (submit)="update()"
                              [formGroup]="form"
                              action=""
                        >
                            <ion-item lines="none">
                                <ion-label class="ion-margin-bottom" position="stacked">
                                    <h3>Jouw notitie</h3>
                                </ion-label>
                                <ion-textarea #message
                                              [autoGrow]="true"
                                              [value]="note.text"
                                              formControlName="text"
                                              placeholder="Voeg een notitie toe"
                                              rows="5"
                                >
                                </ion-textarea>
                            </ion-item>

                            <ng-container *ngIf="noteType === NoteType.Script || NoteType.CalendarEvent">
                                <ion-item class="ion-margin-top"
                                          fill="clear"
                                          lines="none"
                                >
                                    <ion-checkbox formControlName="visibleForStudent" slot="start"></ion-checkbox>
                                    <ion-label>Zichtbaar voor leerling?</ion-label>
                                </ion-item>
                            </ng-container>

                            <ion-card [hidden]="! apiError" class="ion-margin-vertical">
                                <ion-card-content class="error-message">
                                    {{ apiError | translate }}
                                </ion-card-content>
                            </ion-card>

                            <input class="semi-hidden" tabindex="-1" type="submit" value="">
                            <ion-button class="ion-color ion-color-tertiary ion-margin-top"
                                        enterkeyhint="go"
                                        expand="block"
                                        type="submit"
                            >
                                <ion-spinner [hidden]="! loading" slot="icon-only" />
                                <ng-container *ngIf="note">
                                    <span [hidden]="loading">Notitie opslaan</span>
                                </ng-container>
                                <ng-container *ngIf="! note">
                                    <span [hidden]="loading">Notitie toevoegen</span>
                                </ng-container>
                            </ion-button>
                        </form>
                    </ion-card-content>
                </ion-card>
            </div>

        </ion-content>

    </ng-template>
</ion-modal>
