<div [attr.data-status]="event.status"
     [class.allow-multiline-text]="allowMultilineText"
     class="calendar-event"
>
    <ion-item (click)="$event.preventDefault(); $event.stopPropagation(); eventSelected.emit(event)"
              [button]="true"
              [detail]="false"
              class="ion-flex ion-align-items-start"
              lines="full"
              shape="round"
    >
        <span [style.background-color]="$any(event?.product)?.color || ''" class="indicator" slot="start"></span>
        <ion-label>
            <ion-note *ngIf="event.status === 'CANCELLED'" class="ion-margin-bottom ion-color ion-color-danger">
                Geannuleerd
            </ion-note>

            <ion-note *ngIf="event.status === 'RESERVATION'" class="ion-margin-bottom ion-color ion-color-secondary">
                Reservering
            </ion-note>

            <h4>
                {{ (event?.product?.title || '') + (event?.product?.title ? ' - ' : '') + (event.title || '') }}
            </h4>

            <ng-container *ngIf="students?.length">
                <p>{{ students.length === 1 ? 'Leerling' : 'Leerlingen' }}</p>
                <div *ngFor="let student of students"
                     class="ion-flex ion-flex-row ion-align-self-center"
                >
                    <h3 class="subtitle">
                        {{ student?.user?.firstName + ' ' + student?.user?.lastName }}
                    </h3>
                </div>
            </ng-container>

            <p class="ion-margin-vertical-2">
                {{ event.startsAt | date : 'HH:mm' }}
                -
                {{ event.endsAt | date : 'HH:mm' }}
            </p>

            <ng-container *ngIf="instructors?.length">
                <p>{{ instructors.length === 1 ? 'Instructeur' : 'Instructeurs' }}</p>
                <div *ngFor="let instructor of instructors">
                    <h3 class="subtitle">
                        {{ instructor.user?.firstName + ' ' + instructor.user?.lastName }}
                    </h3>
                </div>
            </ng-container>

            <div class="subtitle ion-margin-top-2">{{ event.location }}</div>
        </ion-label>

        <ion-icon [name]="event | calendarEventIcon"
                  class="ion-color ion-color-medium"
                  slot="end"
        />

    </ion-item>
</div>
