import { Pipe, PipeTransform } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { FormatPipe } from 'ngx-date-fns'
import { DateFnsInputDate } from 'ngx-date-fns/lib/types'

import { AppConfig } from '@app-config'

@Pipe({
    name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {

    constructor(
        private readonly datePipe: FormatPipe,
        private readonly translateService: TranslateService,
    ) {
    }

    public transform(
        dateFnsDate: DateFnsInputDate | null | undefined,
        dateFnsDateFormat: string | 'short' | 'medium' | 'long' = 'short',
        dateFnsOptions?: object,
    ): string {
        const locale: Locale = AppConfig.locales
            .find((l) => l.code === this.translateService.instant('locale.code')) ?? AppConfig.locales[0]

        let format = dateFnsDateFormat
        if (format === 'short') {
            format = this.translateService.instant('locale.format.date.short')
        } else if (format === 'medium') {
            format = this.translateService.instant('locale.format.date.medium')
        } else if (format === 'long') {
            format = this.translateService.instant('locale.format.date.long')
        }

        return this.datePipe.transform(
            dateFnsDate,
            format,
            {
                locale,
                weekStartsOn: this.translateService.instant('locale.format.date.firstDayOfWeek'),
                ...dateFnsOptions,
            },
        )
    }

}
