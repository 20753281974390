import { Injectable } from '@angular/core'
import { icon, MapOptions, Marker, marker, tileLayer } from 'leaflet'
import { SearchResult } from 'leaflet-geosearch/lib/providers/provider'
//eslint-disable-next-line import/extensions
import OpenStreetMapProvider, { RawResult } from 'leaflet-geosearch/lib/providers/openStreetMapProvider.js'
import { CacheService } from 'ionic-cache'

import { CacheOptions, CacheTtl } from '@app-services/api'

@Injectable()
export class MapService {

    private provider = new OpenStreetMapProvider()

    constructor(
        private readonly cacheService: CacheService,
    ) {
    }

    public async search(query: string, cacheOptions?: CacheOptions): Promise<SearchResult<RawResult>[]> {
        const cacheKey = btoa(query)

        const hasCachedResult = ! cacheOptions?.clearCache && await this.cacheService.itemExists(cacheKey)
        if (hasCachedResult) {
            // Attempt to get cached item. It may be expired, in which case we'll just ignore it.
            try {
                return await this.cacheService.getItem(cacheKey)
            } catch (error) {
                //
            }
        }

        const results = await this.provider.search({ query })
        await this.cacheService.saveItem(cacheKey, results, null, cacheOptions?.cacheTtl ?? CacheTtl.Day)

        return results
    }

    public getStaticMapOptions(): MapOptions {
        return {
            // Make the map static/fixed
            dragging: false,
            minZoom: 15,
            maxZoom: 15,

            // Tiles + marker(s)
            layers: [
                tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; OpenStreetMap',
                }),
            ],
            zoomControl: false,
            zoom: 15,
        }
    }

    public getMapMarker(coordinates: [number, number]): Marker {
        return marker(
            coordinates,
            {
                icon: icon({
                    iconSize: [32, 47],
                    iconAnchor: [16, 47],
                    iconUrl: '/assets/icons/ui/map-marker.svg',
                }),
            },
        )
    }

}
