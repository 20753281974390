import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'
import { PracticeMilestone } from '@app-graphql'

@Component({
    selector: 'app-roadmap-introduction',
    templateUrl: './roadmap-introduction.modal.html',
    styleUrls: ['./roadmap-introduction.modal.scss'],
})
export class RoadmapIntroductionModal extends BaseModal {

    @Input()
    public milestone: PracticeMilestone

}
