import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'
import { Script, ScriptSection } from '@app-graphql'

interface ScriptSectionGroup {
    header?: string;
    scriptSections: ScriptSection[];
}

@Component({
    selector: 'app-script-sections',
    templateUrl: './script-sections.modal.html',
    styleUrls: ['./script-sections.modal.scss'],
})
export class ScriptSectionsModal extends BaseModal implements OnChanges {

    @Input()
    public script: Script

    public scriptSectionsGroupedByHeader: ScriptSectionGroup[] = []

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.script) {
            this.scriptSectionsGroupedByHeader = this.getScriptSectionsGroupedByHeader()
        }
    }

    private getScriptSectionsGroupedByHeader(): ScriptSectionGroup[] {
        if (! this.script?.scriptSections?.length) {
            return []
        }

        const groups: ScriptSectionGroup[] = []

        this.script?.scriptSections?.forEach((section: ScriptSection) => {
            if (! groups.length || section.header) {
                groups.push({ header: section.header, scriptSections: [section] })
            } else {
                groups[groups.length - 1].scriptSections.push(section)
            }
        })

        return groups
    }

}
