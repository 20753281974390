import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import { ToastController } from '@ionic/angular'
import { Subscription } from 'rxjs'

import { BaseModal } from '@app-components/modals/base.modal'
import {
    Milestone,
    MilestoneTypeEnum,
    RoadmapProgress,
    ScriptRatingEnum,
    ScriptResult,
} from '@app/graphql'
import { HomeworkService, RoadmapService } from '@app/services'
import { NoteType } from '@app/components'

@Component({
    selector: 'app-lesson-script-rating-modal',
    templateUrl: './lesson-script-rating.modal.html',
    styleUrls: ['./lesson-script-rating.modal.scss'],
})
export class LessonScriptRatingModal extends BaseModal implements OnDestroy, OnChanges {

    @Input()
    public roadmapProgress: Partial<RoadmapProgress>

    @Input()
    public calendarEventId: string

    @Input()
    public studentId: string

    @Output()
    public scriptRatingChanged = new EventEmitter<void>()

    public scriptResultsByMilestone: {
        milestone: Milestone,
        scriptResults: { [key: string]: ScriptResult[] },
    }[] = null

    public scriptResultsToCreate: { [key: string]: ScriptRatingEnum } = {}
    public submittingScriptResult = false

    public readonly NoteType = NoteType

    private scriptResults$: Subscription

    constructor(
        private readonly homeworkService: HomeworkService,
        private readonly roadmapService: RoadmapService,
        private readonly toastController: ToastController,
    ) {
        super()

        this.scriptResults$ = this.roadmapService.scriptResults$.subscribe((scriptResults) => {
            this.scriptResultsByMilestone = []

            const milestones = this.roadmapProgress?.roadmap?.milestones
                ?.filter((milestone) => milestone.type === MilestoneTypeEnum.Practice)

            milestones?.forEach((milestone) => {
                const item = {
                    milestone,
                    scriptResults: {},
                }

                scriptResults
                    .filter((scriptResult) => scriptResult.script?.practiceMilestone?.id === milestone.id)
                    .forEach((scriptResult) => {
                        item.scriptResults[scriptResult.script?.id] = scriptResult
                    })

                this.scriptResultsByMilestone.push(item)
            })
        })

        this.modal?.ionModalDidPresent?.subscribe(() => this.scriptResultsToCreate = {})
    }

    public ngOnDestroy(): void {
        this.scriptResults$?.unsubscribe()
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.calendarEventId || changes.roadmapProgress) {
            this.scriptResultsToCreate = {}
            await this.createPracticeMilestonesWithScriptResultsArray()
        }
    }

    private async createPracticeMilestonesWithScriptResultsArray(): Promise<void> {
        if (! this.calendarEventId || ! this.roadmapProgress) {
            return
        }

        await this.roadmapService.getScriptResults({
            roadmapProgressId: this.roadmapProgress?.id,
        })
    }

    public async createScriptResults(): Promise<void> {

        // Remove null values from this.scriptResultsToCreate
        Object.keys(this.scriptResultsToCreate).forEach((key) => {
            if (this.scriptResultsToCreate[key] === null) {
                delete this.scriptResultsToCreate[key]
            }
        })

        if (this.submittingScriptResult || ! Object.keys(this.scriptResultsToCreate).length) {
            await this.toastController.create({
                message: 'Er zijn geen nieuwe scores ingevoerd',
                duration: 3000,
            }).then((toast) => toast.present())
            return
        }

        this.submittingScriptResult = true

        let message = 'Scores toegevoegd'

        const scriptResultPromises = Object.entries(this.scriptResultsToCreate).map(([scriptId, rating]) => {
            return this.homeworkService.createScriptResult({
                rating,
                scriptId,
                roadmapProgressId: this.roadmapProgress.id,
                calendarEventId: this.calendarEventId,
            })
        })

        try {
            await Promise.all(scriptResultPromises)
        } catch (e) {
            message = 'Fout bij toevoegen score'
        }

        this.submittingScriptResult = false
        this.scriptResultsToCreate = {}

        await this.toastController.create({
            message,
            duration: 3000,
        }).then((toast) => toast.present())

        this.scriptRatingChanged.emit()

        await this.dismiss()
    }

    public async noteAdded(): Promise<any> {
        await this.toastController.create({
            message: 'Notitie toegevoegd',
            duration: 3000,
        }).then((toast) => toast.present())
    }
}
