<ng-template #instructorsLoading>
    <div class="ion-flex">
        <ion-skeleton-text [animated]="true" style="height: 60px;" />
        <ion-skeleton-text [animated]="true" style="height: 60px;" />
        <ion-skeleton-text [animated]="true" style="height: 60px;" />
    </div>
</ng-template>

<ng-container *ngIf="instructors; else instructorsLoading">
    <ng-template #instructorsEmpty>
        <ng-content select="[slot='empty']" />
    </ng-template>

    <ion-list *ngIf="instructors.length; else instructorsEmpty"
              class="ion-margin-bottom-5"
              lines="full"
              shape="round"
    >
        <ion-item *ngFor="let instructor of instructors"
                  [detail]="true"
                  [routerLink]="'/tabs/administration/organization/instructor/' + instructor.id"
        >
            <ion-label class="ion-text-wrap">
                {{ instructor.user?.firstName }}
                {{ instructor.user?.lastName }}
            </ion-label>
        </ion-item>
    </ion-list>

</ng-container>
