import { AfterViewChecked, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { CalendarComponent } from 'ionic6-calendar'

import { CalendarEvent } from '@app-graphql'

@Component({
    selector: 'app-calendar-view',
    templateUrl: './calendar-view.component.html',
    styleUrls: ['./calendar-view.component.scss'],
})
export class CalendarViewComponent implements AfterViewChecked {

    @Input()
    public mode: 'day' | 'week' | 'month' = 'day'

    @Input()
    public showWeek = true

    @Input()
    public loading = false

    @Input()
    public events: Partial<CalendarEvent>[] = []

    @Input()
    public value: Date = new Date()

    @Output()
    public dateChanged = new EventEmitter<Date>()

    @Output()
    public eventClicked = new EventEmitter<Partial<CalendarEvent>>()

    @Output()
    public emptyTimeSlotClicked = new EventEmitter<Date>()

    @Output()
    public monthViewDayClicked = new EventEmitter<Date>()

    @Output()
    public afterInitialize = new EventEmitter<void>()

    @ViewChild('calendar')
    public calendar: CalendarComponent

    @ViewChild('week')
    public week: CalendarComponent

    private isChangingDate = false
    private waitingForClickAtDate: Date
    private clickTimer: NodeJS.Timeout | number = 0

    private isInitialized = false
    private scrollElement: HTMLDivElement

    public ngAfterViewChecked(): void {
        if (this.isInitialized) {
            return
        }

        this.scrollElement = document
            .querySelector<HTMLDivElement>('.content-card .swiper-slide-active init-position-scroll .scroll-content')
        if (this.scrollElement) {
            this.isInitialized = true
            this.afterInitialize.emit()
        }
    }

    public setDate(date: Date): void {
        if (! this.isChangingDate && this.calendar) {
            this.isChangingDate = true
            this.calendar.currentDate = date
            this.value = this.calendar.currentDate
            this.isChangingDate = false
        }
    }

    public setWeekFromDayView(date: Date): void {
        if (! this.isChangingDate && this.week) {
            this.isChangingDate = true
            this.week.currentDate = date
            this.value = this.week.currentDate
            this.isChangingDate = false
        }
        this.dateChanged.emit(date)
    }

    public previous(): void {
        this.calendar?.slidePrev()
    }

    public next(): void {
        this.calendar?.slideNext()
    }

    public scrollToHour(hour: number): void {
        if (this.scrollElement) {
            const intervalHeight = parseInt(
                getComputedStyle(this.scrollElement).getPropertyValue('--interval-height'),
            )
            const intervalHeightMultiplier = +getComputedStyle(this.scrollElement)
                .getPropertyValue('--interval-height-multiplier')

            this.scrollElement.scrollTop = hour * intervalHeight * intervalHeightMultiplier
        }
    }

    public waitForClickAtDate(date: Date): void {
        this.waitingForClickAtDate = date

        if (this.mode === 'month') {
            this.monthViewDayClicked.emit(date)
            return
        }

        this.emptyTimeSlotClicked.emit(this.waitingForClickAtDate)
    }
}
