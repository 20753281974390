<ion-list *ngIf="roadmapProgress" class="roadmap-dots" lines="none">
    <ion-item (click)="itemClick(milestone)"
              *ngFor="let milestone of roadmapProgress.roadmap.milestones"
              [button]="['PRACTICE', 'THEORY'].includes(milestone.type)"
              [detail]="['PRACTICE', 'THEORY'].includes(milestone.type)"
    >
        <ng-container *ngFor="let milestoneProgress of milestoneProgresses">
            <ng-container *ngIf="milestoneProgress.milestoneId === milestone.id">

                <ion-thumbnail slot="start">
                    <img [class.not-started]="milestoneProgress.progress === 0"
                         [src]="milestone.icon"
                         alt="{{ milestone.title }}"
                         width="48"
                    >
                </ion-thumbnail>

                <ion-label>
                    <h3 class="ion-text-wrap">{{ milestone.title }}</h3>
                    <div class="subtitle ion-text-wrap">{{ milestone.subTitle }}</div>
                    <app-progress-bar-with-percentage
                        *ngIf="
                            milestone.type === MilestoneTypeEnum.Practice
                            && $any(milestone)?.ratingType === ScriptRatingTypeEnum.Default
                        "
                        [value]="milestoneProgress.progress / 100"
                    />
                </ion-label>

                <ion-icon *ngIf="milestoneProgress.progress === 100" slot="end" src="/assets/icons/ui/check.svg" />

            </ng-container>
        </ng-container>
    </ion-item>
</ion-list>
