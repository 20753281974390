import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-exam-edit',
    templateUrl: './exam-edit.modal.html',
    styleUrls: ['./exam-edit.modal.scss'],
})
export class ExamEditModal extends BaseModal {

    @Input()
    public exam: any // @TODO: Set type

}
