import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-lesson-plan',
    templateUrl: './lesson-plan.modal.html',
    styleUrls: ['./lesson-plan.modal.scss'],
})
export class LessonPlanModal extends BaseModal {

    @Input()
    public lesson: any // @TODO: Set type

}
