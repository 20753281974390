<div #list>
    <ng-container *ngFor="let day of daysInRange">
        <ng-container *ngIf="showEmptyDays || day.events?.length">
            <div [class.today]="day.isToday || null" class="ion-flex ion-align-items-center">
                <ion-chip class="ion-color ion-color-{{ day.isToday ? 'primary' : 'dark' }} ion-margin-bottom">
                    {{ day.date | localizedDate : 'long' }}
                </ion-chip>
            </div>

            <div *ngIf="day.events?.length else noEventsForDay">
                <app-calendar-event (eventSelected)="eventClicked.emit($event)"
                                    *ngFor="let event of day.events"
                                    [event]="event"
                                    class="ion-margin-bottom"
                />
                <!--                                    (click)="eventClicked.emit(event)"-->
            </div>
            <ng-template #noEventsForDay>
                <p class="no-events">Geen items</p>
            </ng-template>
        </ng-container>
    </ng-container>

    <div [hidden]="eventCountInRange > 0" class="no-events">
        <div #noEvents>
            <ng-content select="[slot='empty']" />
        </div>
        <p *ngIf="! noEvents?.childElementCount">Geen items in deze periode</p>
    </div>
</div>
