import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import { IonPopover, Platform } from '@ionic/angular'
import { v4 as uuidv4 } from 'uuid'

import { FileAccept, FileStorageService, LocalFile } from '@app-services'

const noop = () => {
}

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true,
}

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class FileUploadComponent implements ControlValueAccessor {

    @Input()
    public src?: string

    @Input()
    public sources?: ('image' | 'file' | 'camera')[] = ['image', 'file', 'camera']

    @Input()
    public accept?: FileAccept | FileAccept[] | null = FileAccept.Images

    @Input()
    public shape?: 'round' | 'circle' | null = null

    @Input()
    public defaultValue?: string

    @Output()
    public fileChanged = new EventEmitter<LocalFile>()

    @ViewChild('popover')
    public popover: IonPopover

    public innerValue: LocalFile = null

    public uuid = uuidv4()

    private onTouchedCallback: () => void = noop
    private onChangeCallback: (_: any) => void = noop

    constructor(
        private readonly fileStorageService: FileStorageService,
        private readonly platform: Platform,
    ) {
        //
    }

    public get value(): LocalFile | null {
        return this.innerValue
    }

    @Input()
    public set value(value: LocalFile | null) {
        if (value === undefined) {
            return
        }
        if (value !== this.innerValue) {
            this.innerValue = value
            this.onChangeCallback(value)
            this.fileChanged.emit(value)
        }
    }

    public writeValue(value: LocalFile): void {
        if (value !== this.innerValue) {
            this.innerValue = value
        }
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn
    }

    public async removeFile(): Promise<void> {
        this.value = null
        this.src = null
        this.innerValue = null
    }

    public async selectFile(e?: MouseEvent): Promise<void> {
        if (this.platform.is('mobileweb') && this.platform.is('ios')) {
            this.value = await this.fileStorageService.showFileBrowseDialog(this.accept)
        } else {
            await this.popover.present(e)
        }
    }

    public async pickFile(): Promise<void> {
        this.value = await this.fileStorageService.pickFile(this.accept)
        await this.popover?.dismiss()
    }

    public async pickImage(): Promise<void> {
        this.value = await this.fileStorageService.pickImage()
        await this.popover?.dismiss()
    }

    public async takePicture(): Promise<void> {
        this.value = await this.fileStorageService.takePictureWithCamera(false, 'user')
        await this.popover?.dismiss()
    }

}
