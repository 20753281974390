import { Component, Input } from '@angular/core'

import { Vehicle } from '@app-graphql'

@Component({
    selector: 'app-vehicle-list',
    templateUrl: './vehicle-list.component.html',
    styleUrls: ['./vehicle-list.component.scss'],
})
export class VehicleListComponent {

    @Input()
    public vehicles: Partial<Vehicle>[]

    @Input()
    public preferredVehicleId: string

}
