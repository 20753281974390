<div *ngIf="roadmapProgress"
     [style.background-image]="'url(\'/assets/images/roadmap-goals/' + (roadmapProgress.goal | lowercase) + '.svg\')'"
     class="goal"
>
    <div class="goal-content">
        <ion-chip class="ion-color ion-color-primary pointer-events-none">
            Einddoel
        </ion-chip>
        <h2>{{ ('goals.' + roadmapProgress.goal) | translate }}</h2>
        <p class="ion-no-margin">Het is gelukt! Je hebt je rijbewijs gehaald</p>
    </div>
</div>
