<ion-note *ngIf="event.status === 'CANCELLED'"
          class="ion-margin-vertical ion-color ion-color-danger ion-font-bold"
>
    Geannuleerd
</ion-note>

<ion-note *ngIf="event.status === 'RESERVATION'"
          class="ion-margin-vertical ion-color ion-color-secondary ion-font-bold"
>
    Reservering
</ion-note>

<h4 class="ion-margin-vertical-3">
    {{ (event?.product?.title || '') + (event?.product?.title ? ' - ' : '') + (event?.title || '') }}
</h4>

<div class="ion-flex ion-flex-row ion-justify-content-between ion-align-items-center">
    <div>
        <h3>Datum en tijd</h3>
        <p class="subtitle ion-margin-bottom-3">
            {{ event.startsAt | date : ('locale.format.date.medium' | translate) : undefined : ('locale.code' | translate) }}
            {{ event.startsAt | date : 'HH:mm' }}-{{ event.endsAt | date : 'HH:mm' }}
        </p>
    </div>
    <ng-container *ngIf="view === 'instructor'">
        <app-calendar-event-edit (eventCreatedOrUpdated)="eventCreatedOrUpdated.emit($event)"
                                 [event]="event"
                                 trigger="instructor-calendar-event-edit-trigger"
        />
        <ion-button class="ion-color ion-color-light"
                    id="instructor-calendar-event-edit-trigger"
                    size="small"
        >
            Wijzigen
        </ion-button>
    </ng-container>
</div>

<ng-container *ngIf="participatingInstructors?.length">
    <div class="ion-margin-bottom-2">
        <h3 *ngIf="participatingInstructors?.length > 1">Instructeurs</h3>
        <div *ngFor="let participant of participatingInstructors"
        >
            <h3 *ngIf="participatingInstructors?.length === 1">Instructeur</h3>
            <p class="subtitle">
                {{ participant?.user.firstName + ' ' + participant?.user?.lastName }}
            </p>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="event.vehicles?.length">
    <h3 *ngIf="event.vehicles.length > 1">Voertuigen</h3>
    <div *ngFor="let vehicle of event.vehicles"
         class="ion-flex ion-flex-row ion-justify-content-between ion-align-items-center"
    >
        <div>
            <h3 *ngIf="event.vehicles.length === 1">Voertuig</h3>
            <p class="subtitle ion-margin-bottom-3">
                {{ vehicle.brand }}
                {{ vehicle.model }}
                <ng-container *ngIf="vehicle.name">({{ vehicle.name }})</ng-container>
            </p>
        </div>
        <app-license-plate *ngIf="vehicle.licensePlate" [code]="vehicle.licensePlate" />
    </div>
</ng-container>

<ng-container *ngIf="participatingStudents?.length">
    <div class="ion-margin-bottom-3">
        <h3 *ngIf="participatingStudents?.length > 1">Leerlingen</h3>
        <div *ngFor="let participant of participatingStudents"
        >
            <h3 *ngIf="participatingStudents?.length === 1">Leerling</h3>
            <p class="subtitle">
                {{ participant?.user.firstName + ' ' + participant?.user?.lastName }}
            </p>
        </div>
    </div>
</ng-container>

<h3>Locatie</h3>
<p class="subtitle ion-margin-bottom-3">
    {{ event.location }}
</p>
<div [appExternalLink]="'https://maps.apple.com/maps?q=' + (event.location | encodeUri)"
     appExternalLinkTarget="_system"
>
    <div (leafletMapReady)="refreshMap()"
         *ngIf="mapOptions?.center"
         [leafletOptions]="mapOptions"
         leaflet
    ></div>
</div>

<ng-container *ngIf="event?.tableNumber">
    <h3 class="ion-margin-top-3">Tafelnummer</h3>
    <p class="subtitle ion-margin-bottom-3">
        {{ event.tableNumber }}
    </p>
</ng-container>

<!--<ion-button *ngIf="participatingStudents.length === 1"-->
<!--            expand="block"-->
<!--            class="ion-margin-top-3"-->
<!--            size="small"-->
<!--            [routerLink]="-->
<!--                view === 'instructor'-->
<!--                    ? ('/tabs/students/student/' + participatingStudents[0].user.student.id)-->
<!--                    : '/tabs/roadmap/index'-->
<!--            "-->
<!--&gt;-->
<!--    Vorderingskaart openen-->
<!--</ion-button>-->
