import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { Color } from '@ionic/core'
import { SwiperComponent } from 'swiper/angular'

import { LinkTarget } from '@app-services'

export interface ButtonSwiperButton {
    label: string;
    value?: string;
    id?: string;
    routerLink?: string;
    externalLink?: string;
    externalLinkTarget?: LinkTarget;
    hidden?: boolean;
}

@Component({
    selector: 'app-button-swiper',
    templateUrl: './button-swiper.component.html',
    styleUrls: ['./button-swiper.component.scss'],
})
export class ButtonSwiperComponent implements OnChanges {

    @Input()
    public color: Color = 'secondary'

    @Input()
    public buttons: ButtonSwiperButton[]

    @Input()
    public value: string

    @Input()
    public updateLocationHref: boolean = false

    @Output()
    public valueChanged = new EventEmitter<ButtonSwiperButton>()

    @ViewChild('swiper')
    public swiper: SwiperComponent

    private activeButton: ButtonSwiperButton

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            this.setValue(this.value)
        }
    }

    public setValue(value: string): void {
        this.activeButton = this.buttons.find(b => b.value === value)

        const previousValue = this.value
        this.value = this.activeButton.value
        this.valueChanged.emit(this.activeButton)

        if (this.updateLocationHref) {
            let pageRoute = window.location.pathname
            if (pageRoute.endsWith(`/${previousValue}`)) {
                pageRoute = pageRoute.substring(0, pageRoute.lastIndexOf(previousValue) - 1)
            }
            history.replaceState({}, '', `${pageRoute}/${this.value}`)
        }

        this.scrollActiveButtonIntoViewIfNeeded()
    }

    public swiperInitializeHandler(): void {
        setTimeout(() => this.scrollActiveButtonIntoViewIfNeeded(), 500)
    }

    private scrollActiveButtonIntoViewIfNeeded(): void {
        const activeButtonElement = document
            .querySelector<HTMLIonButtonElement>(`[data-value='${this.activeButton.value}']`)

        if (! activeButtonElement) {
            return
        }

        const activeButtonIndex = this.buttons.findIndex((b) => b.value === this.activeButton.value)

        const rect = activeButtonElement.getBoundingClientRect()
        if (rect.left < 0 || rect.right > (window.innerWidth || document.documentElement.clientWidth)) {
            this.swiper.swiperRef.slideTo(activeButtonIndex)
        }
    }
}
