<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.notes.detail.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding-horizontal">

            <ng-container *ngIf="note">
                <div class="content-wrapper">
                    <ion-card class="content-card">
                        <ion-card-content>
                            <div class="subtitle">
                                Door {{ note?.author?.firstName }} {{ note?.author?.lastName }}
                            </div>
                            <div [innerHTML]="note.text" class="keep-white-space"></div>

                            <app-note-edit #noteEditModal
                                           (noteUpdated)="noteUpdated.emit($event)"
                                           [noteType]="noteType"
                                           [note]="note"
                            />

                            <ng-container *ngIf="note?.author | userIsMe | async">
                                <ion-button (click)="noteEditModal.present()"
                                            class="ion-color ion-color-light ion-margin-top"
                                            size="small"
                                >
                                    Bewerken
                                </ion-button>
                                <ion-button (click)="delete()"
                                            class="ion-color ion-color-danger ion-margin-top ion-float-right"
                                            size="small"
                                >
                                    Verwijderen
                                </ion-button>
                            </ng-container>
                        </ion-card-content>
                    </ion-card>
                </div>
            </ng-container>

        </ion-content>

    </ng-template>
</ion-modal>
