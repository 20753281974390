import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Color } from '@ionic/core'

import { ScriptRatingCompetencyLevel } from '@app-enums'
import { ScriptRatingEnum } from '@app-graphql'

export enum CompetencyComponentInterface {
    Badge = 'badge',
    Chip = 'chip',
}

@Component({
    selector: 'app-competency-level',
    templateUrl: './competency-level.component.html',
    styleUrls: ['./competency-level.component.scss'],
})
export class CompetencyLevelComponent implements OnChanges {

    @Input()
    public level?: ScriptRatingCompetencyLevel

    @Input()
    public scriptRating?: ScriptRatingEnum

    @Input()
    public interface?: CompetencyComponentInterface | string = CompetencyComponentInterface.Chip

    @Input()
    public size?: 'small' | 'medium' | 'large' | 'xlarge' = 'medium'

    public color: Color
    public shieldColor: Color
    public badgeColor: Color

    public readonly ScriptRatingEnum = ScriptRatingEnum

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.level || changes.scriptRating) {
            this.render()
        }
    }

    public render(): void {
        if (this.scriptRating) {
            this.level = ScriptRatingCompetencyLevel[this.scriptRating]
        }

        if (this.level === ScriptRatingCompetencyLevel.HOMEWORK) {
            this.color = 'dark'
            this.shieldColor = 'medium'
            this.badgeColor = 'dark'
        } else if (this.level === ScriptRatingCompetencyLevel.ACT_INDEPENDENTLY) {
            this.color = 'primary'
            this.shieldColor = 'tertiary'
            this.badgeColor = 'tertiary'
        } else {
            this.color = 'tertiary'
            this.shieldColor = 'primary'
            this.badgeColor = 'primary'
        }
    }

}
