import { Injectable } from '@angular/core'

import { Student, User } from '@app-graphql'

export type FilterSearchInputItem = string | Partial<User> | Partial<Student>;

@Injectable({
    providedIn: 'root',
})
export class FilterHelperService {

    public filterBySearchTerms(input: FilterSearchInputItem[], terms: string): FilterSearchInputItem[] {
        if (! terms?.trim()) {
            return input
        }

        let termsArray = this.normalizeString(terms).split(' ')
        if (termsArray.length > 1) {
            termsArray = [terms, ...termsArray]
        }

        return input.filter((inputItem: FilterSearchInputItem) => {
            const normalizedInputStr = this.normalizeString(this.filterSearchInputItemToString(inputItem))
            return termsArray.some((term) => {
                const reg = new RegExp(term, 'i')
                return reg.test(normalizedInputStr)
            })
        })
    }

    public filterSearchInputItemToString(inputItem: FilterSearchInputItem): string {
        if (inputItem.constructor === String) {
            return inputItem as string
        }

        if ((inputItem as User).firstName) {
            return this.getSearchIndexPropertiesForUser(inputItem as User).join(' ')
        }

        if ((inputItem as Student).user) {
            return this.getSearchIndexPropertiesForStudent(inputItem as Student).join(' ')
        }

        return inputItem.toString()
    }

    public getSearchIndexPropertiesForUser(user: User): string[] {
        return [
            user.firstName,
            user.lastName,
        ]
    }

    public getSearchIndexPropertiesForStudent(student: Student): string[] {
        return [
            student.user.firstName,
            student.user.lastName,
            student.postalAddress?.postalCode,
            student.postalAddress?.street,
            student.postalAddress?.city,
        ]
    }

    public normalizeString(input: string): string {
        return (input || '')
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .split('.')
            .join('')
    }

}
