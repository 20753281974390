import { Injectable } from '@angular/core'
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx'
import { TranslateService } from '@ngx-translate/core'

import { InAppBrowserOptions } from '@app-interfaces'

export type LinkTarget = '_system' | '_blank';

@Injectable()
export class ExternalLinkService {

    protected viewControllerAvailable: boolean

    constructor(
        private readonly viewController: SafariViewController,
        private readonly translateService: TranslateService,
    ) {
    }

    public async open(url: string, target: LinkTarget = '_system', hideUi = false): Promise<void> {

        const hasSpecialUrlScheme = ! (/^https?:/i).test(url) && (/^([a-z0-9]+:)/i).test(url)

        if (target === '_system' || hasSpecialUrlScheme || ! (window as any).cordova) {
            this.openUsingSystem(url)
            return
        }

        if (hideUi) {
            this.openUsingInAppBrowser(url, true)
        } else {
            await this.openUsingViewController(url)
        }
    }

    private async openUsingViewController(url: string): Promise<void> {
        if (this.viewControllerAvailable === undefined) {
            this.viewControllerAvailable = await this.viewController.isAvailable()
        }
        if (this.viewControllerAvailable) {
            const tintColor = window.getComputedStyle(document.documentElement)
                .getPropertyValue('--ion-color-primary').trim() || '#333333'
            const toolbarColor = tintColor

            this.viewController.show({
                url,
                tintColor,
                toolbarColor,
            }).subscribe()
        } else {
            this.openUsingInAppBrowser(url)
        }
    }

    private openUsingInAppBrowser(url: string, hideUi = false): void {
        if ((window as any).cordova) {
            const features = this.getInAppBrowserUiFeaturesString(hideUi);
            (window as any).cordova.InAppBrowser.open(url, '_blank', features)
        } else {
            this.openUsingSystem(url)
        }
    }

    private openUsingSystem(url: string): void {
        if ((window as any).cordova) {
            (window as any).cordova.InAppBrowser.open(url, '_system')
        } else {
            location.href = url
        }
    }

    private getInAppBrowserUiFeaturesString(hideUi): string {
        const features: InAppBrowserOptions = {
            allowInlineMediaPlayback: 'yes',
            closebuttoncaption: this.translateService.instant('common.close'),
            mediaPlaybackRequiresUserAction: 'no',
            usewkwebview: 'yes',
            transitionstyle: 'fliphorizontal',
        }

        if (hideUi) {
            features.location = 'no'
            features.zoom = 'no'
            features.toolbar = 'no'
        }

        return Object.keys(features).map((key: string) => `${key}=${features[key]}`).join(',')
    }

}
