import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core'
import { latLng, MapOptions } from 'leaflet'
import { LeafletDirective } from '@asymmetrik/ngx-leaflet'

import { CalendarEvent, Instructor, RoleEnum, Student } from '@app-graphql'
import { MapService } from '@app-services'

@Component({
    selector: 'app-calendar-event-details',
    templateUrl: './calendar-event-details.component.html',
    styleUrls: ['./calendar-event-details.component.scss'],
})
export class CalendarEventDetailsComponent implements OnChanges {

    @Input()
    public event: Partial<CalendarEvent>

    @Input()
    public view: 'student' | 'instructor'

    @Output()
    public eventCreatedOrUpdated = new EventEmitter<void>()

    @ViewChild(LeafletDirective)
    public map: LeafletDirective

    public participatingStudents: Partial<Student>[] = []
    public participatingInstructors: Partial<Instructor>[] = []

    public mapOptions: MapOptions

    public studentId: string

    constructor(
        private readonly mapService: MapService,
    ) {
        this.mapOptions = this.mapService.getStaticMapOptions()
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.event) {
            this.setParticipants()
            await this.getEventLocation()
            setTimeout(() => this.refreshMap(), 500)
        }
    }

    public refreshMap(): void {
        setTimeout(() => this.map?.getMap().invalidateSize(), 1)
    }

    private setParticipants(): void {
        const instructorsAndStudents: Partial<Instructor | Student>[] = this.event?.calendarEventParticipants
            .filter((participant) => !! (participant.actor as Instructor | Student).user)
            .map((participant) => participant.actor)

        this.participatingInstructors = (instructorsAndStudents || [])
            .filter((instructor) => instructor?.user?.roles?.some(role => role.name === RoleEnum.Instructor))

        this.participatingStudents = (instructorsAndStudents || [])
            .filter((student) => student?.user?.roles?.some(role => role.name === RoleEnum.Student))

        if (this.participatingStudents.length === 1) {
            this.studentId = this.participatingStudents[0].id
        }
    }

    private async getEventLocation(): Promise<void> {
        if (this.event?.location) {
            const results = await this.mapService.search(this.event.location)
            if (results?.length) {
                this.showLocationOnMap([results[0]?.y, results[0]?.x])
            }
        }
    }

    private showLocationOnMap(coordinates: [number, number]): void {
        this.mapOptions.center = latLng(coordinates)
        this.mapOptions.layers.push(this.mapService.getMapMarker(coordinates))
        this.refreshMap()
    }
}
