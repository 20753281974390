import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@app-pages/tabs/tabs-page.module').then((m) => m.TabsPageModule),
    },
    {
        path: 'administration',
        loadChildren: () => import('@app-pages/administration/administration-page.module')
            .then((m) => m.AdministrationPageModule),
    },
    {
        path: 'accept-driving-school-invite',
        loadChildren: () => import('@app-pages/driving-school-invitation/driving-school-invitation-page.module')
            .then((m) => m.DrivingSchoolInvitationPageModule),
    },
    {
        path: 'calendar',
        loadChildren: () => import('@app-pages/student-calendar/student-calendar-page.module')
            .then((m) => m.StudentCalendarPageModule),
    },
    {
        path: 'instructor-calendar',
        loadChildren: () => import('@app-pages/instructor-calendar/instructor-calendar-page.module')
            .then((m) => m.InstructorCalendarPageModule),
    },
    {
        path: 'auth',
        loadChildren: () => import('@app-pages/auth/auth-page.module').then((m) => m.AuthPageModule),
    },
    {
        path: 'profile',
        loadChildren: () => import('@app-pages/profile/profile-page.module').then((m) => m.ProfilePageModule),
    },
    {
        path: 'request-demo',
        loadChildren: () => import('@app-pages/request-demo/request-demo-page.module')
            .then((m) => m.RequestDemoPageModule),
    },
    {
        path: 'roadmap',
        loadChildren: () => import('@app-pages/roadmap/roadmap-page.module').then((m) => m.RoadmapPageModule),
    },
    {
        path: 'settings',
        loadChildren: () => import('@app-pages/settings/settings-page.module').then((m) => m.SettingsPageModule),
    },
    {
        path: 'students',
        loadChildren: () => import('@app-pages/students/students-page.module').then((m) => m.StudentsPageModule),
    },
    {
        path: 'general',
        loadChildren: () => import('@app-pages/general/general-page.module').then((m) => m.GeneralPageModule),
    },
    {
        path: 'ui-kit',
        loadChildren: () => import('@app-pages/ui-kit/ui-kit-page.module').then((m) => m.UiKitPageModule),
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
