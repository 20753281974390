import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { ToastController } from '@ionic/angular'
import { format } from 'date-fns'
import { equals, reject } from 'ramda'
import { Subscription } from 'rxjs'

import { BaseModal } from '@app-components/modals/base.modal'
import { Instructor, Student, User } from '@app-graphql'
import { FormHelperService, InstructorsService, StudentsService, UserService } from '@app-services'

@Component({
    selector: 'app-student-edit',
    templateUrl: './student-edit.modal.html',
    styleUrls: ['./student-edit.modal.scss'],
})
export class StudentEditModal extends BaseModal implements AfterViewInit, OnChanges, OnDestroy {

    @Input()
    public student: Partial<Student>

    @Output()
    public studentUpdated = new EventEmitter<Partial<Student>>()

    public form: FormGroup
    public initialFormValue: FormGroup
    public invoiceSame: FormControl<boolean>
    public pickupSame: FormControl<boolean>

    public apiError: string
    public loading = false

    public user: Partial<User>
    public instructors: Partial<Instructor>[]

    private user$: Subscription
    private instructors$: Subscription

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly instructorsService: InstructorsService,
        private readonly studentsService: StudentsService,
        private readonly toastController: ToastController,
        private readonly translateService: TranslateService,
        private readonly userService: UserService,
    ) {
        super()

        this.user$ = this.userService.user$.subscribe((user) => this.user = user)

        this.instructors$ = this.instructorsService.instructors$
            .subscribe((instructors) => this.instructors = instructors)
    }

    public async ngAfterViewInit(): Promise<void> {

        this.modal?.ionModalWillPresent?.subscribe(async () => {
            this.apiError = ''
            await this.userService.getUser()

            await this.initializeForm()
        })

        await this.userService.getUser()
        await this.instructorsService.getInstructors({ first: 1000, page: 1 })

        this.modal.canDismiss = async () => this.canDismiss()
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.student) {
            await this.initializeForm()
        }
    }

    public async initializeForm(): Promise<void> {
        this.form = this.formBuilder.group({
            firstName: new FormControl(this.student?.user?.firstName, Validators.required),
            lastName: new FormControl(this.student?.user?.lastName, Validators.required),
            email: new FormControl(this.student?.user?.email, [Validators.required, Validators.email]),
            phone: new FormControl(this.student?.phone),
            birthdate: new FormControl(this.student?.birthdate ? new Date(`${this.student.birthdate}T00:00:00`) : null),
            cbrId: new FormControl(this.student?.cbrId),
            address: this.formBuilder.group({
                street: new FormControl(this.student?.postalAddress?.street, Validators.required),
                number: new FormControl(this.student?.postalAddress?.number, Validators.required),
                numberAddition: new FormControl(this.student?.postalAddress?.numberAddition),
                postalCode: new FormControl(this.student?.postalAddress?.postalCode, Validators.required),
                city: new FormControl(this.student?.postalAddress?.city, Validators.required),
            }),
            invoiceAddress: this.formBuilder.group({
                street: new FormControl(this.student?.invoiceAddress?.street, Validators.required),
                number: new FormControl(this.student?.invoiceAddress?.number, Validators.required),
                numberAddition: new FormControl(this.student?.invoiceAddress?.numberAddition),
                postalCode: new FormControl(this.student?.invoiceAddress?.postalCode, Validators.required),
                city: new FormControl(this.student?.invoiceAddress?.city, Validators.required),
            }),
            pickupAddress: this.formBuilder.group({
                street: new FormControl(this.student?.pickupAddress?.street, Validators.required),
                number: new FormControl(this.student?.pickupAddress?.number, Validators.required),
                numberAddition: new FormControl(this.student?.pickupAddress?.numberAddition),
                postalCode: new FormControl(this.student?.pickupAddress?.postalCode, Validators.required),
                city: new FormControl(this.student?.pickupAddress?.city, Validators.required),
            }),
            preferredInstructorIds: new FormControl(
                this.student?.preferredInstructors?.map((p) => p.id),
                Validators.required,
            ),
        })

        this.invoiceSame = new FormControl(equals(
            this.form.get('address').value,
            this.form.get('invoiceAddress').value,
        ))
        this.pickupSame = new FormControl(equals(
            this.form.get('address').value,
            this.form.get('pickupAddress').value,
        ))

        this.toggleFormGroup(this.form.get('invoiceAddress'), this.invoiceSame.value)
        this.toggleFormGroup(this.form.get('pickupAddress'), this.pickupSame.value)

        this.initialFormValue = this.form.value
    }

    public ngOnDestroy(): void {
        this.user$?.unsubscribe()
        this.instructors$?.unsubscribe()
    }

    private async canDismiss(): Promise<boolean> {

        // If the form wasn't changed, we can leave the page without prompting
        if (! this.form.dirty) {
            return true
        }

        // Ask the user whether they want to discard their changes
        const shouldDiscardChanges = await this.formHelperService.confirmDiscardChanges()

        // Reset the form if the user wants to discard changes
        if (shouldDiscardChanges) {
            this.form.reset(this.initialFormValue)
        }

        // We are allowed to leave the page if the changes should be discarded
        return shouldDiscardChanges
    }

    public toggleFormGroup(formGroup: AbstractControl, isEnabled: boolean): void {
        if (isEnabled) {
            formGroup.disable()
        } else {
            formGroup.enable()
        }
    }

    public async submit(): Promise<void> {
        this.apiError = ''

        if (this.loading) {
            return
        }

        if (! this.form.valid) {
            this.formHelperService.reportFormErrors(this.form)
            return
        }

        this.loading = true

        let message = this.translateService.instant('modals.students.student.edit.success')

        this.form.markAsPristine()

        const postalAddress = {
            ...this.form.get('address').value,
            postalCode: this.form.get('address.postalCode').value.split(' ').join('').toUpperCase(),
        }

        try {
            const data = {
                id: this.student.id,
                cbrId: this.form.controls.cbrId.value,
                firstName: this.form.controls.firstName.value,
                lastName: this.form.controls.lastName.value,
                email: this.form.controls.email.value?.toLowerCase(),
                phone: this.form.controls.phone.value ?? null,
                birthdate: this.form.get('birthdate').value
                    ? format(this.form.get('birthdate').value, 'yyyy-MM-dd')
                    : null,
                postalAddress,
                invoiceAddress: this.invoiceSame.value
                    ? postalAddress
                    : {
                        ...this.form.get('invoiceAddress').value,
                        postalCode: this.form.get('invoiceAddress.postalCode').value.split(' ').join('').toUpperCase(),
                    },
                pickupAddress: this.pickupSame.value
                    ? postalAddress
                    : {
                        ...this.form.get('pickupAddress').value,
                        postalCode: this.form.get('pickupAddress.postalCode').value.split(' ').join('').toUpperCase(),
                    },
                preferredInstructorIds: this.form.get('preferredInstructorIds').value,
            }

            const response = await this.studentsService.updateStudent(
                reject(equals(null))(data),
            )

            this.loading = false
            this.formHelperService.hideKeyboard()
            this.studentUpdated.emit(response.updateStudent as Partial<Student>)

            await this.dismiss()

        } catch (e) {
            this.loading = false
            this.apiError = e.message
            this.formHelperService.reportFormErrors(this.form)
            message = this.translateService.instant('modals.students.student.edit.error')
        }

        await this.toastController.create({
            message,
            duration: 3000,
        }).then((toast) => toast.present())
    }
}
