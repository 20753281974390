import { NgModule } from '@angular/core'

import { FileStorageService } from '@app-services/storage/file-storage.service'
import { StorageService } from '@app-services/storage/storage.service'

@NgModule({
    providers: [
        FileStorageService,
        StorageService,
    ],
})
export class StorageServiceModule {
}
