import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-lesson-package-edit',
    templateUrl: './lesson-package-edit.modal.html',
    styleUrls: ['./lesson-package-edit.modal.scss'],
})
export class LessonPackageEditModal extends BaseModal {

    @Input()
    public lessonPackage: any // @TODO: Set type

}
