import { Component, OnInit } from '@angular/core'
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links/ngx'
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx'
import { Platform } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'

import {
    AuthService,
    AutocapitalizeFixService,
    BackButtonService,
    KeyboardHelperService,
    PushService,
    StatusBarStyleService,
    UpdateService,
} from '@app-services'

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

    public settingsMenuVisible: boolean

    constructor(
        private readonly authService: AuthService,
        private readonly autocapitalizeFixService: AutocapitalizeFixService,
        private readonly backButtonService: BackButtonService,
        private readonly firebaseDynamicLinks: FirebaseDynamicLinks,
        private readonly keyboardHelperService: KeyboardHelperService,
        private readonly platform: Platform,
        private readonly pushService: PushService,
        private readonly splashScreen: SplashScreen,
        private readonly statusBarStyleService: StatusBarStyleService,
        private readonly translateService: TranslateService,
        private readonly updateService: UpdateService,
    ) {
    }

    public async ngOnInit(): Promise<void> {
        await this.platform.ready()
        await Promise.all([
            this.initialize(),
            this.setLocale(),
        ])

        this.settingsMenuVisible = this.platform.is('hybrid')
    }

    protected async initialize(): Promise<void> {
        this.backButtonService.initialize()
        await this.authService.initialize()
        if (this.platform.is('hybrid')) {
            this.statusBarStyleService.setStatusBarStyle({ color: 'light' })
            this.autocapitalizeFixService.initialize()
            this.keyboardHelperService.initialize()

            // this.firebaseDynamicLinks.onDynamicLink()
            //     .subscribe({
            //         next: (res: IDynamicLink | null) => console.log('Dynamic links success:', res),
            //         error: (error) => console.log('Dynamic links error:', error),
            //     });

            // this.firebaseDynamicLinks.getDynamicLink()
            //     .then((link: IDynamicLink | null) => console.log('Got dynamic link async: ', link));

            setTimeout(() => this.splashScreen.hide(), 50)
        }
    }

    protected async setLocale(): Promise<void> {
        // @TODO: Uncomment when app becomes multi-lingual
        // const savedLocaleCode = await this.storageService.get('locale');
        // const defaultLocaleCode = this.translateService.getBrowserLang();
        //
        // const localeCode = savedLocaleCode || defaultLocaleCode;
        // const locale = AppConfig.locales.find((l) => l.code === localeCode) || AppConfig.locales[0];
        //
        // this.translateService.use(locale.code);
        this.translateService.use('nl')
    }

}
