import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'

import {
    MilestoneProgress,
    MilestoneTypeEnum,
    PracticeMilestone,
    RoadmapProgress,
    ScriptProgress,
    ScriptRatingTypeEnum,
} from '@app/graphql'
import { RoadmapService, ScriptGroupedByMilestone } from '@app/services'

@Component({
    selector: 'app-roadmap-progress-accordion',
    templateUrl: './roadmap-progress-accordion.component.html',
    styleUrls: ['./roadmap-progress-accordion.component.scss'],
})
export class RoadmapProgressAccordionComponent implements OnChanges {

    @Input()
    public roadmapProgress: Partial<RoadmapProgress>

    @Input()
    public practiceMilestones: PracticeMilestone[]

    @Input()
    public milestoneProgresses: MilestoneProgress[]

    @Input()
    public scriptProgresses: ScriptProgress[]

    @Input()
    public studentId: string

    @Input()
    public calendarEventId: string

    public scriptsGroupedByMilestone: ScriptGroupedByMilestone[]

    public readonly MilestoneTypeEnum = MilestoneTypeEnum
    public readonly ScriptRatingTypeEnum = ScriptRatingTypeEnum

    constructor(
        private readonly roadmapService: RoadmapService,
    ) {
    }

    public async ngOnChanges(changes: SimpleChanges): Promise<void> {
        if (changes.roadmapProgress || changes.practiceMilestones || changes.scriptProgresses) {
            this.getScriptsGroupedByMilestone()
        }
    }

    private getScriptsGroupedByMilestone(): void {
        if (! this.roadmapProgress?.id || ! this.practiceMilestones?.length || ! this.scriptProgresses) {
            return
        }

        this.scriptsGroupedByMilestone = this.roadmapService
            .getScriptsGroupedByMilestone(this.practiceMilestones, this.scriptProgresses)
    }

}
