<ng-template #workInProgressLoading>
    <ion-skeleton-text [animated]="true" class="ion-margin-bottom" style="height: 58px;" />
    <ion-skeleton-text [animated]="true" class="ion-margin-bottom" style="height: 58px;" />
    <ion-skeleton-text [animated]="true" class="ion-margin-bottom" style="height: 58px;" />
</ng-template>

<ng-container *ngIf="workInProgress; else workInProgressLoading">

    <ng-template #workInProgressEmpty>
        <ion-card>
            <ion-card-content>
                <p>Er is nog geen huiswerk toegevoegd</p>
            </ion-card-content>
        </ion-card>
    </ng-template>

    <ng-container *ngIf="workInProgress.length; else workInProgressEmpty">
        <div *ngFor="let script of workInProgress" class="has-chip ion-margin-vertical-4">
            <ng-container *ngFor="let scriptProgress of scriptProgresses">
                <app-competency-level *ngIf="scriptProgress.scriptId === script.id"
                                      [level]="scriptProgress.progress"
                />
            </ng-container>
            <ion-item [detail]="false"
                      [routerLink]="'/tabs/instructor-calendar/event/' + calendarEventId + '/' + studentId + '/script/' + script.id"
                      lines="none"
                      shape="round"
            >
                <ion-label>
                    <h4 class="ion-text-wrap">{{ script.title }}</h4>
                </ion-label>
                <ng-container *ngFor="let scriptProgress of scriptProgresses">
                    <ion-icon *ngIf="scriptProgress.scriptId === script.id && scriptProgress.passed"
                              slot="end"
                              src="/assets/icons/ui/check.svg"
                    />
                </ng-container>
            </ion-item>
        </div>
    </ng-container>

</ng-container>
