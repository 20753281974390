<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.lesson.measure.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light">
            <form (submit)="selectionChanged.emit(newRelatedScripts); dismiss()"
                  *ngIf="relatedScripts?.length"
                  action=""
                  id="student-lesson-measure-form"
            >
                <div class="content-wrapper">
                    <ion-list shape="round">
                        <ion-item *ngFor="let relatedScript of relatedScripts" [detail]="false">
                            <ion-checkbox (ionChange)="toggleRelatedScript(relatedScript, $any($event).detail.checked)"
                                          [checked]="usedRelatedScriptIds.includes(relatedScript.id)"
                                          [disabled]="usedRelatedScriptIds.includes(relatedScript.id)"
                                          [value]="relatedScript.id"
                                          slot="start"
                            />
                            <ion-label>
                                <h3>{{ relatedScript.title }}</h3>
                            </ion-label>
                        </ion-item>
                    </ion-list>
                </div>
            </form>
        </ion-content>

        <ion-footer>
            <ion-toolbar>
                <ion-button class="ion-color ion-color-primary ion-margin"
                            expand="block"
                            form="student-lesson-measure-form"
                            type="submit"
                >
                    Opslaan
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ng-template>
</ion-modal>
