<div class="button-swiper ion-color ion-color-{{color}}">
    <swiper #swiper
            (afterInit)="swiperInitializeHandler()"
            [spaceBetween]="10"
            class="ion-padding"
            slidesPerView="auto"
    >
        <ng-template *ngFor="let button of buttons" swiperSlide>
            <ion-button (click)="! button.id && setValue(button.value)"
                        [appExternalLinkTarget]="button.externalLinkTarget ?? null"
                        [appExternalLink]="button.externalLink ?? null"
                        [attr.data-value]="button.value"
                        [fill]="value === button.value ? 'solid' : 'outline'"
                        [id]="button.id"
                        [routerLink]="button.routerLink ?? null"
                        class="ion-color ion-color-light"
                        shape="round"
            >
                {{ button.label }}
            </ion-button>
        </ng-template>
    </swiper>
</div>
