import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { firstValueFrom, map, Subject } from 'rxjs'

import {
    MilestoneProgress,
    MilestoneProgressesInput,
    MilestoneProgressesQuery,
    MilestoneProgressesQueryService,
    PracticeMilestone,
    PracticeMilestonesInput,
    PracticeMilestoneQuery,
    PracticeMilestoneQueryService,
    RedeemRoadmapPaywallCodeInput,
    RedeemRoadmapPaywallCodeMutation,
    RedeemRoadmapPaywallCodeMutationService,
    RoadmapProgress,
    RoadmapProgressesInput,
    RoadmapProgressesQuery,
    RoadmapProgressesQueryService,
    Script,
    ScriptProgress,
    ScriptProgressesInput,
    ScriptProgressesQuery,
    ScriptProgressesQueryService,
    ScriptResult,
    ScriptResultsInput,
    ScriptResultsQuery,
    ScriptResultsQueryService,
    WorkInProgressInput,
    WorkInProgressQuery,
    WorkInProgressQueryService,
} from '@app-graphql'
import { ApiHelperService, CacheOptions } from '@app-services/api/api-helper.service'

export type ScriptGroupedByMilestone = {
    [key: string]: {
        scripts: Script[],
        scriptProgressesByScriptId: { [key: string]: ScriptProgress[] },
    }
};

@Injectable({
    providedIn: 'root',
})
export class RoadmapService {

    public milestoneProgresses$ = new Subject<MilestoneProgress[]>()
    public practiceMilestones$ = new Subject<PracticeMilestone[]>()
    public roadmapProgresses$ = new Subject<RoadmapProgress[]>()
    public scriptProgresses$ = new Subject<ScriptProgress[]>()
    public scriptResults$ = new Subject<ScriptResult[]>()
    public workInProgress$ = new Subject<Script[]>()

    public milestoneProgresses: MilestoneProgress[]
    public practiceMilestones: PracticeMilestone[]
    public roadmapProgresses: RoadmapProgress[]
    public scriptProgresses: ScriptProgress[]
    public scriptResults: ScriptResult[]
    public workInProgress: Script[]

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly milestoneProgressesQueryService: MilestoneProgressesQueryService,
        private readonly practiceMilestoneQueryService: PracticeMilestoneQueryService,
        private readonly redeemRoadmapPaywallCodeMutationService: RedeemRoadmapPaywallCodeMutationService,
        private readonly roadmapProgressesQueryService: RoadmapProgressesQueryService,
        private readonly scriptProgressesQueryService: ScriptProgressesQueryService,
        private readonly scriptResultsQueryService: ScriptResultsQueryService,
        private readonly workInProgressQueryService: WorkInProgressQueryService,
    ) {
    }

    public async getRoadmapProgresses(
        input: RoadmapProgressesInput = null,
        cacheOptions?: CacheOptions,
    ): Promise<RoadmapProgress[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `roadmap.roadmap-progresses_${input?.studentId || ''}`,
        )
        const roadmapProgresses$ = this.roadmapProgressesQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<RoadmapProgressesQuery>) => {
                this.roadmapProgresses = result.data.roadmapProgresses as RoadmapProgress[]
                this.roadmapProgresses$.next(this.roadmapProgresses)

                return this.roadmapProgresses
            }),
        )

        return firstValueFrom(roadmapProgresses$)
    }

    public async getMilestoneProgresses(
        input: MilestoneProgressesInput,
        cacheOptions?: CacheOptions,
    ): Promise<MilestoneProgress[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `roadmap.roadmap-progresses_${input?.roadmapProgressId}_${input.milestoneIds?.join('_')}`,
        )
        const milestoneProgresses$ = this.milestoneProgressesQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<MilestoneProgressesQuery>) => {
                this.milestoneProgresses = result.data.milestoneProgresses
                this.milestoneProgresses$.next(this.milestoneProgresses)

                return this.milestoneProgresses
            }),
        )

        return firstValueFrom(milestoneProgresses$)
    }

    public async getPracticeMilestones(
        input: PracticeMilestonesInput,
        cacheOptions?: CacheOptions,
    ): Promise<PracticeMilestone[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `roadmap.practice-milestones_${input.ids?.join('_')}`,
        )
        const practiceMilestones$ = this.practiceMilestoneQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<PracticeMilestoneQuery>) => {
                this.practiceMilestones = result.data.practiceMilestones as PracticeMilestone[]
                this.practiceMilestones$.next(this.practiceMilestones)

                return this.practiceMilestones
            }),
        )

        return firstValueFrom(practiceMilestones$)
    }

    public async getScriptProgresses(
        input: ScriptProgressesInput,
        cacheOptions?: CacheOptions,
    ): Promise<ScriptProgress[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            'roadmap.'
            + `script-progresses_${input.roadmapProgressId}_${input.practiceMilestoneId}_${input.scriptIds?.join('_')}`,
        )
        const scriptProgresses$ = this.scriptProgressesQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<ScriptProgressesQuery>) => {
                this.scriptProgresses = result.data.scriptProgresses
                this.scriptProgresses$.next(this.scriptProgresses)

                return this.scriptProgresses
            }),
        )

        return firstValueFrom(scriptProgresses$)
    }

    public async getScriptResults(input: ScriptResultsInput, cacheOptions?: CacheOptions): Promise<ScriptResult[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `roadmap.script-results_${input.roadmapProgressId}_${input.scriptId}`,
        )
        const scriptResults$ = this.scriptResultsQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<ScriptResultsQuery>) => {
                this.scriptResults = result.data.scriptResults as ScriptResult[]
                this.scriptResults$.next(this.scriptResults)

                return this.scriptResults
            }),
        )

        return firstValueFrom(scriptResults$)
    }

    public async getWorkInProgress(input: WorkInProgressInput, cacheOptions?: CacheOptions): Promise<Script[]> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `roadmap.work-in-progress_${input.roadmapProgressId}`,
        )
        const workInProgress$ = this.workInProgressQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<WorkInProgressQuery>) => {
                this.workInProgress = result.data.workInProgress as Script[]
                this.workInProgress$.next(this.workInProgress)

                return this.workInProgress
            }),
        )

        return firstValueFrom(workInProgress$)
    }

    public async redeemRoadmapPaywallCode(
        input: RedeemRoadmapPaywallCodeInput,
    ): Promise<RedeemRoadmapPaywallCodeMutation> {
        try {
            const response = await firstValueFrom(
                this.redeemRoadmapPaywallCodeMutationService.mutate({ input }),
            )

            await this.apiHelperService.invalidateCache('roadmap')

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public getScriptsGroupedByMilestone(
        practiceMilestones: PracticeMilestone[],
        scriptProgresses: ScriptProgress[],
    ): ScriptGroupedByMilestone[] {
        const scriptsGroupedByMilestone: ScriptGroupedByMilestone[] = []

        // Create a map of script progresses by script id
        practiceMilestones.forEach((practiceMilestone) => scriptsGroupedByMilestone[practiceMilestone.id] = {
            scripts: practiceMilestone.scripts,
            scriptProgressesByScriptId: {},
        })

        // Get script progresses for each practice milestone
        practiceMilestones.forEach((practiceMilestone) => {
            scriptProgresses.forEach((scriptProgress) => {
                if (
                    ! scriptsGroupedByMilestone[practiceMilestone.id]
                        .scriptProgressesByScriptId[scriptProgress.scriptId]
                ) {
                    scriptsGroupedByMilestone[practiceMilestone.id]
                        .scriptProgressesByScriptId[scriptProgress.scriptId] = []
                }
                scriptsGroupedByMilestone[practiceMilestone.id]
                    .scriptProgressesByScriptId[scriptProgress.scriptId].push(scriptProgress)
            })
        })

        return scriptsGroupedByMilestone
    }

}
