import { Injectable, NgZone } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms'
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx'
import { AlertController, Platform } from '@ionic/angular'
import { firstValueFrom } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class FormHelperService {

    constructor(
        private readonly alertController: AlertController,
        private readonly keyboard: Keyboard,
        private readonly platform: Platform,
        private readonly zone: NgZone,
    ) {
    }

    public confirmPasswordValidator(control: AbstractControl): ValidatorFn {
        const password = control.get('password').value
        const confirmPassword = control.get('confirmPassword').value
        const currentErrors = control.get('confirmPassword').errors
        const confirmControl = control.get('confirmPassword')

        if (password !== confirmPassword) {
            confirmControl.setErrors({ ...currentErrors, notMatching: true })
            return () => ({ notMatching: true })
        } else {
            confirmControl.setErrors(currentErrors)
        }

        return () => null
    }

    public reportFormErrors(formGroup: FormGroup | AbstractControl[]): void {
        const controls = formGroup instanceof FormGroup ? Object.values(formGroup.controls) : formGroup
        controls.forEach((control: FormControl | FormGroup) => {
            if (control instanceof FormGroup) {
                this.reportFormErrors(control)
            } else {
                control.markAsDirty()
            }
        })
        this.scrollFirstErrorIntoView()
    }

    public scrollFirstErrorIntoView(duration = 600): void {
        const firstErrorElement = document
            .querySelector<HTMLElement>('.ion-invalid,ion-input.ng-invalid,ion-textarea.ng-invalid,.error-message')

        if (firstErrorElement) {
            this.scrollElementIntoView(firstErrorElement, duration)
        }
    }

    public async scrollElementIntoView(element: HTMLElement, duration = 600): Promise<void> {
        await firstValueFrom(this.zone.onStable)

        // Check if there is an open modal
        let page = document.querySelector('.show-modal .ion-page:not(.ion-page-hidden)')
        if (! page) {

            // Check if there is a visible page
            const visiblePages = document.querySelectorAll(
                '.ion-page,.ion-page:not(ion-app):not(app-tabs):not(.ion-page-hidden)',
            )
            page = Array.from(visiblePages).find((p) => {
                const tabs = p.closest('app-tabs')
                return ! tabs || ! tabs.classList.contains('ion-page-hidden')
            })
        }

        const content = page?.querySelector('ion-content') as HTMLIonContentElement
        if (! content) {
            return
        }
        const scrollElement = await content.getScrollElement()
        const containerHeight = content.offsetHeight

        const rect = element.getBoundingClientRect()
        const pos = Math.round(rect.top - ((containerHeight - rect.height) / 2))
        const scrollTop = pos + scrollElement.scrollTop

        await content.scrollToPoint(0, scrollTop, duration)
    }

    public hideKeyboard(): void {
        if (this.platform.is('hybrid')) {
            this.keyboard.hide()
        }
    }

    public async confirmDiscardChanges(): Promise<boolean> {
        const confirm = await this.alertController.create({
            message: 'Wil je dit venster sluiten zonder op te slaan?',
            buttons: [
                {
                    text: 'Nee, blijf hier',
                    role: 'cancel',
                },
                {
                    text: 'Ja, sluiten zonder opslaan',
                    role: 'destructive',
                },
            ],
        })
        await confirm.present()
        const response = await confirm.onDidDismiss()

        return response.role === 'destructive'
    }

    public createTimePickerOptions(
        fromStartDate?: Date,
        intervalMinutes = 15,
    ): { value: string, label: string }[] {

        const options = []

        // Generate options for each [intervalMinutes] step
        for (let i = 0; i < Math.ceil(24 * (60 / intervalMinutes)); i++) {
            let value: string =
                `${String(Math.floor(i * intervalMinutes / 60)).padStart(2, '0')}`
                + `:${String(i * intervalMinutes % 60).padStart(2, '0')}`

            let label = ''

            if (fromStartDate) {
                // Get difference between value and fromStartDate in minutes
                const difference = (i * intervalMinutes) - (fromStartDate.getHours() * 60 + fromStartDate.getMinutes())

                // Format difference as hours and minutes
                // const hours = Math.floor(difference / 60);
                // const minutes = difference % 60;
                // label = `${hours ? `${hours} uur` : ''} ${minutes ? `${minutes} min` : ''}`;

                if (difference <= 0) {
                    continue;
                }

                // @TODO: Localize
                const decimalHours = difference / 60
                label = `${decimalHours.toFixed(2).split('.').join(',')} uur`
            }

            options.push({ value, label })
        }

        return options
    }

}
