<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>
        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>
                    {{
                        event?.id
                            ? ('modals.calendar.event.edit.title' | translate)
                            : ('modals.calendar.event.create.title' | translate)
                    }}
                </ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding">
            <form (submit)="submit()"
                  *ngIf="form"
                  [formGroup]="form"
                  action=""
                  id="event-edit-form"
            >

                <!--                <ion-item lines="none" class="ion-margin-bottom">-->
                <!--                    <ion-label position="stacked">Omschrijving</ion-label>-->
                <!--                    <ion-textarea formControlName="description"-->
                <!--                                  autocapitalize="sentences"-->
                <!--                                  [rows]="2"-->
                <!--                                  [autoGrow]="true"-->
                <!--                    />-->
                <!--                </ion-item>-->

                <ng-container *ngIf="! event.id">
                    <ion-grid>
                        <ion-row class="ion-align-items-center">
                            <ion-col [class.ion-padding-end]="!! form.controls.educationCategory.value"
                                     [size]="form.controls.educationCategory.value ? 6 : 12"
                                     class="ion-no-padding"
                            >
                                <ion-item class="ion-margin-bottom" lines="none">
                                    <ion-label position="stacked">
                                        <h3>Categorie</h3>
                                    </ion-label>
                                    <ion-select (ionChange)="updateTypeAndProductValues()"
                                                formControlName="educationCategory"
                                                interface="popover"
                                                placeholder="Maak een keuze..."
                                    >
                                        <ion-select-option *ngFor="let educationCategory of educationCategories"
                                                           [value]="educationCategory"
                                        >
                                            {{ educationCategory.name }}
                                        </ion-select-option>
                                        <ion-select-option value="other">
                                            Anders...
                                        </ion-select-option>
                                    </ion-select>
                                    <ion-note [hidden]="form.controls.educationCategory.valid || ! form.controls.educationCategory.dirty"
                                              slot="error"
                                    >
                                        {{ 'modals.calendar.event.validation.educationCategoryInvalid' | translate }}
                                    </ion-note>
                                </ion-item>
                            </ion-col>

                            <ion-col [hidden]="! form.controls.educationCategory.value" class="ion-no-padding" size="6">
                                <ion-item [hidden]="form.controls.educationCategory.value === 'other'"
                                          class="ion-margin-bottom"
                                          lines="none"
                                >
                                    <ion-label position="stacked">
                                        <h3>Product</h3>
                                    </ion-label>
                                    <ion-select formControlName="product"
                                                interface="popover"
                                                placeholder="Maak een keuze..."
                                                (ionChange)="updateLocationRequired()"
                                    >
                                        <ion-select-option *ngFor="let product of products"
                                                           [value]="product"
                                        >
                                            {{ product.title }}
                                        </ion-select-option>
                                    </ion-select>
                                    <ion-note [hidden]="form.controls.product.valid || ! form.controls.product.dirty"
                                              slot="error"
                                    >
                                        {{ 'modals.calendar.event.validation.productInvalid' | translate }}
                                    </ion-note>
                                </ion-item>
                                <ion-item [hidden]="form.controls.educationCategory.value !== 'other'"
                                          class="ion-margin-bottom"
                                          lines="none"
                                >
                                    <ion-label position="stacked">
                                        <h3>Type afspraak</h3>
                                    </ion-label>
                                    <ion-select formControlName="type"
                                                interface="popover"
                                                placeholder="Maak een keuze..."
                                    >
                                        <ion-select-option *ngFor="let type of calendarEventTypes"
                                                           [value]="type"
                                        >
                                            {{ type.name }}
                                        </ion-select-option>
                                    </ion-select>
                                    <ion-note [hidden]="form.controls.type.valid || ! form.controls.type.dirty"
                                              slot="error"
                                    >
                                        {{ 'modals.calendar.event.validation.typeInvalid' | translate }}
                                    </ion-note>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ng-container>

                <app-date-picker (valueChanged)="updateDateValues()"
                                 formControlName="date"
                >
                    <ion-label position="stacked">
                        <h3>Datum en tijd</h3>
                    </ion-label>
                    <ion-note [hidden]="form.get('date').valid || ! form.get('date').dirty"
                              class="error"
                    >
                        {{ 'modals.calendar.event.validation.dateInvalid' | translate }}
                    </ion-note>
                </app-date-picker>

                <ion-item class="ion-margin-vertical" lines="none">
                    <ion-grid>
                        <ion-row>
                            <ion-col class="ion-no-padding" size="5">
                                <ion-item lines="none">
                                    <ion-input (ionBlur)="updateDateValues('startTime')"
                                               appDatalist="startTimes"
                                               formControlName="startTime"
                                               type="time"
                                    />
                                    <datalist id="startTimes">
                                        <option *ngFor="let option of startsAtTimePickerOptions; trackBy: timePickerTrackByFn"
                                                [value]="option.value"
                                        >
                                            {{ option.label }}
                                        </option>
                                    </datalist>
                                </ion-item>
                            </ion-col>
                            <ion-col class="ion-align-self-center ion-text-center" size="2">tot</ion-col>
                            <ion-col class="ion-no-padding" size="5">
                                <ion-item lines="none">
                                    <ion-input (ionBlur)="updateDateValues('endTime')"
                                               appDatalist="endTimes"
                                               formControlName="endTime"
                                               type="time"
                                    />
                                    <datalist id="endTimes">
                                        <option *ngFor="let option of endsAtTimePickerOptions; trackBy: timePickerTrackByFn"
                                                [value]="option.value"
                                        >
                                            {{ option.label }}
                                        </option>
                                    </datalist>
                                </ion-item>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Status</h3>
                    </ion-label>
                    <ion-select formControlName="status"
                                interface="popover"
                                placeholder="Maak een keuze..."
                    >
                        <ion-select-option *ngFor="let status of EventStatusEnum | keyvalue"
                                           [value]="status.value"
                        >
                            {{ ('eventStatuses.' + status.value) | translate }}
                        </ion-select-option>
                    </ion-select>
                    <ion-note [hidden]="form.controls.status.valid || ! form.controls.status.dirty" slot="error">
                        {{ 'modals.calendar.event.validation.statusInvalid' | translate }}
                    </ion-note>
                </ion-item>

                <ion-grid>
                    <ion-row class="ion-align-items-center">
                        <ion-col class="ion-no-padding ion-padding-end" size="6">
                            <ion-item class="ion-margin-bottom" lines="none">
                                <ion-label position="stacked">
                                    <h3>Instructeur(s)</h3>
                                </ion-label>
                                <app-select-with-search [multiple]="true"
                                                        compareWith="id"
                                                        formControlName="instructors"
                                                        placeholder="Maak een keuze..."
                                                        searchbarPlaceholder="Zoek op naam"
                                >
                                    <ion-select-option *ngFor="let instructor of instructors"
                                                       [value]="instructor"
                                    >
                                        {{ instructor.user.firstName + ' ' + instructor.user.lastName }}
                                    </ion-select-option>
                                </app-select-with-search>
                                <ion-note [hidden]="form.controls.instructors.valid || ! form.controls.instructors.dirty"
                                          slot="error"
                                >
                                    {{ 'modals.calendar.event.validation.instructorsInvalid' | translate }}
                                </ion-note>
                            </ion-item>
                        </ion-col>

                        <ion-col class="ion-no-padding" size="6">
                            <ion-item class="ion-margin-bottom" lines="none">
                                <ion-label position="stacked">
                                    <h3>Voertuig(en)</h3>
                                </ion-label>
                                <app-select-with-search [multiple]="true"
                                                        compareWith="id"
                                                        formControlName="vehicles"
                                                        placeholder="Maak een keuze..."
                                                        searchbarPlaceholder="Zoek op naam of kenteken"
                                >
                                    <ion-select-option *ngFor="let vehicle of vehicles"
                                                       [value]="vehicle"
                                    >
                                        {{ vehicle.name }}
                                        <span slot="end">{{ vehicle.licensePlate }}</span>
                                    </ion-select-option>
                                </app-select-with-search>
                                <ion-note [hidden]="form.controls.vehicles.valid || ! form.controls.vehicles.dirty"
                                          slot="error"
                                >
                                    {{ 'modals.calendar.event.validation.vehiclesInvalid' | translate }}
                                </ion-note>
                            </ion-item>
                        </ion-col>
                    </ion-row>
                </ion-grid>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Leerling(en)</h3>
                    </ion-label>

                    <app-select-with-search (valueChanged)="updateLocationValues()"
                                            [multiple]="true"
                                            compareWith="id"
                                            formControlName="students"
                                            placeholder="Maak een keuze..."
                                            searchbarPlaceholder="Zoek op naam"
                                            side="top"
                    >
                        <ion-select-option *ngFor="let student of students" [value]="student">
                            {{ student.user.firstName + ' ' + student.user.lastName }}
                        </ion-select-option>
                    </app-select-with-search>
                    <ion-note [hidden]="form.controls.students.valid || ! form.controls.students.dirty" slot="error">
                        {{ 'modals.calendar.event.validation.studentInvalid' | translate }}
                    </ion-note>
                </ion-item>

                <!--
                <ion-button expand="block" class="ion-color ion-color-light">
                    <ion-icon slot="start" size="small" name="reload" />
                    <small>Afspraak herhalen</small>
                </ion-button>
                -->

                <ion-item class="item-input" lines="none">
                    <ion-label class="ion-margin-bottom" position="stacked">
                        <h3>Locatie</h3>
                    </ion-label>
                    <ion-radio-group (ionChange)="updateLocationValues()"
                                     [hidden]="form.controls.students.value?.length !== 1"
                                     class="w-100 ion-margin-bottom"
                                     formControlName="locationType"
                    >
                        <ion-item class="w-100" fill="clear" lines="none">
                            <ion-radio [disabled]="! studentWithAddresses?.pickupAddress" slot="start" value="home" />
                            <ion-label class="max-w-100">
                                Leerling adres
                                <ng-container *ngIf="(studentWithAddresses === undefined || studentWithAddresses?.pickupAddress); else noPickupLocation">
                                    <br>
                                    <ion-note>{{ event.location }}</ion-note>
                                </ng-container>
                                <ng-template #noPickupLocation>
                                    <br>
                                    <ion-note>{{ 'Geen locatie bekend' }}</ion-note>
                                </ng-template>
                            </ion-label>
                            <ion-spinner *ngIf="studentWithAddresses === undefined" slot="end" />
                        </ion-item>
                        <ion-item class="w-100" fill="clear" lines="none">
                            <ion-radio slot="start" value="other" />
                            <ion-label>Anders, namelijk</ion-label>
                        </ion-item>
                    </ion-radio-group>
                </ion-item>

                <ion-item [hidden]="form?.controls?.locationType?.value === 'home' && event.location"
                          class="ion-margin-bottom"
                          lines="none"
                >
                    <ion-input (ionBlur)="updateLocationValues()"
                               autocapitalize="sentences"
                               formControlName="locationOther"
                               placeholder="Vul een locatie/adres in"
                               type="text"
                    />
                    <ion-note [hidden]="form.controls.locationOther.valid || ! form.controls.locationOther.dirty"
                              slot="error"
                    >
                        {{ 'modals.calendar.event.validation.locationOtherInvalid' | translate }}
                    </ion-note>
                </ion-item>

                <ion-item [hidden]="form?.controls?.tableNumber?.disabled"
                          class="ion-margin-bottom"
                          lines="none"
                >
                    <ion-label class="ion-margin-bottom" position="stacked">
                        <h3>Tafelnummer</h3>
                    </ion-label>
                    <ion-input formControlName="tableNumber"
                               inputmode="numeric"
                               placeholder="Vul een tafelnummer in"
                               type="text"
                    />
                    <ion-note [hidden]="form.controls.tableNumber.valid || ! form.controls.tableNumber.dirty"
                              slot="error"
                    >
                        Vul een tafelnummer in
                    </ion-note>
                </ion-item>

                <ion-item *ngIf="! event?.id" lines="none">
                    <ion-label class="ion-margin-bottom" position="stacked">
                        <h3>Notitie</h3>
                    </ion-label>
                    <ion-textarea [autoGrow]="true"
                                  autocapitalize="sentences"
                                  formControlName="note"
                                  placeholder="Voeg eventueel een notitie toe"
                                  rows="5"
                    >
                    </ion-textarea>
                </ion-item>
            </form>

        </ion-content>

        <ion-footer>
            <ion-toolbar class="ion-color ion-color-light">
                <ion-button class="ion-color ion-color-tertiary ion-margin"
                            expand="block"
                            form="event-edit-form"
                            type="submit"
                >
                    {{
                        event?.id
                            ? ('modals.calendar.event.edit.title' | translate)
                            : ('modals.calendar.event.create.submit' | translate)
                    }}
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ng-template>
</ion-modal>
