<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.calendar.event.assignHomework.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light">
            <form (submit)="selectionChanged.emit(selectedScripts); dismiss()"
                  *ngIf="eligibleScripts?.length"
                  action=""
                  id="calendar-event-assign-homework-form"
            >
                <ion-accordion-group [value]="eligibleScriptsGroupedByMilestone[0]?.practiceMilestone.id">
                    <ion-accordion *ngFor="let group of eligibleScriptsGroupedByMilestone"
                                   [value]="group.practiceMilestone.id"
                    >
                        <ion-item slot="header">
                            <ion-label>{{ group.practiceMilestone.title }}</ion-label>
                        </ion-item>
                        <div class="ion-padding" slot="content">
                            <ion-item *ngFor="let script of group.scripts"
                                      class="ion-item-checkbox ion-margin-bottom"
                                      lines="none"
                            >
                                <ion-checkbox (ionChange)="toggleScript(script, $any($event).detail.checked)"
                                              [value]="script.id"
                                              slot="start"
                                />
                                <ion-label>{{ script.title }}</ion-label>
                            </ion-item>
                        </div>
                    </ion-accordion>
                </ion-accordion-group>
            </form>

        </ion-content>

        <ion-footer>
            <ion-toolbar class="ion-color ion-color-light">
                <ion-button class="ion-color ion-color-tertiary ion-margin"
                            expand="block"
                            form="calendar-event-assign-homework-form"
                            type="submit"
                >
                    Toevoegen
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ng-template>
</ion-modal>
