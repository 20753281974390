import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { ChartData, ChartOptions } from 'chart.js'

import { ScriptRatingCompetencyLevel } from '@app-enums'
import { ScriptRatingEnum, ScriptResult } from '@app-graphql'

const DEFAULT_CHART_OPTIONS: ChartOptions<'bar'> = {
    scales: {
        x: {
            grid: { display: false },
            ticks: { display: false },
        },
        y: {
            border: { display: false },
            grid: { drawTicks: false },
            ticks: { display: false },
            min: 0,
            max: 8,
        },
    },
    animation: false,
}

@Component({
    selector: 'app-roadmap-script-result-graph',
    templateUrl: './roadmap-script-result-graph.component.html',
    styleUrls: ['./roadmap-script-result-graph.component.scss'],
})
export class RoadmapScriptResultGraphComponent implements OnChanges {

    @Input()
    public scriptResults: ScriptResult[]

    @Input()
    public chartOptions: ChartOptions<'bar'> = DEFAULT_CHART_OPTIONS

    public chartData: ChartData<'bar'>

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.scriptResults) {
            this.render()
        }
    }

    public render(): void {
        if (! this.scriptResults) {
            return
        }

        const competencyLevels = this.scriptResults
            .filter((scriptResult) => scriptResult.rating !== ScriptRatingEnum.Tool)
            .map((scriptResult) => ScriptRatingCompetencyLevel[scriptResult.rating] as number)

        this.chartData = {
            labels: Array.from({ length: competencyLevels.length }).fill(''),
            datasets: [{
                data: competencyLevels,
                label: 'Score',
                backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--ion-color-primary'),
                borderRadius: 50,
                barThickness: 12,
            }],
        }
    }

}
