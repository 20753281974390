<ng-template #vehiclesLoading>
    <div class="ion-flex">
        <ion-skeleton-text [animated]="true" style="height: 80px;" />
        <ion-skeleton-text [animated]="true" style="height: 80px;" />
        <ion-skeleton-text [animated]="true" style="height: 80px;" />
    </div>
</ng-template>

<ng-container *ngIf="vehicles; else vehiclesLoading">
    <ng-template #vehiclesEmpty>
        <ng-content select="[slot='empty']" />
    </ng-template>

    <ion-list *ngIf="vehicles.length; else vehiclesEmpty"
              class="ion-margin-bottom-5"
              lines="full"
              shape="round"
    >
        <ion-item *ngFor="let vehicle of vehicles"
                  [detail]="true"
                  [routerLink]="'/tabs/administration/organization/vehicle/' + vehicle.id"
        >
            <ion-label class="ion-text-wrap">
                <div>{{ vehicle.name ?? '(Geen naam)' }}</div>

                <p>
                    {{ vehicle.brand }}
                    {{ vehicle.model }}
                </p>

                <ion-badge *ngIf="vehicle.id === preferredVehicleId"
                           class="ion-padding-horizontal-2"
                >
                    Voorkeursauto
                </ion-badge>
            </ion-label>
            <app-license-plate [code]="vehicle.licensePlate" slot="end" />
        </ion-item>
    </ion-list>
</ng-container>
