import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { addDays, getDaysInMonth, isSameDay, Locale, startOfMonth, startOfToday, startOfWeek } from 'date-fns'

import { AppConfig } from '@app-config'
import { CalendarEvent } from '@app-graphql'

export enum CalendarListView {
    Day = 'day',
    Week = 'week',
    Month = 'month',
}

export interface ListDay {
    date: Date;
    isToday: boolean;
    events: Partial<CalendarEvent>[];
}

@Component({
    selector: 'app-calendar-list',
    templateUrl: './calendar-list.component.html',
    styleUrls: ['./calendar-list.component.scss'],
})
export class CalendarListComponent implements OnChanges {

    @ViewChild('list', { read: ElementRef })
    public list: ElementRef<HTMLDivElement>

    @Input()
    public view: string | CalendarListView = CalendarListView.Week

    @Input()
    public events: Partial<CalendarEvent>[] = []

    @Input()
    public value: Date

    @Input()
    public showEmptyDays = true

    @Output()
    public dateChanged = new EventEmitter<Date>()

    @Output()
    public eventClicked = new EventEmitter<Partial<CalendarEvent>>()

    public daysInRange: ListDay[]

    public eventCountInRange: number

    constructor(
        private translateService: TranslateService,
    ) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.events) {
            this.value = this.value
                ?? (this.events?.[0]?.startsAt ? new Date(this.events?.[0]?.startsAt) : null)
                ?? new Date()
            this.render()
        }
    }

    public setValue(value: string | Date): void {
        this.value = value instanceof Date ? value : new Date(value)
        this.render()
    }

    public render(): void {
        if (! this.events) {
            return
        }

        this.daysInRange = this.getDaysInRange()
        this.eventCountInRange = this.daysInRange.reduce((count, day: ListDay) => count + day.events.length, 0)
    }

    private getDaysInRange(): ListDay[] {
        if (! this.value) {
            return []
        }

        const today = startOfToday()
        const locale = AppConfig.locales.find((l: Locale) => l.code === this.translateService.currentLang)

        let firstDay: Date
        let range: number
        if (this.view === CalendarListView.Month) {
            firstDay = startOfMonth(this.value)
            range = getDaysInMonth(firstDay)
        } else if (this.view === CalendarListView.Week) {
            firstDay = startOfWeek(this.value, { locale })
            range = 7
        } else if (this.view === CalendarListView.Day) {
            firstDay = today
            range = 1
        }

        return Array.from({ length: range }).map((_: undefined, index: number) => {
            const date = addDays(firstDay, index)

            return {
                date,
                isToday: isSameDay(date, today),
                events: this.events.filter((event) => isSameDay(date, new Date(event.startsAt))),
            }
        })
    }
}
