<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.organization.organizationDetails.edit.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding">
            <form (submit)="dismiss()" action="" id="organization-details-edit-form">

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Bedrijfsnaam</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je bedrijfsnaam in" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Telefoonnummer</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je telefoonnummer in" type="tel" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Rijschoolnummer</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je rijschoolnummer in" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>KvK nummer</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je KvK nummer in" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>BTW nummer</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je BTW nummer in" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>IBAN</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je IBAN in" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Straatnaam</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je straatnaam in" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Huisnummer + toevoeging</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je huisnummer in" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Postcode</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je postcode in" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Plaatsnaam</h3>
                    </ion-label>
                    <ion-input placeholder="Vul je plaatsnaam in" />
                </ion-item>

            </form>
        </ion-content>

        <ion-footer>
            <ion-toolbar class="ion-color ion-color-light">
                <ion-button class="ion-color ion-color-tertiary ion-margin"
                            expand="block"
                            form="organization-details-edit-form"
                            type="submit"
                >
                    Gegevens opslaan
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ng-template>
</ion-modal>
