<div *ngIf="! homeworkScripts || homeworkScripts?.length; else noHomework" class="ion-margin-horizontal--3">
    <swiper *ngIf="homeworkScripts?.length; else loadingHomework"
            class="roadmap-homework-swiper"
            slidesPerView="auto"
    >
        <ng-template *ngFor="let homeworkScript of homeworkScripts" swiperSlide>
            <div class="has-chip">
                <app-competency-level [level]="1"></app-competency-level>
                <ion-card [routerLink]="'/tabs/roadmap/script/' + roadmapProgress.id + '/' + homeworkScript.practiceMilestone.id + '/' + homeworkScript.id">
                    <img alt="" class="card-icon" src="/assets/icons/title-icons/driving.svg">
                    <ion-card-content>
                        <h3>{{ homeworkScript.title }}</h3>
                    </ion-card-content>
                </ion-card>
            </div>
        </ng-template>
    </swiper>
    <ng-template #loadingHomework>
        <ion-skeleton-text [animated]="true" class="ion-margin" style="width: 50%; height: 148px" />
    </ng-template>
</div>

<ng-template #noHomework>
    <ion-card>
        <ion-card-content>
            <p>Lekker bezig, op dit moment hoef je niets voor te bereiden voor aankomende les</p>
        </ion-card-content>
    </ion-card>
</ng-template>
