<ion-modal [breakpoints]="[0, 0.5, 1]"
           [handle]="false"
           [initialBreakpoint]="0.5"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>
        <ion-header class="ion-no-border">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-title>Lesplan 'Scan'</ion-title>
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-color ion-color-light">
            <!--            <ion-card class="ion-margin">-->
            <!--                <ion-card-content>-->
            <!--                    <ion-icon name="sparkles-outline"></ion-icon>-->
            <!--                    Coming soon-->
            <!--                </ion-card-content>-->
            <!--            </ion-card>-->
            <ion-accordion-group>
                <ion-accordion value="first">
                    <ion-item slot="header">
                        <ion-badge class="ion-color ion-color-tertiary"
                                   size="large"
                                   slot="start"
                        >
                            1
                        </ion-badge>
                        <ion-label>Titel</ion-label>
                    </ion-item>
                    <div class="ion-padding" slot="content">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed fringilla arcu vitae lectus
                    </div>
                </ion-accordion>
                <ion-accordion value="second">
                    <ion-item slot="header">
                        <ion-badge class="ion-color ion-color-tertiary"
                                   size="large"
                                   slot="start"
                        >
                            2
                        </ion-badge>
                        <ion-label>Titel</ion-label>
                    </ion-item>
                    <div class="ion-padding" slot="content">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias animi consectetur corporis
                        expedita facere facilis incidunt, iusto laudantium minima nesciunt, omnis pariatur placeat
                        praesentium quibusdam quos temporibus veniam vero voluptate.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias animi consectetur corporis
                        expedita facere facilis incidunt, iusto laudantium minima nesciunt, omnis pariatur placeat
                        praesentium quibusdam quos temporibus veniam vero voluptate.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias animi consectetur corporis
                        expedita facere facilis incidunt, iusto laudantium minima nesciunt, omnis pariatur placeat
                        praesentium quibusdam quos temporibus veniam vero voluptate.
                    </div>
                </ion-accordion>
                <ion-accordion value="third">
                    <ion-item slot="header">
                        <ion-badge class="ion-color ion-color-tertiary shadow"
                                   size="large"
                                   slot="start"
                        >
                            3
                        </ion-badge>
                        <ion-label>Titel</ion-label>
                    </ion-item>
                    <div class="ion-padding" slot="content">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias animi consectetur corporis
                        expedita facere facilis incidunt, iusto laudantium minima nesciunt, omnis pariatur placeat
                        praesentium quibusdam quos temporibus veniam vero voluptate.
                    </div>
                </ion-accordion>
                <ion-accordion value="fourth">
                    <ion-item slot="header">
                        <ion-badge class="ion-color ion-color-tertiary shadow"
                                   size="large"
                                   slot="start"
                        >
                            4
                        </ion-badge>
                        <ion-label>Titel</ion-label>
                    </ion-item>
                    <div class="ion-padding" slot="content">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias animi consectetur corporis
                        expedita facere facilis incidunt, iusto laudantium minima nesciunt, omnis pariatur placeat
                        praesentium quibusdam quos temporibus veniam vero voluptate.
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias animi consectetur corporis
                        expedita facere facilis incidunt, iusto laudantium minima nesciunt, omnis pariatur placeat
                        praesentium quibusdam quos temporibus veniam vero voluptate.
                    </div>
                </ion-accordion>
            </ion-accordion-group>
        </ion-content>
    </ng-template>
</ion-modal>
