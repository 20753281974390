<ng-template #productsLoading>
    <div class="ion-flex">
        <ion-skeleton-text [animated]="true" style="height: 80px;" />
        <ion-skeleton-text [animated]="true" style="height: 80px;" />
        <ion-skeleton-text [animated]="true" style="height: 80px;" />
    </div>
</ng-template>

<ng-container *ngIf="products; else productsLoading">
    <ng-template #productsEmpty>
        <ng-content select="[slot='empty']" />
    </ng-template>

    <ion-list *ngIf="products.length; else productsEmpty"
              class="ion-margin-bottom-5"
              lines="full"
              shape="round"
    >
        <ion-item *ngFor="let product of products">
            <ion-label class="ion-text-wrap">
                <div>{{ product.title ?? '(Geen naam)' }}</div>

                <p>
                    {{ product.priceExcludingVat | currency : 'EUR' }}
                </p>

            </ion-label>
        </ion-item>
    </ion-list>
</ng-container>
