import { EventEmitter, Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { FirebaseMessaging, IFirebaseMessage } from '@awesome-cordova-plugins/firebase-messaging/ngx'
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx'
import { AlertController, Platform } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'

import { PushNotification } from '@app-interfaces'
import { firstValueFrom } from 'rxjs'

declare const cordova: any

export interface PushChannel {
    id: string;
    name: string;
    description: string;
    enabled: boolean;
    importance?: 0 | 1 | 2 | 3 | 4 | 5;
    badge?: boolean;
    light?: boolean;
    lightColor?: number;
    sound?: string;
    vibration?: boolean | any[];
}

const DEFAULT_CHANNEL_ID = 'fcm_default_channel'

@Injectable()
export class PushService {

    protected token: string = null

    public onNotificationReceived = new EventEmitter<PushNotification>()

    constructor(
        private readonly firebase: FirebaseMessaging,
        private readonly settings: OpenNativeSettings,
        private readonly alertCtrl: AlertController,
        private readonly router: Router,
        private readonly platform: Platform,
        private readonly translateService: TranslateService,
    ) {
        this.platform.ready().then(() => this.initialize())
        this.onNotificationReceived.subscribe((notification) => this.handleNotification(notification))
        this.translateService.onLangChange.subscribe(() => this.createChannelsIfNeeded())
    }

    protected async initialize(): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }

        this.firebase.onMessage().subscribe((message: IFirebaseMessage) => {
            this.onNotificationReceived.emit({
                id: message.gcm?.message_id || message.message_id || message.google?.message_id,
                title: message.gcm?.title || message.aps?.alert?.title,
                message: message.gcm?.body || message.aps?.alert?.body,
                appWasOpenedFromNotification: false,
                data: (message.data && typeof message.data !== 'object') ? JSON.parse(message.data) : message.data,
            })
        })
        this.firebase.onBackgroundMessage().subscribe((message: IFirebaseMessage) => {
            this.onNotificationReceived.emit({
                id: message.gcm?.message_id || message.message_id || message.google?.message_id,
                title: message.gcm?.title || message.aps?.alert?.title,
                message: message.gcm?.body || message.aps?.alert?.body,
                appWasOpenedFromNotification: true,
                data: (message.data && typeof message.data !== 'object') ? JSON.parse(message.data) : message.data,
            })
        })

        await this.firebase.setBadge(0)
    }

    public async openNotificationSettings(): Promise<void> {
        if (this.platform.is('hybrid')) {
            if (this.platform.is('ios')) {
                await this.settings.open('application_details')
            } else {
                await this.settings.open('notification_id')
            }
        }
    }

    public async hasPermission(): Promise<boolean> {
        if (this.platform.is('android')) {
            return new Promise<boolean>((resolve) => {
                cordova.plugins.permissions.checkPermission(
                    cordova.plugins.permissions.POST_NOTIFICATIONS,
                    (result: { hasPermission: boolean }) => resolve(result.hasPermission),
                    () => resolve(false))
            })
        }

        if (this.platform.is('ios')) {
            return new Promise<boolean>((resolve) => {
                cordova.plugins.diagnostic.isRemoteNotificationsEnabled(
                    (hasPermission: boolean) => resolve(hasPermission),
                    () => resolve(false))
            })
        }

        return false
    }

    public async requestPermission(): Promise<boolean> {
        if (! this.platform.is('hybrid')) {
            return false
        }

        if (this.platform.is('android')) {
            return new Promise<boolean>((resolve) => {
                cordova.plugins.permissions.requestPermission(
                    cordova.plugins.permissions.POST_NOTIFICATIONS,
                    async (result: { hasPermission: boolean }) => {
                        if (result.hasPermission) {
                            await this.createChannelsIfNeeded()
                        }
                        resolve(result.hasPermission)
                    },
                    () => resolve(false))
            })
        }

        const result = await this.firebase.requestPermission()
        return result === 'OK'
    }

    public getToken(): Promise<string | null> {
        return this.firebase.getToken()
    }

    public deleteToken(): Promise<null> {
        return this.firebase.deleteToken()
    }

    public clearNotifications(): Promise<void> {
        // Using cordova.plugins because method was not defined in @awesome-cordova-plugins/firebase-messaging
        return cordova.plugins.firebase.messaging.clearNotifications()
    }

    public subscribe(topic: string): Promise<null> {
        return this.firebase.subscribe(topic)
    }

    public unsubscribe(topic: string): Promise<null> {
        return this.firebase.unsubscribe(topic)
    }

    public createChannel(channel: PushChannel): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }

        /*
         * We might want to add stuff like sound and vibration here, based on channel options defined here:
         * https://github.com/chemerisuk/cordova-plugin-firebase-messaging#channel-options
         */
        const firebaseChannel: PushChannel = {
            ...channel,
            importance: channel.enabled ? 5 : 0,
            badge: true,
            // sound: '',
            // vibration: false,
        }

        // Using cordova.plugins because method was not defined in @awesome-cordova-plugins/firebase-messaging
        return cordova.plugins.firebase.messaging.createChannel(firebaseChannel)
    }

    public deleteChannel(channelId: string): Promise<void> {
        if (! this.platform.is('hybrid')) {
            return
        }
        // Using cordova.plugins because method was not defined in @awesome-cordova-plugins/firebase-messaging
        return cordova.plugins.firebase.messaging.deleteChannel(channelId)
    }

    public async getChannelById(channelId: string): Promise<PushChannel | null> {
        if (! this.platform.is('hybrid')) {
            return null
        }
        // Using cordova.plugins because method was not defined in @awesome-cordova-plugins/firebase-messaging
        const channel = await cordova.plugins.firebase.messaging.findChannel(channelId)

        return {
            ...channel,
            enabled: channel?.importance !== 0,
        }
    }

    public listChannels(): Promise<PushChannel[]> {
        if (! this.platform.is('android')) {
            return Promise.resolve([])
        }
        return new Promise<PushChannel[]>((resolve) => {
            cordova.plugins.firebase.messaging.listChannels()
                .then((nativeChannels: PushChannel[]) => {
                    const channels: PushChannel[] = nativeChannels.map((channel) => ({
                        ...channel,
                        enabled: channel?.importance !== 0,
                    }))

                    // Delete and skip over 'default' channel
                    const defaultChannelIndex = channels.findIndex((channel) => channel.id === 'default')
                    if (defaultChannelIndex >= 0) {
                        this.deleteChannel('default')
                        channels.splice(defaultChannelIndex, 1)
                    }

                    resolve(channels)
                })
        })
    }

    public getBadge(): Promise<number> {
        return this.firebase.getBadge()
    }

    public setBadge(count: number): Promise<null> {
        return this.firebase.setBadge(count)
    }

    protected handleNotification(notification: PushNotification): void {
        // console.log('Received notification:', notification);
        this.alertCtrl.create({
            header: notification.title,
            message: notification.message,
            buttons: [this.translateService.instant('common.ok')],
            backdropDismiss: false,
        }).then((alert) => alert.present())
    }

    protected async createChannelsIfNeeded(): Promise<void> {

        // Get channel definitions from translation file
        const channels = await firstValueFrom(this.translateService.get('pushNotifications.channels'))

        // Check if the default channel already exists and if its name matches the one for the current UI language
        const expectedDefaultChannel = channels.find((channel) => channel.id === DEFAULT_CHANNEL_ID)
        const defaultChannel = await this.getChannelById(DEFAULT_CHANNEL_ID)

        if (! defaultChannel || defaultChannel.name !== expectedDefaultChannel.name) {
            await this.createChannels()
        }
    }

    protected async createChannels(): Promise<void> {
        const channels = await firstValueFrom(this.translateService.get('pushNotifications.channels'))
        const channelPromises = channels.map((channel) => this.createChannel(channel))

        await Promise.all([
            this.deleteChannel(DEFAULT_CHANNEL_ID),
            this.deleteChannel('default'),
            ...channelPromises,
        ])
    }
}
