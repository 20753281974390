import { Pipe, PipeTransform } from '@angular/core'
import { CurrencyPipe } from '@angular/common'
import { TranslateService } from '@ngx-translate/core'

@Pipe({
    name: 'localizedCurrency',
})
export class LocalizedCurrencyPipe implements PipeTransform {

    constructor(
        private readonly currencyPipe: CurrencyPipe,
        private readonly translateService: TranslateService,
    ) {
    }

    public transform(value: number | string, currencyCode: string = 'EUR'): string {
        return this.currencyPipe.transform(
            value,
            currencyCode,
            this.translateService.instant('locale.format.currency.display'),
            this.translateService.instant('locale.format.currency.digitsInfo'),
            this.translateService.currentLang ?? this.translateService.defaultLang,
        )
    }

}
