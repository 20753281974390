import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ToastController } from '@ionic/angular'
import { equals, reject } from 'ramda'
import { Subscription } from 'rxjs'
import { format } from 'date-fns'

import { Student, User } from '@app-graphql'
import { BaseModal } from '@app-components/modals/base.modal'
import { FormHelperService, StudentsService, UserService } from '@app-services'

@Component({
    selector: 'app-personal-details-edit',
    templateUrl: './personal-details-edit.modal.html',
    styleUrls: ['./personal-details-edit.modal.scss'],
})
export class PersonalDetailsEditModal extends BaseModal implements AfterViewInit {

    @Output()
    public studentUserUpdated = new EventEmitter<Partial<Student>>()

    public form: FormGroup
    public initialFormValue: FormGroup
    public invoiceSame: FormControl<boolean>
    public pickupSame: FormControl<boolean>

    public apiError: string
    public loading = false

    public student: Partial<Student>
    public user: Partial<User>

    private user$: Subscription

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly userService: UserService,
        private readonly studentsService: StudentsService,
        private readonly toastController: ToastController,
    ) {
        super()

        this.user$ = this.userService.user$.subscribe((user) => this.user = user)
    }

    public async ngAfterViewInit(): Promise<void> {
        this.modal.canDismiss = async () => this.canDismiss()

        await this.userService.getUser()

        this.modal?.ionModalWillPresent?.subscribe(async () => {
            this.apiError = ''
            await this.initializeForm()
        })
    }

    public async initializeForm(): Promise<void> {
        this.form = this.formBuilder.group({
            firstName: new FormControl(this.user.firstName, [Validators.required]),
            lastName: new FormControl(this.user.lastName, [Validators.required]),
            birthdate: new FormControl(this.user?.student?.birthdate ? new Date(`${this.user?.student.birthdate}T00:00:00`) : null),
            phone: new FormControl(this.user?.student?.phone),
            cbrId: new FormControl(this.user.student?.cbrId),
            email: new FormControl(this.user.email, [Validators.required, Validators.email]),
            address: this.formBuilder.group({
                street: new FormControl(this.user?.student?.postalAddress?.street, Validators.required),
                number: new FormControl(this.user?.student?.postalAddress?.number, Validators.required),
                numberAddition: new FormControl(this.user?.student?.postalAddress?.numberAddition),
                postalCode: new FormControl(this.user?.student?.postalAddress?.postalCode, Validators.required),
                city: new FormControl(this.user?.student?.postalAddress?.city, Validators.required),
            }),
            invoiceAddress: this.formBuilder.group({
                street: new FormControl(this.user?.student?.invoiceAddress?.street, Validators.required),
                number: new FormControl(this.user?.student?.invoiceAddress?.number, Validators.required),
                numberAddition: new FormControl(this.user?.student?.invoiceAddress?.numberAddition),
                postalCode: new FormControl(this.user?.student?.invoiceAddress?.postalCode, Validators.required),
                city: new FormControl(this.user?.student?.invoiceAddress?.city, Validators.required),
            }),
            pickupAddress: this.formBuilder.group({
                street: new FormControl(this.user?.student?.pickupAddress?.street, Validators.required),
                number: new FormControl(this.user?.student?.pickupAddress?.number, Validators.required),
                numberAddition: new FormControl(this.user?.student?.pickupAddress?.numberAddition),
                postalCode: new FormControl(this.user?.student?.pickupAddress?.postalCode, Validators.required),
                city: new FormControl(this.user?.student?.pickupAddress?.city, Validators.required),
            }),
        })

        this.invoiceSame = new FormControl(equals(
            this.form.get('address').value,
            this.form.get('invoiceAddress').value,
        ))
        this.pickupSame = new FormControl(equals(
            this.form.get('address').value,
            this.form.get('pickupAddress').value,
        ))

        this.toggleFormGroup(this.form.get('invoiceAddress'), this.invoiceSame.value)
        this.toggleFormGroup(this.form.get('pickupAddress'), this.pickupSame.value)

        this.initialFormValue = this.form.value
    }

    private async canDismiss(): Promise<boolean> {

        // If the form wasn't changed, we can leave the page without prompting
        if (! this.form.dirty) {
            return true
        }

        // Ask the user whether they want to discard their changes
        const shouldDiscardChanges = await this.formHelperService.confirmDiscardChanges()

        // Reset the form if the user wants to discard changes
        if (shouldDiscardChanges) {
            this.form.reset(this.initialFormValue)
        }

        // We are allowed to leave the page if the changes should be discarded
        return shouldDiscardChanges
    }

    public toggleFormGroup(formGroup: AbstractControl, isEnabled: boolean): void {
        if (isEnabled) {
            formGroup.disable()
        } else {
            formGroup.enable()
        }
    }

    public async submit(): Promise<void> {
        this.apiError = ''

        if (this.loading) {
            return
        }

        if (! this.form.valid) {
            this.formHelperService.reportFormErrors(this.form)
            return
        }

        this.loading = true

        let message = 'Student Bijgewerkt'

        this.form.markAsPristine()

        const postalAddress = {
            ...this.form.get('address').value,
            postalCode: this.form.get('address.postalCode').value.split(' ').join('').toUpperCase(),
        }

        try {
            const data = {
                id: this.user?.student?.id,
                cbrId: this.form.controls.cbrId.value ?? null,
                firstName: this.form.controls.firstName.value,
                lastName: this.form.controls.lastName.value,
                email: this.form.controls.email.value?.toLowerCase(),
                phone: this.form.controls.phone.value ?? null,
                birthdate: this.form.get('birthdate').value
                    ? format(this.form.get('birthdate').value, 'yyyy-MM-dd')
                    : null,
                postalAddress,
                invoiceAddress: this.invoiceSame.value
                    ? postalAddress
                    : {
                        ...this.form.get('invoiceAddress').value,
                        postalCode: this.form.get('invoiceAddress.postalCode').value.split(' ').join('').toUpperCase(),
                    },
                pickupAddress: this.pickupSame.value
                    ? postalAddress
                    : {
                        ...this.form.get('pickupAddress').value,
                        postalCode: this.form.get('pickupAddress.postalCode').value.split(' ').join('').toUpperCase(),
                    },
            }

            const response = await this.studentsService.updateStudent(
                reject(equals(null))(data),
            )

            this.loading = false
            this.studentUserUpdated.emit(response.updateStudent as Partial<Student>)
            this.formHelperService.hideKeyboard()

            await this.dismiss()

        } catch (e) {
            this.loading = false
            this.apiError = e.message
            this.formHelperService.reportFormErrors(this.form)
            message = 'Fout bij bijwerken student'
        }

        await this.toastController.create({
            message,
            duration: 3000,
        }).then((toast) => toast.present())
    }
}
