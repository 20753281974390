import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { firstValueFrom, map, Subject } from 'rxjs'

import { ApiHelperService, CacheOptions, PaginationOptions } from '@app-services'
import {
    AcceptInviteStudentToDrivingSchoolInput,
    AcceptInviteStudentToDrivingSchoolMutation,
    AcceptInviteStudentToDrivingSchoolMutationService as AcceptInvitationMutationService,
    ConnectStudentToDrivingSchoolInput,
    ConnectStudentToDrivingSchoolMutation,
    ConnectStudentToDrivingSchoolMutationService,
    CreateStudentInput,
    CreateStudentMutation,
    CreateStudentMutationService,
    InviteStudentToDrivingSchoolInput,
    InviteStudentToDrivingSchoolMutation,
    InviteStudentToDrivingSchoolMutationService,
    QueryStudentsOrderByColumn,
    SortOrder,
    Student,
    StudentInput,
    StudentQuery,
    StudentQueryService,
    StudentsQuery,
    StudentsQueryService,
    UpdateStudentInput,
    UpdateStudentMutation,
    UpdateStudentMutationService,
} from '@app-graphql'

@Injectable({
    providedIn: 'root',
})
export class StudentsService {

    public students$ = new Subject<Student[]>()
    public studentsSearchResults$ = new Subject<Student[]>()
    public student$ = new Subject<Student>()

    private students: Student[]
    private studentsSearchResults: Student[]
    private student: Student

    public studentPaginationOptions: PaginationOptions = {
        first: 999,
        page: 1,
        orderBy: [
            {
                order: SortOrder.Asc,
                column: QueryStudentsOrderByColumn.FirstName,
            },
        ],
    }

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly acceptInvitationMutationService: AcceptInvitationMutationService,
        private readonly createStudentMutationService: CreateStudentMutationService,
        private readonly inviteStudentToDrivingSchoolMutationService: InviteStudentToDrivingSchoolMutationService,
        private readonly studentsQueryService: StudentsQueryService,
        private readonly studentQueryService: StudentQueryService,
        private readonly updateStudentMutationService: UpdateStudentMutationService,
        private readonly connectStudentToDrivingSchoolMutationService: ConnectStudentToDrivingSchoolMutationService,
    ) {
    }

    public async getStudents(
        paginationOptions: PaginationOptions = this.studentPaginationOptions,
        cacheOptions?: CacheOptions,
    ): Promise<Student[]> {
        if (paginationOptions?.search) {
            return this.searchStudents(paginationOptions)
        }

        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `students.students_${paginationOptions?.page}_${btoa(JSON.stringify(paginationOptions?.orderBy))}`,
        )

        const students$ = this.studentsQueryService.fetch(paginationOptions, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<StudentsQuery>) => {
                this.students = result.data.students.data as Student[]
                this.students$.next(this.students)

                return this.students
            }),
        )

        return firstValueFrom(students$)
    }

    public async searchStudents(
        paginationOptions: PaginationOptions = this.studentPaginationOptions,
    ): Promise<Student[]> {
        const students$ = this.studentsQueryService.fetch(paginationOptions, { fetchPolicy: 'network-only' }).pipe(
            map((result: ApolloQueryResult<StudentsQuery>) => {
                this.studentsSearchResults = result.data.students.data as Student[]
                this.studentsSearchResults$.next(this.studentsSearchResults)

                return this.studentsSearchResults
            }),
        )

        return firstValueFrom(students$)
    }

    public async getStudentProfile(
        input: StudentInput,
        cacheOptions?: CacheOptions,
    ): Promise<Student> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `students.studentProfile_${input.id}`,
        )
        const student$ = this.studentQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<StudentQuery>) => {
                this.student = result.data.student as Student
                this.student$.next(this.student)

                return this.student
            }),
        )

        return firstValueFrom(student$)
    }

    public async createStudent(
        input: CreateStudentInput,
    ): Promise<CreateStudentMutation> {
        try {
            const response = await firstValueFrom(
                this.createStudentMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async updateStudent(
        input: UpdateStudentInput,
    ): Promise<Partial<UpdateStudentMutation>> {
        try {
            const response = await firstValueFrom(
                this.updateStudentMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async acceptInviteStudentToDrivingSchool(
        input: AcceptInviteStudentToDrivingSchoolInput,
    ): Promise<AcceptInviteStudentToDrivingSchoolMutation> {

        try {
            const response = await firstValueFrom(
                this.acceptInvitationMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async connectStudentToDrivingSchool(
        input: ConnectStudentToDrivingSchoolInput,
    ): Promise<ConnectStudentToDrivingSchoolMutation> {
        try {
            const response = await firstValueFrom(
                this.connectStudentToDrivingSchoolMutationService.mutate({ input }),
            )

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async inviteStudentToDrivingSchool(
        input: InviteStudentToDrivingSchoolInput,
    ): Promise<InviteStudentToDrivingSchoolMutation> {

        try {
            const response = await firstValueFrom(
                this.inviteStudentToDrivingSchoolMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }
}
