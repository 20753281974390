import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'app-zoom-button-component',
    templateUrl: './zoom-button-component.component.html',
    styleUrls: ['./zoom-button-component.component.scss'],
})
export class ZoomButtonComponentComponent {

    @Input()
    public element: HTMLElement

    @Input()
    public min = 0.5

    @Input()
    public max = 2

    @Input()
    public step = 0.25

    @Input()
    public cssVariableName = 'zoom'

    @Input()
    public set value(value: number) {
        const newZoom = Math.round(Math.min(Math.max(value, this.min), this.max) * 100) / 100
        if (newZoom !== this.zoom) {
            this.zoom = newZoom
            this.update()
            this.zoomLevelChanged.emit(this.zoom)
        }
    }

    public get value(): number {
        return this.zoom
    }

    @Output()
    public zoomLevelChanged = new EventEmitter<number>()

    private zoom = 1

    public zoomIn(): void {
        this.value += this.step
    }

    public zoomOut(): void {
        this.value -= this.step
    }

    private update(): void {
        this.element.style.setProperty(`--${this.cssVariableName}`, `${this.zoom}`)
    }
}
