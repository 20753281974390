import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-progress-bar-with-percentage',
    templateUrl: './progress-bar-with-percentage.component.html',
    styleUrls: ['./progress-bar-with-percentage.component.scss'],
})
export class ProgressBarWithPercentageComponent {
    @Input()
    public value?: any
}
