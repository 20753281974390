<ion-item [class.show-input]="showInput"
          [class.show-picker-button]="showPickerButton"
          class="ion-item-datetime"
          lines="none"
>
    <ng-content />
    <div>
        <ion-input (blur)="onBlur()"
                   (click)="! allowManualInput && dateModal.present()"
                   (ionChange)="updateValueFromManualInput($any($event).detail.value)"
                   [class]="color ? ('ion-color ion-color-' + color) : ''"
                   [hidden]="! showInput"
                   [maskitoElement]="inputMask.element"
                   [maskito]="(presentation === 'date' && ! format) ? inputMask.options : null"
                   [maxlength]="10"
                   [minlength]="10"
                   [pattern]="presentation === 'date' ? ('locale.format.date.pattern' | translate) : null"
                   [placeholder]="placeholder | translate"
                   [readonly]="! allowManualInput"
                   [value]="value | localizedDate : format"
                   inputmode="numeric"
        />
        <ion-datetime-button [datetime]="'date_' + uuid"
                             [hidden]="! showPickerButton"
                             class="ion-color ion-color-{{ color || 'light' }}"
        >
            <ion-icon name="calendar-number" slot="date-target" />
            <ion-icon name="time" slot="time-target" />
        </ion-datetime-button>
    </div>
    <ion-modal #dateModal [keepContentsMounted]="true" class="datetime-modal">
        <ng-template>
            <ion-datetime #date
                          [firstDayOfWeek]="'locale.format.date.firstDayOfWeek' | translate"
                          [id]="'date_' + uuid"
                          [locale]="'locale.code' | translate"
                          [max]="max ? (max | date : 'YYYY-MM-ddTHH:mm') : undefined"
                          [min]="min ? (min | date : 'YYYY-MM-ddTHH:mm') : undefined"
                          [multiple]="multiple"
                          [presentation]="presentation"
                          [value]="(value || defaultValue) | date : 'YYYY-MM-ddTHH:mm'"
            />
            <ion-button (click)="setValue(date.value || defaultValue); dateModal.dismiss()"
                        class="ion-color ion-color-primary ion-margin"
                        expand="block"
                        fill="solid"
            >
                {{ 'common.ok' | translate }}
            </ion-button>
            <ion-button (click)="setValue(null); dateModal.dismiss()"
                        *ngIf="showClearButton"
                        class="ion-color ion-color-secondary ion-margin-horizontal ion-margin-bottom"
                        expand="block"
                        fill="solid"
            >
                {{ 'common.clear' | translate }}
            </ion-button>
            <ion-button (click)="dateModal.dismiss()"
                        *ngIf="showCancelButton"
                        class="ion-color ion-color-secondary ion-margin-horizontal ion-margin-bottom"
                        expand="block"
                        fill="solid"
            >
                {{ 'common.cancel' | translate }}
            </ion-button>
        </ng-template>
    </ion-modal>

</ion-item>
