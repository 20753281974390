import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client/core'
import { firstValueFrom, map, Subject } from 'rxjs'

import { ApiHelperService, CacheOptions } from '@app-services/api/api-helper.service'
import {
    CalendarEvent,
    CalendarEventQuery,
    CalendarEventQueryService,
    CalendarEventsInput,
    CalendarEventsQuery,
    CalendarEventsQueryService,
    CreateCalendarEventInput,
    CreateCalendarEventMutation,
    CreateCalendarEventMutationService,
    UpdateCalendarEventInput,
    UpdateCalendarEventMutation,
    UpdateCalendarEventMutationService,
} from '@app-graphql'

@Injectable({
    providedIn: 'root',
})
export class CalendarEventsService {

    public calendarEvents$ = new Subject<Partial<CalendarEvent>[]>()
    public calendarEvent$ = new Subject<Partial<CalendarEvent>>()

    private calendarEvents: Partial<CalendarEvent>[]
    private calendarEvent: Partial<CalendarEvent>

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly calendarEventsQueryService: CalendarEventsQueryService,
        private readonly calendarEventQueryService: CalendarEventQueryService,
        private readonly createCalendarEventMutationService: CreateCalendarEventMutationService,
        private readonly updateCalendarEventMutationService: UpdateCalendarEventMutationService,
    ) {
        //
    }

    public async getCalendarEvents(
        input: CalendarEventsInput,
        cacheOptions?: CacheOptions,
    ): Promise<Partial<CalendarEvent>[]> | null {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `calendar.events_${input.dateFrom}_${input.dateTo}_${input.studentId}_${input.instructorId}`,
        )

        const calendarEvents$ = this.calendarEventsQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<CalendarEventsQuery>) => {
                this.calendarEvents = result.data.calendarEvents as Partial<CalendarEvent>[]
                this.calendarEvents$.next(this.calendarEvents)

                return this.calendarEvents
            }),
        )

        return firstValueFrom(calendarEvents$)
    }

    public async getCalendarEventById(id: string, cacheOptions?: CacheOptions): Promise<Partial<CalendarEvent> | null> {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `calendar.event_${id}`,
        )

        const calendarEvent$ = this.calendarEventQueryService.fetch({ input: { id } }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<CalendarEventQuery>) => {
                this.calendarEvent = result.data.calendarEvent as Partial<CalendarEvent>
                this.calendarEvent$.next(this.calendarEvent)

                return this.calendarEvent
            }),
        )

        return firstValueFrom(calendarEvent$)
    }

    public async createCalendarEvent(input: CreateCalendarEventInput): Promise<Partial<CreateCalendarEventMutation>> {
        try {
            const response = await firstValueFrom(
                this.createCalendarEventMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async updateCalendarEvent(input: UpdateCalendarEventInput): Promise<UpdateCalendarEventMutation> {
        try {
            const response = await firstValueFrom(
                this.updateCalendarEventMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

}
