<app-note-detail #noteDetailModal
                 (noteDeleted)="loadNotes({ clearCache: true })"
                 (noteUpdated)="loadNotes({ clearCache: true })"
                 [noteType]="noteType"
                 [note]="selectedNote"
/>

<ng-container *ngIf="notes; else loadingNotes">
    <ng-container *ngIf="notes.length; else noNotes">
        <ion-card (click)="selectedNote = note; noteDetailModal.present()"
                  *ngFor="let note of notes"
                  [button]="true"
                  class="ion-margin-bottom"
                  shape="round"
        >
            <ion-card-content>
                <div class="ion-flex ion-flex-row ion-align-items-center ion-justify-content-between">
                    <div>
                        <div class="ion-flex ion-flex-row ion-align-items-center">
                            <h3 class="ion-no-margin">Notitie</h3>
                            <div class="subtitle ion-margin-start ion-margin-top-1">
                                {{ note?.createdAt | date: 'MM-dd-yyyy \'om\' HH:mm' }}
                            </div>
                        </div>
                        <div class="subtitle">
                            Door {{ note?.author?.firstName }} {{ note?.author?.lastName }}
                        </div>
                    </div>
                    <img alt="" height="50" src="/assets/images/avatar-placeholder.png" width="50" />
                </div>
                <div class="ion-margin-top">
                    <div [innerHTML]="note.text" class="keep-white-space"></div>
                </div>
            </ion-card-content>
        </ion-card>
    </ng-container>
</ng-container>

<ng-template #noNotes>
    <ion-card>
        <ion-card-content>
            <p>Er zijn nog geen notities geplaatst.</p>
        </ion-card-content>
    </ion-card>
</ng-template>

<ng-template #loadingNotes>
    <ion-skeleton-text [animated]="true" class="ion-margin-bottom" style="height: 62px;" />
    <ion-skeleton-text [animated]="true" class="ion-margin-bottom" style="height: 62px;" />
</ng-template>

<app-note-add #noteAddModal
              (noteAdded)="loadNotes({ clearCache: true })"
              [calendarEventId]="calendarEventId"
              [noteType]="noteType"
              [roadmapProgressId]="roadmapProgressId"
              [scriptId]="scriptId"
              [studentId]="studentId"
/>

<ion-card [hidden]="! apiError" class="ion-margin-vertical">
    <ion-card-content class="error-message">
        {{ apiError | translate }}
    </ion-card-content>
</ion-card>
