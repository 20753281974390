import { Injectable } from '@angular/core'
import { firstValueFrom, map, Subject } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core'

import { ApiHelperService, CacheOptions } from '@app-services/api/api-helper.service'
import {
    CreateScriptResultInput,
    CreateScriptResultMutation,
    CreateScriptResultMutationService,
    EligibleForHomeworkInput,
    EligibleForHomeworkQuery,
    EligibleForHomeworkQueryService,
    HomeworkInput,
    HomeworkQuery,
    HomeworkQueryService,
    Script,
    UsedRelatedScriptsInput,
    UsedRelatedScriptsQuery,
    UsedRelatedScriptsQueryService,
} from '@app-graphql'

@Injectable({
    providedIn: 'root',
})
export class HomeworkService {

    public eligibleForHomeworkScripts$ = new Subject<Script[]>()
    public homeworkScripts$ = new Subject<Script[]>()
    public usedRelatedScripts$ = new Subject<Script[]>()

    private eligibleForHomeworkScripts: Script[]
    private homeworkScripts: Script[]
    private usedRelatedScripts: Script[]

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly createScriptResultMutationService: CreateScriptResultMutationService,
        private readonly eligibleForHomeworkQueryService: EligibleForHomeworkQueryService,
        private readonly homeworkQueryService: HomeworkQueryService,
        private readonly usedRelatedScriptsQueryService: UsedRelatedScriptsQueryService,
    ) {
    }

    public async getEligibleForHomework(
        input: EligibleForHomeworkInput,
        cacheOptions?: CacheOptions,
    ): Promise<Script[]> | null {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `homework.eligibleForHomework_${input.roadmapProgressId}`,
        )
        const eligibleForHomeworkScripts$ = this.eligibleForHomeworkQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<EligibleForHomeworkQuery>) => {
                this.eligibleForHomeworkScripts = result.data.eligibleForHomework as Script[]
                this.eligibleForHomeworkScripts$.next(this.eligibleForHomeworkScripts)

                return this.eligibleForHomeworkScripts
            }),
        )

        return firstValueFrom(eligibleForHomeworkScripts$)
    }

    public async getHomework(
        input: HomeworkInput,
        cacheOptions?: CacheOptions,
    ): Promise<Script[]> | null {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `homework.homeworkScripts_${input.roadmapProgressId}`,
        )
        const homeworkScripts$ = this.homeworkQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<HomeworkQuery>) => {
                this.homeworkScripts = result.data.homework as Script[]
                this.homeworkScripts$.next(this.homeworkScripts)

                return this.homeworkScripts
            }),
        )

        return firstValueFrom(homeworkScripts$)
    }

    public async getUsedRelatedScripts(
        input: UsedRelatedScriptsInput,
        cacheOptions?: CacheOptions,
    ): Promise<Script[]> | null {
        const fetchPolicy = await this.apiHelperService.getFetchPolicy(
            cacheOptions,
            `homework.usedRelatedScripts_${input.roadmapProgressId}_${input.scriptId}`,
        )
        const usedRelatedScripts$ = this.usedRelatedScriptsQueryService.fetch({ input }, { fetchPolicy }).pipe(
            map((result: ApolloQueryResult<UsedRelatedScriptsQuery>) => {
                this.usedRelatedScripts = result.data.usedRelatedScripts as Script[]
                this.usedRelatedScripts$.next(this.usedRelatedScripts)

                return this.usedRelatedScripts
            }),
        )

        return firstValueFrom(usedRelatedScripts$)
    }

    public async createScriptResult(input: CreateScriptResultInput): Promise<CreateScriptResultMutation> {
        try {
            const response = await firstValueFrom(
                this.createScriptResultMutationService.mutate({ input }),
            )

            await this.apiHelperService.invalidateCache('roadmap')

            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

}
