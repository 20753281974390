import { Component, Input } from '@angular/core'

import { Instructor } from '@app-graphql'

@Component({
    selector: 'app-instructor-list',
    templateUrl: './instructor-list.component.html',
    styleUrls: ['./instructor-list.component.scss'],
})
export class InstructorListComponent {

    @Input()
    public instructors: Partial<Instructor>[]

}
