<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.organization.vehicle.add.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding">
            <form (submit)="submit()"
                  *ngIf="form"
                  [formGroup]="form"
                  action=""
                  id="organization-vehicle-add-form"
            >

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Naam</h3>
                    </ion-label>
                    <ion-input formControlName="name"
                               placeholder="Vul een naam in"
                    />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Kenteken</h3>
                    </ion-label>
                    <ion-input class="ion-text-uppercase"
                               formControlName="licensePlate"
                               placeholder="AB-123-D"
                    />
                    <ion-note [hidden]="! form.get('licensePlate').dirty" slot="error">
                        {{ 'modals.organization.vehicle.validation.licensePlate' | translate }}
                    </ion-note>
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>CBR code</h3>
                    </ion-label>
                    <ion-input formControlName="cbrId"
                               placeholder="Vul een CBR code in"
                    />
                    <ion-note [hidden]="! form.get('cbrId').dirty" slot="error">
                        {{ 'modals.organization.vehicle.validation.validation.cbrId' | translate }}
                    </ion-note>
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Merk</h3>
                    </ion-label>
                    <ion-input formControlName="brand"
                               placeholder="Vul een merk in"
                    />
                    <ion-note [hidden]="! form.get('brand').dirty" slot="error">
                        {{ 'modals.organization.vehicle.validation.brand' | translate }}
                    </ion-note>
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Model</h3>
                    </ion-label>
                    <ion-input formControlName="model"
                               placeholder="Vul een model in"
                    />
                    <ion-note [hidden]="! form.get('model').dirty" slot="error">
                        {{ 'modals.organization.vehicle.validation.model' | translate }}
                    </ion-note>
                </ion-item>

                <ion-item class="ion-no-padding ion-margin-bottom" lines="none">
                    <ion-label class="ion-no-margin" position="stacked">
                        <h3>Bouwjaar</h3>
                    </ion-label>
                    <ion-datetime-button datetime="year" />

                    <ion-modal #yearPickerModal [keepContentsMounted]="true">
                        <ng-template>
                            <ion-datetime formControlName="year"
                                          id="year"
                                          presentation="year"
                            />
                            <ion-button (click)="yearPickerModal.dismiss()"
                                        class="ion-color ion-color-primary ion-margin"
                                        expand="block"
                                        fill="solid"
                            >
                                {{ 'common.ok' | translate }}
                            </ion-button>
                        </ng-template>
                    </ion-modal>
                </ion-item>

                <ion-card [hidden]="! apiError" class="ion-margin-vertical">
                    <ion-card-content class="error-message">
                        {{ apiError | translate }}
                    </ion-card-content>
                </ion-card>

                <input class="semi-hidden" tabindex="-1" type="submit" value="" />
            </form>
        </ion-content>

        <ion-footer>
            <ion-toolbar class="ion-color ion-color-light">
                <ion-button class="ion-color ion-color-tertiary ion-margin"
                            expand="block"
                            form="organization-vehicle-add-form"
                            type="submit"
                >
                    <span [hidden]="loading">Gegevens opslaan</span>
                    <ion-spinner [hidden]="! loading" slot="icon-only" />
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ng-template>
</ion-modal>
