<h3>Toetsoefeningen</h3>
<p>Toets het script middels één van deze oefeningen</p>
<swiper [autoHeight]="true"
        [pagination]="true"
        [slidesPerView]="1"
        [spaceBetween]="-10"
        class="overflow-visible item-swiper ion-color ion-color-primary ion-margin-top ion-margin-bottom-4"
>
    <ng-template *ngFor="let testExercise of testExercises" swiperSlide>
        <ion-item [detail]="false" class="ion-align-items-start" lines="full" shape="round">
            <ion-label class="ion-text-wrap">
                <h3>{{ testExercise.title }}</h3>
                <p>{{ testExercise.description }}</p>
            </ion-label>
        </ion-item>
    </ng-template>
</swiper>
