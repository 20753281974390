import { Injectable } from '@angular/core'
import { NavController } from '@ionic/angular'
import { CacheService } from 'ionic-cache'
import { firstValueFrom } from 'rxjs'

import {
    AuthPayload,
    ForgotPasswordMutation,
    ForgotPasswordMutationService,
    LoginInput,
    LoginMutation,
    LoginMutationService,
    LogoutMutation,
    LogoutMutationService,
    NewPasswordWithCodeInput,
    RefreshTokenMutation,
    RefreshTokenMutationService,
    RegisterStudentInput,
    RegisterStudentMutation,
    RegisterStudentMutationService,
    ResetPasswordMutation,
    ResetPasswordMutationService,
} from '@app-graphql'
import { ApiHelperService } from '@app-services/api/api-helper.service'
import { StorageService } from '@app-services/storage'

@Injectable({
    providedIn: 'root',
})
export class AuthService {

    private authPayload: AuthPayload
    private initialized = false

    public authenticationInProgress = false
    public userWasRedirectedAsAlreadyLoggedIn = false // This is to prevent infinite loops after gql errors

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly cacheService: CacheService,
        private readonly forgotPasswordMutationService: ForgotPasswordMutationService,
        private readonly loginMutationService: LoginMutationService,
        private readonly logoutMutationService: LogoutMutationService,
        private readonly refreshTokenMutationService: RefreshTokenMutationService,
        private readonly registerStudentMutationService: RegisterStudentMutationService,
        private readonly resetPasswordMutationService: ResetPasswordMutationService,
        private readonly navController: NavController,
        private readonly storageService: StorageService,
    ) {
    }

    public async initialize(): Promise<void> {
        if (this.initialized) {
            return
        }

        this.initialized = true
        this.authenticationInProgress = true

        await this.authenticateFromPersistedAuthPayload()
        if (! this.authPayload) {
            await this.redirectToLoginIfNeeded()
        }

        this.authenticationInProgress = false
    }

    public async isAuthenticated(): Promise<boolean> {
        await this.initialize()
        return !! this.authPayload
    }

    public getAuthPayload(): AuthPayload {
        return this.authPayload
    }

    public async login(input: LoginInput): Promise<LoginMutation> {
        try {
            const response = await firstValueFrom(
                this.loginMutationService.mutate({ input }),
            )
            await this.handleAuthPayloadAndGetUser(response.data.login)
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async forgotPassword(email: string): Promise<ForgotPasswordMutation> {
        try {
            const response = await firstValueFrom(
                this.forgotPasswordMutationService.mutate({ input: { email } }),
            )
            return response.data
        } catch (e) {
            await this.logout(true)
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async resetPassword(input: NewPasswordWithCodeInput): Promise<ResetPasswordMutation> {
        try {
            const response = await firstValueFrom(
                this.resetPasswordMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async logout(redirectToLogin = false): Promise<LogoutMutation> {
        let response

        try {
            response = await firstValueFrom(this.logoutMutationService.mutate())
        } catch (e) {
        }

        this.authPayload = null

        await Promise.all([
            this.apiHelperService.invalidateCache(),
            this.cacheService.clearAll(),
            this.storageService.remove('refreshToken'),
        ])

        this.userWasRedirectedAsAlreadyLoggedIn = false

        if (redirectToLogin) {
            await this.navController.navigateBack('/auth/index', { replaceUrl: true })
        }

        return response?.data
    }

    public async registerStudent(input: RegisterStudentInput): Promise<RegisterStudentMutation> {
        try {
            const response = await firstValueFrom(
                this.registerStudentMutationService.mutate({ input }),
            )
            return response.data
        } catch (e) {
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    public async refreshToken(refreshToken: string): Promise<RefreshTokenMutation> {
        try {
            const response = await firstValueFrom(
                this.refreshTokenMutationService.mutate({ input: { refreshToken } }),
            )
            await this.handleAuthPayloadAndGetUser(response.data.refreshToken)
            return response.data
        } catch (e) {
            await this.logout(true)
            throw new Error(this.apiHelperService.getErrorMessageFromApolloError(e))
        }
    }

    private async handleAuthPayloadAndGetUser(authPayload: AuthPayload): Promise<void> {
        this.authPayload = authPayload
        await this.persistAuthPayload()
    }

    private async persistAuthPayload(): Promise<void> {
        await Promise.all([
            this.cacheService.saveItem('auth', this.authPayload, 'auth', this.authPayload.expiresIn - (60 * 60)),
            this.storageService.set('refreshToken', this.authPayload.refreshToken),
        ])
    }

    private async authenticateFromPersistedAuthPayload(): Promise<void> {
        const hasPersistedAuthPayload = await this.cacheService.itemExists('auth')
        if (hasPersistedAuthPayload) {
            this.authPayload = await this.cacheService.getItem('auth')
            return
        }

        // No (valid) login data present. Check if we've got a saved refreshToken we can use instead
        const refreshToken = await this.storageService.get('refreshToken')
        if (refreshToken) {
            await this.refreshToken(refreshToken)
        }
    }

    private async redirectToLoginIfNeeded(): Promise<void> {
        if (! location.pathname.startsWith('/auth')) {
            await this.navController.navigateBack('/auth/index', { replaceUrl: true })
        }
    }

}
