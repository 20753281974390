<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.organization.instructor.edit.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding">
            <form (submit)="dismiss()" action="" id="organization-instructor-edit-form">

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Voornaam</h3>
                    </ion-label>
                    <ion-input placeholder="Vul een voornaam in" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Achternaam</h3>
                    </ion-label>
                    <ion-input placeholder="Vul een achternaam in" />
                </ion-item>

                <app-date-picker class="ion-margin-bottom" placeholder="Vul een geboortedatum in">
                    <ion-label position="stacked">
                        <h3>Geboortedatum</h3>
                    </ion-label>
                </app-date-picker>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Telefoonnummer</h3>
                    </ion-label>
                    <ion-input placeholder="Vul een telefoonnummer in" type="tel" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>E-mailadres</h3>
                    </ion-label>
                    <ion-input placeholder="Vul een e-mailadres in" type="email" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>CBR nummer</h3>
                    </ion-label>
                    <ion-input placeholder="10 cijfers" type="number" />
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Bevoegdheid</h3>
                    </ion-label>
                    <ion-select interface="popover" placeholder="Selecteer een categorie">
                        <ion-select-option>Iets</ion-select-option>
                        <ion-select-option>Nog iets</ion-select-option>
                    </ion-select>
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Bevoegdheid</h3>
                    </ion-label>
                    <ion-select [multiple]="true"
                                interface="popover"
                                placeholder="Selecteer één of meerdere voertuigen"
                    >
                        <ion-select-option>Volkswagen Golf (XX-12-AB)</ion-select-option>
                        <ion-select-option>Koenigsegg CCX (A12-BC-3)</ion-select-option>
                        <ion-select-option>Lamborghini Murciélago LP640 (XY-Z12-3)</ion-select-option>
                    </ion-select>
                </ion-item>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Voorkeursvoertuig</h3>
                    </ion-label>
                    <ion-select interface="popover" placeholder="Selecteer een voertuig">
                        <ion-select-option>Volkswagen Golf (XX-12-AB)</ion-select-option>
                        <ion-select-option>Koenigsegg CCX (A12-BC-3)</ion-select-option>
                        <ion-select-option>Lamborghini Murciélago LP640 (XY-Z12-3)</ion-select-option>
                    </ion-select>
                </ion-item>

                <h3>Werktijden</h3>
                <div class="ion-padding-start">
                    <ng-container *ngFor="let day of workingDays">
                        <h4>{{ day.weekDay }}</h4>

                        <ng-container *ngFor="let workingTime of day.times; let i = index">
                            <ion-chip *ngIf="i % 2 === 0"
                                      class="ion-text-lowercase ion-color ion-color-primary ion-margin-bottom ion-margin-end"
                            >
                                Van {{ workingTime }} tot {{ day.times[i + 1] ?? '(voeg hieronder toe)' }}
                                <ion-icon (click)="day.times.splice(i, 2)" name="close-circle" />
                            </ion-chip>
                        </ng-container>
                        <app-date-picker #workingTimePicker
                                         (valueChanged)="day.times.push($event.toTimeString().substring(0, 5)); workingTimePicker.innerValue = null"
                                         [defaultValue]="'2023-01-01T08:00:00' | dateFromString"
                                         class="ion-margin-bottom"
                                         format="HH:mm"
                                         placeholder="Voeg werktijd toe ({{ day.times.length % 2 === 0 ? 'begintijd' : 'eindtijd' }})"
                                         presentation="time"
                        >
                        </app-date-picker>
                    </ng-container>
                </div>

                <ion-item class="ion-margin-bottom" lines="none">
                    <ion-label position="stacked">
                        <h3>Rol en rechten</h3>
                    </ion-label>
                    <ion-select interface="popover" placeholder="Selecteer een rol">
                        <ion-select-option>Rol 1</ion-select-option>
                        <ion-select-option>Rol 2</ion-select-option>
                        <ion-select-option>Rol 3</ion-select-option>
                    </ion-select>
                </ion-item>

            </form>
        </ion-content>

        <ion-footer>
            <ion-toolbar class="ion-color ion-color-light">
                <ion-button class="ion-color ion-color-tertiary ion-margin"
                            expand="block"
                            form="organization-instructor-edit-form"
                            type="submit"
                >
                    <ng-container *ngIf="instructor">Gegevens opslaan</ng-container>
                    <ng-container *ngIf="! instructor">Instructeur toevoegen</ng-container>
                </ion-button>
            </ion-toolbar>
        </ion-footer>

    </ng-template>
</ion-modal>
