import { AfterViewInit, Component, EventEmitter, OnDestroy, Output } from '@angular/core'
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { AlertController, ToastController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { format } from 'date-fns'
import { equals, reject } from 'ramda'
import { Subscription } from 'rxjs'

import { BaseModal } from '@app-components/modals/base.modal'
import {
    CreateStudentInput,
    CreateStudentMutation,
    CreateStudentStatusEnum,
    Instructor,
    Student,
    User,
} from '@app-graphql'
import { FormHelperService, InstructorsService, StudentsService, UserService } from '@app-services'
import { GraphQLError } from 'graphql'

@Component({
    selector: 'app-student-add',
    templateUrl: './student-add.modal.html',
    styleUrls: ['./student-add.modal.scss'],
})
export class StudentAddModal extends BaseModal implements AfterViewInit, OnDestroy {

    @Output()
    public studentAdded = new EventEmitter<Partial<Student>>()

    public form: FormGroup
    public invoiceSame: FormControl<boolean>
    public pickupSame: FormControl<boolean>

    public apiError: string
    public loading = false
    public toastMessage?: string

    public user: Partial<User>
    public instructors: Partial<Instructor>[]

    private user$: Subscription
    private instructors$: Subscription

    constructor(
        private readonly alertController: AlertController,
        private readonly formBuilder: FormBuilder,
        private readonly formHelperService: FormHelperService,
        private readonly instructorsService: InstructorsService,
        private readonly studentsService: StudentsService,
        private readonly toastController: ToastController,
        private readonly translateService: TranslateService,
        private readonly userService: UserService,
    ) {
        super()

        this.user$ = this.userService.user$.subscribe((user) => this.user = user)

        this.instructors$ = this.instructorsService.instructors$
            .subscribe((instructors) => this.instructors = instructors)
    }

    public async ngAfterViewInit(): Promise<void> {
        this.modal.canDismiss = async () => this.canDismiss()

        await this.userService.getUser()
        await this.instructorsService.getInstructors({ first: 1000, page: 1 })

        this.modal?.ionModalWillPresent?.subscribe(async () => {
            this.apiError = ''
            await this.userService.getUser()
            await this.initializeForm()
            this.setupSameAddressConditionalValidators()
        })
    }

    public async initializeForm(): Promise<void> {
        this.form = this.formBuilder.group({
            firstName: new FormControl(null, Validators.required),
            lastName: new FormControl(null, Validators.required),
            email: new FormControl(null, [Validators.required, Validators.email]),
            phone: new FormControl(null),
            birthdate: new FormControl(null, Validators.required),
            drivingSchoolId: [this.userService.getDrivingSchool()?.id, Validators.required],
            address: this.formBuilder.group({
                street: new FormControl(null, Validators.required),
                number: new FormControl(null, Validators.required),
                numberAddition: new FormControl(null),
                postalCode: new FormControl(null, Validators.required),
                city: new FormControl(null, Validators.required),
            }),
            invoiceAddress: this.formBuilder.group({
                street: new FormControl(null, Validators.required),
                number: new FormControl(null, Validators.required),
                numberAddition: new FormControl(null),
                postalCode: new FormControl(null, Validators.required),
                city: new FormControl(null, Validators.required),
            }),
            pickupAddress: this.formBuilder.group({
                street: new FormControl(null, Validators.required),
                number: new FormControl(null, Validators.required),
                numberAddition: new FormControl(null),
                postalCode: new FormControl(null, Validators.required),
                city: new FormControl(null, Validators.required),
            }),
            preferredInstructorIds: new FormControl(null, Validators.required),
        })

        this.invoiceSame = new FormControl(equals(
            this.form.get('address').value,
            this.form.get('invoiceAddress').value,
        ))

        this.pickupSame = new FormControl(equals(
            this.form.get('address').value,
            this.form.get('pickupAddress').value,
        ))

        this.toggleFormGroup(this.form.get('invoiceAddress'), this.invoiceSame.value)
        this.toggleFormGroup(this.form.get('pickupAddress'), this.pickupSame.value)
    }

    public ngOnDestroy(): void {
        this.user$?.unsubscribe()
        this.instructors$?.unsubscribe()
    }

    public setupSameAddressConditionalValidators(): void {
        this.invoiceSame.valueChanges.subscribe((value) => {
            const invoiceAddressGroup = this.form.get('invoiceAddress') as FormGroup
            if (! value) {
                invoiceAddressGroup.get('street').setValidators([Validators.required])
                invoiceAddressGroup.get('number').setValidators([Validators.required])
                invoiceAddressGroup.get('postalCode').setValidators([Validators.required])
                invoiceAddressGroup.get('city').setValidators([Validators.required])
            } else {
                invoiceAddressGroup.get('street').setValidators(null)
                invoiceAddressGroup.get('number').setValidators(null)
                invoiceAddressGroup.get('postalCode').setValidators(null)
                invoiceAddressGroup.get('city').setValidators(null)
            }

            invoiceAddressGroup.updateValueAndValidity()
        })

        this.pickupSame.valueChanges.subscribe((value) => {
            const pickupAddressGroup = this.form.get('pickupAddress') as FormGroup
            if (! value) {
                pickupAddressGroup.get('street').setValidators([Validators.required])
                pickupAddressGroup.get('number').setValidators([Validators.required])
                pickupAddressGroup.get('postalCode').setValidators([Validators.required])
                pickupAddressGroup.get('city').setValidators([Validators.required])
            } else {
                pickupAddressGroup.get('street').setValidators(null)
                pickupAddressGroup.get('number').setValidators(null)
                pickupAddressGroup.get('postalCode').setValidators(null)
                pickupAddressGroup.get('city').setValidators(null)
            }

            pickupAddressGroup.updateValueAndValidity()
        })
    }

    private async canDismiss(): Promise<boolean> {
        if (! this.form.dirty) {
            return true
        }

        const shouldDiscardChanges = await this.formHelperService.confirmDiscardChanges()

        if (shouldDiscardChanges) {
            this.form.reset()
            await this.initializeForm()
        }

        return shouldDiscardChanges
    }

    public toggleFormGroup(formGroup: AbstractControl, isEnabled: boolean): void {
        if (isEnabled) {
            formGroup.disable()
        } else {
            formGroup.enable()
        }
        formGroup.updateValueAndValidity()
    }

    public getFormData(): Partial<CreateStudentInput> {
        const postalAddress = {
            ...this.form.get('address').value,
            postalCode: this.form.get('address.postalCode').value.split(' ').join('').toUpperCase(),
        }

        return {
            avatar: '',
            drivingSchoolId: this.form.controls.drivingSchoolId.value,
            firstName: this.form.controls.firstName.value,
            lastName: this.form.controls.lastName.value,
            email: this.form.controls.email.value?.toLowerCase(),
            phone: this.form.controls.phone.value ?? null,
            birthdate: this.form.get('birthdate').value
                ? format(this.form.get('birthdate').value, 'yyyy-MM-dd')
                : null,
            postalAddress,
            invoiceAddress: this.invoiceSame.value
                ? postalAddress
                : {
                    ...this.form.get('invoiceAddress').value,
                    postalCode: this.form.get('invoiceAddress.postalCode').value.split(' ').join('').toUpperCase(),
                },
            pickupAddress: this.pickupSame.value
                ? postalAddress
                : {
                    ...this.form.get('pickupAddress').value,
                    postalCode: this.form.get('pickupAddress.postalCode').value.split(' ').join('').toUpperCase(),
                },
            preferredInstructorIds: this.form.get('preferredInstructorIds').value,
        }
    }

    public handleCreateStudentResponse(response: CreateStudentMutation): void {
        if (response.createStudent.status === CreateStudentStatusEnum.AlreadyConnected) {
            this.toastMessage =
                this.translateService.instant('modals.students.student.add.validation.alreadyConnected')

            this.loading = false
            this.form.markAsDirty()
        } else if (response.createStudent.status === CreateStudentStatusEnum.AvailableToConnect) {
            this.checkIfStudentIsAvailableToConnect(response)
        } else {
            this.studentAddedSuccess(response)
        }
    }

    public async checkIfStudentIsAvailableToConnect(response: CreateStudentMutation): Promise<void> {
        this.toastMessage = this.translateService.instant('modals.students.student.add.validation.availableToConnect')

        const alert = await this.alertController.create({
            message: this.translateService.instant('modals.students.student.add.validation.availableToConnectInvite'),
            buttons: [
                {
                    text: this.translateService.instant('common.no'),
                    role: 'cancel',
                    handler: () => {
                        this.loading = false
                        this.form.markAsDirty()
                        this.alertController.dismiss()
                    },
                },
                {
                    text: this.translateService.instant('common.yes'),
                    role: 'confirm',
                    handler: async () => {
                        await this.inviteStudentToDrivingSchool(response)
                    },
                },
            ],
        })

        await alert.present()
    }

    public async inviteStudentToDrivingSchool(response: CreateStudentMutation): Promise<void> {
        await this.studentsService.inviteStudentToDrivingSchool({
            drivingSchoolId: this.form.controls.drivingSchoolId.value,
            id: response.createStudent?.student?.id,
        })

        this.studentAdded.emit(response.createStudent?.student as Partial<Student>)

        this.loading = false
        this.formHelperService.hideKeyboard()

        await this.dismiss()
        await this.initializeForm()
    }

    public studentAddedSuccess(response: CreateStudentMutation): void {
        this.studentAdded.emit(response.createStudent?.student as Partial<Student>)
        this.toastMessage = this.translateService.instant('modals.students.student.add.validation.added')
        this.loading = false
        this.formHelperService.hideKeyboard()

        this.dismiss()
        this.initializeForm()
    }

    public handleCreateStudentError(e: GraphQLError): void {
        this.loading = false
        this.apiError = e.message
        this.formHelperService.reportFormErrors(this.form)
        this.toastMessage = this.translateService.instant('modals.students.student.add.validation.error')
    }

    public async displayToastMessage(): Promise<void> {
        await this.toastController.create({
            message: this.toastMessage,
            duration: 4000,
        }).then((toast) => toast.present())
    }

    public async submit(): Promise<void> {
        this.apiError = ''

        if (this.loading) {
            return
        }

        if (! this.form.valid) {
            this.formHelperService.reportFormErrors(this.form)
            return
        }

        this.loading = true

        this.form.markAsPristine()

        try {
            const data = this.getFormData()
            const response = await this.studentsService.createStudent(<CreateStudentInput>reject(equals(null))(data))
            this.handleCreateStudentResponse(response)

        } catch (e) {
            this.handleCreateStudentError(e)
        }

        await this.displayToastMessage()
    }
}
