import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { AlertController, ModalController } from '@ionic/angular'
import { TranslateService } from '@ngx-translate/core'
import { CacheService } from 'ionic-cache'
import { Subscription } from 'rxjs'

import { BaseModal } from '@app-components/modals/base.modal'
import { ApiHelperService, NotesService } from '@app-services'
import { Note, NoteType } from '@app-components/ui'

@Component({
    selector: 'app-note-detail',
    templateUrl: './note-detail.modal.html',
    styleUrls: ['./note-detail.modal.scss'],
})
export class NoteDetailModal extends BaseModal implements OnInit {

    @Input()
    public note: Note

    @Input()
    public noteType: NoteType

    @Output()
    public noteDeleted = new EventEmitter<Note>()

    @Output()
    public noteUpdated = new EventEmitter<Note>()

    public studentId: string
    public section: string

    private note$: Subscription

    constructor(
        private readonly apiHelperService: ApiHelperService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly alertController: AlertController,
        private readonly cacheService: CacheService,
        private readonly notesService: NotesService,
        private readonly modalController: ModalController,
        private readonly translateService: TranslateService,
    ) {
        super()

        this.note$ = this.notesService.note$.subscribe((note) => {
            this.note = note
        })
    }

    public ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.section = params.section ?? 'lesson-plan'

            if (params.studentId) {
                this.studentId = params.studentId
            }
        })
    }

    public async delete(): Promise<void> {
        const alert = await this.alertController.create({
            header: this.translateService.instant('modals.notes.delete.title'),
            message: this.translateService.instant('modals.notes.delete.message'),
            buttons: [
                {
                    text: this.translateService.instant('common.cancel'),
                    handler: () => {
                        alert.dismiss()
                    },
                },
                {
                    text: this.translateService.instant('common.delete'),
                    role: 'delete',
                    handler: async () => {
                        try {
                            await this.notesService.deleteNote({ id: this.note.id }, this.noteType)

                            this.noteDeleted.emit(this.note)
                        } catch (e) {
                            console.log(e)
                        }
                    },
                },
            ],
        })

        await alert.present()

        const { role } = await alert.onDidDismiss()
        if (role === 'delete') {
            await this.modalController.dismiss()
        }
    }
}
