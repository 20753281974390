import { Component, EventEmitter, Output } from '@angular/core'

@Component({
    selector: 'app-roadmap-paywall',
    templateUrl: './roadmap-paywall.component.html',
    styleUrls: ['./roadmap-paywall.component.scss'],
})
export class RoadmapPaywallComponent {

    @Output()
    public codeRedeemed = new EventEmitter<void>()

    public packagesUrl = 'https://metmax.io/leerling/prijzen'

}
