import { Component, Input } from '@angular/core'

import { BaseModal } from '@app-components/modals/base.modal'

@Component({
    selector: 'app-organization-details-edit',
    templateUrl: './organization-details-edit.modal.html',
    styleUrls: ['./organization-details-edit.modal.scss'],
})
export class OrganizationDetailsEditModal extends BaseModal {

    @Input()
    public organizationDetails: any // @TODO: Set type

}
