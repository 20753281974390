<ion-modal [breakpoints]="[0, 1]"
           [handle]="false"
           [initialBreakpoint]="1"
           [trigger]="trigger"
           class="action-sheet-modal"
>
    <ng-template>

        <ion-header [translucent]="true" class="ion-no-border" collapse="fade">
            <ion-toolbar class="ion-color ion-color-light">
                <ion-buttons slot="end">
                    <ion-button (click)="dismiss()" fill="clear">
                        <ion-icon name="close" />
                    </ion-button>
                </ion-buttons>
                <ion-title>{{ 'modals.script.sections.title' | translate }}</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content [fullscreen]="true" class="ion-color ion-color-light ion-padding-horizontal">

            <ng-container *ngIf="scriptSectionsGroupedByHeader.length > 0; else noSections">
                <ng-container *ngFor="let scriptSectionGroup of scriptSectionsGroupedByHeader; let i = index">
                    <h2 *ngIf="scriptSectionGroup.header">
                        {{ scriptSectionGroup.header }}
                    </h2>
                    <ion-card [class.ion-margin-top]="i > 0" color="light" shape="round">
                        <ion-card-content class="ion-no-padding ion-padding-1">
                            <ion-accordion-group>
                                <ion-accordion
                                    *ngFor="let scriptSection of scriptSectionGroup.scriptSections"
                                    [value]="scriptSection.id"
                                >

                                    <ion-item slot="header">
                                        <ion-label>{{ scriptSection.title }}</ion-label>
                                    </ion-item>
                                    <div class="ion-padding" slot="content">
                                        <div *ngFor="let contentItem of $any(scriptSection.contents)"
                                             class="ion-margin-bottom"
                                        >
                                            <ng-container *ngIf="contentItem.text">
                                                <div [innerHTML]="contentItem.text | safe : 'html'"
                                                     appExternalContent
                                                ></div>
                                            </ng-container>
                                            <ng-container *ngIf="contentItem.videoId">
                                                <app-video-player [video]="contentItem" />
                                            </ng-container>
                                            <ng-container *ngIf="contentItem.image">
                                                <img [alt]="contentItem.altText"
                                                     [src]="contentItem.image"
                                                >
                                            </ng-container>
                                        </div>
                                    </div>
                                </ion-accordion>
                            </ion-accordion-group>
                        </ion-card-content>
                    </ion-card>
                </ng-container>
            </ng-container>
            <ng-template #noSections>
                <ion-card class="ion-margin-vertical">
                    <ion-card-content>
                        <p>Er zijn nog geen scripts toegevoegd</p>
                    </ion-card-content>
                </ion-card>
            </ng-template>

        </ion-content>

    </ng-template>
</ion-modal>
