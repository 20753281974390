import { Component, Input } from '@angular/core'
import { Router } from '@angular/router'

import { Milestone, MilestoneProgress, MilestoneTypeEnum, RoadmapProgress, ScriptRatingTypeEnum } from '@app-graphql'

@Component({
    selector: 'app-roadmap-progress-list',
    templateUrl: './roadmap-progress-list.component.html',
    styleUrls: ['./roadmap-progress-list.component.scss'],
})
export class RoadmapProgressListComponent {

    @Input()
    public roadmapProgress: RoadmapProgress

    @Input()
    public milestoneProgresses: MilestoneProgress[]

    public readonly MilestoneTypeEnum = MilestoneTypeEnum
    public readonly ScriptRatingTypeEnum = ScriptRatingTypeEnum

    constructor(
        private readonly router: Router,
    ) {
    }

    public async itemClick(milestone: Milestone): Promise<void> {
        let route: string
        if (milestone.type === MilestoneTypeEnum.Theory) {
            route = `/tabs/roadmap/theory/${this.roadmapProgress.id}/${milestone.id}`
        } else if (milestone.type === MilestoneTypeEnum.Practice) {
            route = `/tabs/roadmap/module/${this.roadmapProgress.id}/${milestone.id}`
        }

        // Some milestone types don't have a detail page
        if (! route) {
            return
        }

        await this.router.navigateByUrl(route)
    }

}
