import { enUS, nl } from 'date-fns/locale'

import { AppConfigInterface } from '@app-config/app/app-config.interface'

// noinspection JSUnusedGlobalSymbols
export const AppConfig: AppConfigInterface = {

    environment: 'production',

    locales: [
        enUS,
        nl,
    ],

    api: {
        graphQLEndpoint: 'https://api.metmax.io/graphql',
        wsGraphQLEndpoint: 'wss://api.metmax.io/graphql',
        clientId: '33ef9243-9801-43d4-b2f3-0c61830ea211',
    },

    cache: {
        cacheByDefault: true,
        ttl: 60,
    },

    bugsnag: {
        enabled: true,
        apiKey: '7d827a5cf57b4a9fa86ab9c819cdaac7',
    },

}
