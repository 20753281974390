import { Component, Input } from '@angular/core'

import { RoadmapProgress } from '@app-graphql'

@Component({
    selector: 'app-roadmap-goal',
    templateUrl: './roadmap-goal.component.html',
    styleUrls: ['./roadmap-goal.component.scss'],
})
export class RoadmapGoalComponent {

    @Input()
    public roadmapProgress: RoadmapProgress

}
