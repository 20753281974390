<ng-container *ngIf="(
    scriptRating !== ScriptRatingEnum.StvFormation
    && scriptRating !== ScriptRatingEnum.StvTraining
    && scriptRating !== ScriptRatingEnum.StvEducation
); else stvRating"
>
    <ng-container *ngIf="level > 0">
        <ng-container *ngIf="interface === 'badge'">
            <ion-badge [attr.size]="size"
                       class="ion-color ion-color-{{ badgeColor }} competency-level-{{level}}"
            >
                <img *ngIf="level >= 6"
                     alt="" class="competency-level-icon"
                     src="/assets/icons/ui/competency-level-crown.svg"
                />
                <span>{{ level }}</span>
            </ion-badge>
            <img *ngIf="level >= 6"
                 alt=""
                 class="competency-level-icon-sparks"
                 src="/assets/icons/ui/sparks-icon.svg"
            />
        </ng-container>

        <ion-chip *ngIf="interface === 'chip'"
                  [attr.size]="size"
                  class="ranking-chip ion-color ion-color-{{ color }} competency-level-{{level}}"
        >
            <div class="ranking-chip-shield ion-color ion-color-{{ shieldColor }}">
                <ion-badge [attr.size]="size"
                           class="ion-color ion-color-{{ badgeColor }}"
                >{{ level }}
                </ion-badge>
            </div>
            {{ ('competencyLevels.' + ($any(level) - 1)) | translate }}
        </ion-chip>
    </ng-container>
</ng-container>

<ng-template #stvRating>
    <ng-container *ngIf="interface === 'badge'">
        <ion-badge [attr.data-stv-rating]="scriptRating"
                   [attr.size]="size"
                   class="ion-color ion-color-{{ badgeColor }}"
        >
            <img *ngIf="scriptRating === ScriptRatingEnum.StvFormation"
                 alt=""
                 class="competency-level-icon"
                 src="/assets/icons/ui/competency-level-crown.svg"
            />
            <span class="ion-padding-horizontal-2">{{ ('stvRatingLevels.' + scriptRating) | translate }}</span>
        </ion-badge>
        <img *ngIf="scriptRating === ScriptRatingEnum.StvFormation"
             alt=""
             class="competency-level-icon-sparks"
             src="/assets/icons/ui/sparks-icon.svg"
        />
    </ng-container>

    <ion-chip *ngIf="interface === 'chip'"
              [attr.data-stv-rating]="scriptRating"
              [attr.size]="size"
              class="ranking-chip ion-color ion-color-{{ color }}"
    >
        <span class="ion-padding-horizontal-1">{{ ('stvRatingLevels.' + scriptRating) | translate }}</span>
    </ion-chip>
</ng-template>
